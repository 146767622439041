import * as yup from 'yup';
import { setYupLocale } from '../../lib/setYupLocale';
import { addCustomDateValidator, addCustomNationalNumberValidator, addCustomPotentiallyRequiredValidator } from '../../lib/validators';

export const PEOPLE__DATA = {
	version: '1.0',
	first_name: '',
	last_name: '',
	national_number: '',
	phone: '',
	address: {
		street: '',
		number: '',
		box: '',
		postal_code: '',
		town: '',
	},
	birth: {
		date: '',
		address: {
			town: '',
		},
	},
	relationship: '',
};

setYupLocale(yup);
addCustomNationalNumberValidator(yup);
addCustomDateValidator(yup);
addCustomPotentiallyRequiredValidator(yup);

export const PEOPLE__DATA__SCHEMA = yup.object().shape({
	first_name: yup.string().customPotentiallyRequired(),
	last_name: yup.string().customPotentiallyRequired(),
	national_number: yup.string().customNationalNumber().customPotentiallyRequired(),
	phone: yup.string().customPotentiallyRequired(),
	address: yup.object().shape({
		street: yup.string().customPotentiallyRequired(),
		number: yup.string().customPotentiallyRequired(),
		box: yup.string(),
		postal_code: yup.string().customPotentiallyRequired(),
		town: yup.string().customPotentiallyRequired(),
	}),
	birth: yup.object().shape({
		date: yup.string().customDate({ max: new Date() }).customPotentiallyRequired(),
		address: yup.object().shape({
			town: yup.string().customPotentiallyRequired(),
		}),
	}),
	relationship: yup.string(),
});