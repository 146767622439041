/** @jsxImportSource @emotion/react */

import { ReactComponent as Sync } from "@material-design-icons/svg/filled/sync.svg";
import { useTranslation } from "react-i18next";
import { animations, palette, spacing } from "../../theme";
import { CardBox } from "../ui/CardBox";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";

/**
 * A card-like component to display a loading.
 * 
 * @param {object} props Object containing the props:
 * - `...props` any props will be passed.
 * @returns {any} The component.
 */
export function LoadingCardBox({
	...props
}) {
	const { t } = useTranslation();

	return <CardBox
		css={{
			padding: spacing(4),
		}}
		{...props}
	>
		<Typo
			component='p'
			variant='regular'
			css={{
				fontWeight: 600,
				marginBottom: spacing(2),
			}}
		>
			{t('global.loading.title')}
			<Svg svg={Sync}
				aria-hidden={true}
				css={{
					marginLeft: spacing(2),
					animation: `${animations.rotateAntiClockwise} 1s linear infinite`,
				}}
			/>
		</Typo>
		<Typo
			component='p'
			variant='regular'
			css={{
				color: palette.blue[200],
			}}
		>{t('global.loading.description')}</Typo>
	</CardBox>
}