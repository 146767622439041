import { useState } from "react";

export function ModalsArrayHandler({
	handleAdd: givenHandleAdd = () => { },
	handleRemove: givenHandleRemove = () => { },
	defaultValue = null,
	name = '',
	updateFormErrors = () => { },
	currentLength = 0,
	children,
}) {
	const [modalIndex, setModalIndex] = useState(null);
	function setModal(index) {
		setModalIndex(index);
	}
	function closeModal() {
		setModalIndex(null);
	}

	function handleAdd() {
		givenHandleAdd(name, defaultValue);
		setModalIndex(currentLength);
		updateFormErrors();
	}
	function handleRemove(index) {
		givenHandleRemove(name, index);
		updateFormErrors();
	}

	return children({ modalIndex, setModal, closeModal, handleAdd, handleRemove, currentLength });
}