/** @jsxImportSource @emotion/react */

import { ReactComponent as AccessTime } from "@material-design-icons/svg/filled/access_time.svg";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Link as RouterLink, useSearchParams } from "react-router-dom";
import euthanasiaDecisionImg from '../assets/illustrations/euthanasia_decision.png';
import funeralDecisionImg from '../assets/illustrations/funeral_decision.png';
import negativeDecisionImg from '../assets/illustrations/negative_decision.png';
import organDonationDecisionImg from '../assets/illustrations/organ_donation_decision.png';
import { TextCard } from "../components/common/TextCard";
import { Button } from "../components/ui/Button";
import { CardModal } from "../components/ui/CardModal";
import { Container } from "../components/ui/Container";
import { FlexGrid } from "../components/ui/FlexGrid";
import { Svg } from "../components/ui/Svg";
import { Typo } from "../components/ui/Typo";
import { getAuthToken } from "../lib/authToken";
import { getLocalStorageData } from "../lib/localStorage";
import { mq, palette, spacing } from "../theme";
import { LinkButton } from "../components/ui/LinkButton";

export function AdvanceDecisions() {
	const { t } = useTranslation();

	const [isSuccess, setIsSuccess] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	useEffect(() => {
		if (!!searchParams.get('success')) setIsSuccess(true);
		setSearchParams({});
	}, []);

	const [completedDecisions, setCompletedDecisions] = useState([]);
	const loadCompletedDecisions = useCallback(async () => {
		setCompletedDecisions(await getLocalStorageData('advance_decisions_completed') || []);
	}, []);
	useEffect(() => {
		loadCompletedDecisions();
	}, [loadCompletedDecisions]);

	// // TODO: This is a temporary solution for demo purposes, or is it?
	// if (!!getAuthToken()) return <Navigate to="/account/before/legal-documents/advance-decisions" replace />;

	return <>
		<Container>
			<FlexGrid
				container
				css={{ justifyContent: 'center', }}
			>
				<FlexGrid
					xs={12} md={9} lg={8}
				>
					<Typo
						component='h1'
						variant='marketing-title'
						css={{
							marginBottom: spacing(7),
							[mq('lg')]: {
								marginBottom: spacing(12),
							},
						}}
					>{t('guestAdvanceDecisions.title')}</Typo>
					<section
						css={{
							marginBottom: spacing(12),
						}}
					>
						<Typo
							variant='title-1'
							component='h2'
							css={{
								marginBottom: spacing(4),
							}}
						>{t('guestAdvanceDecisions.chooseSection.title')}</Typo>
						<Typo
							variant='regular'
							component='p'
							css={{
								marginBottom: spacing(4),
								color: palette.blue[200],
							}}
						>{t('guestAdvanceDecisions.chooseSection.description')}</Typo>

						<FlexGrid
							container
							spacing={2}
						>
							<FlexGrid xs={12} sm={6}>
								<TextCard
									title={t('advanceDecisions.negative.title')}
									{
									...completedDecisions.includes('negative')
										? { icon: 'valid', }
										: {
											note: <span
												css={{ display: 'flex', justifyContent: 'center', }}
											>
												<Svg
													svg={AccessTime}
													aria-label={t('global.timeEstimate')}
													fontSize='small'
													css={{ marginRight: spacing(1), }}
												/>{t('global.timeInMinutes', { count: 5, })}
											</span>
										}
									}
									image={negativeDecisionImg}
									imageLayout='row'
									imageSize='small'
									css={{
										padding: spacing(4),
									}}
									component={RouterLink}
									to='/advance-decisions/negative'
								>
									<Typo
										component='p'
										variant='small'
										css={{
											color: palette.blue[200],
											marginBottom: spacing(2),
										}}
									>{t('advanceDecisions.negative.description')}</Typo>
								</TextCard>
							</FlexGrid>
							<FlexGrid xs={12} sm={6}>
								<TextCard
									title={t('advanceDecisions.euthanasia.title')}
									{
									...completedDecisions.includes('euthanasia')
										? { icon: 'valid', }
										: {
											note: <span
												css={{ display: 'flex', justifyContent: 'center', }}
											>
												<Svg
													svg={AccessTime}
													aria-label={t('global.timeEstimate')}
													fontSize='small'
													css={{ marginRight: spacing(1), }}
												/>{t('global.timeInMinutes', { count: 5, })}
											</span>
										}
									}
									image={euthanasiaDecisionImg}
									imageLayout='row'
									imageSize='small'
									css={{
										padding: spacing(4),
									}}
									component={RouterLink}
									to='/advance-decisions/euthanasia'
								>
									<Typo
										component='p'
										variant='small'
										css={{
											color: palette.blue[200],
											marginBottom: spacing(2),
										}}
									>{t('advanceDecisions.euthanasia.description')}</Typo>
								</TextCard>
							</FlexGrid>
							<FlexGrid xs={12} sm={6}>
								<TextCard
									title={t('advanceDecisions.organDonation.title')}
									{
									...completedDecisions.includes('organ_donation')
										? { icon: 'valid', }
										: {
											note: <span
												css={{ display: 'flex', justifyContent: 'center', }}
											>
												<Svg
													svg={AccessTime}
													aria-label={t('global.timeEstimate')}
													fontSize='small'
													css={{ marginRight: spacing(1), }}
												/>{t('global.timeInMinutes', { count: 5, })}
											</span>
										}
									}
									image={organDonationDecisionImg}
									imageLayout='row'
									imageSize='small'
									css={{
										padding: spacing(4),
									}}
									component={RouterLink}
									to='/advance-decisions/organ-donation'
								>
									<Typo
										component='p'
										variant='small'
										css={{
											color: palette.blue[200],
											marginBottom: spacing(2),
										}}
									>{t('advanceDecisions.organDonation.description')}</Typo>
								</TextCard>
							</FlexGrid>
							<FlexGrid xs={12} sm={6}>
								<TextCard
									title={t('advanceDecisions.funeral.title')}
									image={funeralDecisionImg}
									imageLayout='row'
									imageSize='small'
									css={{
										padding: spacing(4),
									}}
								>
									<Typo
										component='p'
										variant='small'
										css={{
											color: palette.blue[200],
											marginBottom: spacing(2),
										}}
									>{t('advanceDecisions.funeral.description')}</Typo>
								</TextCard>
							</FlexGrid>
						</FlexGrid>
					</section>
				</FlexGrid>
			</FlexGrid>
		</Container>

		<CardModal
			open={isSuccess}
			onClose={() => setIsSuccess(false)}
			header={
				<Typo
					component='h2'
					variant='title-1'
				>{t('guestAdvanceDecisions.successModal.title')}</Typo>
			}
			footer={
				<Button
					onClick={() => setIsSuccess(false)}
					variant='contained'
				>{t('global.continue')}</Button>
			}
		>
			<Typo
				component='p'
				variant='regular'
			>{t('guestAdvanceDecisions.successModal.description')}</Typo>
		</CardModal>
	</>
}
