import React, { useEffect, useId, useRef, useState } from "react";
import { Popper } from "./Popper";

export function Tooltip({
	open = null, // Boolean or unset. If unset: will be automatic on hover
	placement = 'top',
	content = '',
	phrasingContent = false,
	withPortal = false, // Boolean, defines if the Tooltip is in a React Portal or collocated. /!\ If collocated, `content` should be valid DOM relative the Tooltip's parent (ie.: if the parent is a 'p' tag, only phrasing content and no 'p' tag)
	anchorAriaRefType = 'aria-details',
	role = 'presentation',
	children,
}) {
	const id = useId();

	const [anchorEl, setAnchorEl] = useState(null);

	const [hovered, setHovered] = useState(false);
	const timeoutIdRef = useRef();

	const [focused, setFocused] = useState(false);

	useEffect(() => {
		if (open !== null) return;

		function handleMouseEnter() {
			if (hovered) return;

			timeoutIdRef.current = setTimeout(
				() => setHovered(true),
				500
			);
		}

		function handleMouseLeave() {
			setHovered(false);

			if (!timeoutIdRef?.current) return;

			clearTimeout(timeoutIdRef.current);
		}

		function handleFocusIn() {
			setFocused(true);
		}

		function handleFocusOut() {
			setFocused(false);
		}

		function handleKeyDown(event) {
			if (event.key === 'Escape') {
				setFocused(false);
			}
		}

		anchorEl?.addEventListener('mouseenter', handleMouseEnter);
		anchorEl?.addEventListener('mouseleave', handleMouseLeave);
		anchorEl?.addEventListener('focusin', handleFocusIn);
		anchorEl?.addEventListener('focusout', handleFocusOut);
		anchorEl?.addEventListener('keydown', handleKeyDown);

		return () => {
			anchorEl?.removeEventListener('mouseenter', handleMouseEnter);
			anchorEl?.removeEventListener('mouseleave', handleMouseLeave);
			anchorEl?.removeEventListener('focusin', handleFocusIn);
			anchorEl?.removeEventListener('focusout', handleFocusOut);
			anchorEl?.removeEventListener('keydown', handleKeyDown);
		}
	}, [anchorEl]);

	return <>
		{
			React.cloneElement(
				children,
				{
					ref: setAnchorEl,
					...open === null && { tabIndex: 0, },
					[anchorAriaRefType]: `tooltip_${id}-content`,
				}
			)
		}
		<Popper
			open={open === null ? (hovered || focused) : open}
			anchorEl={anchorEl}
			placement={placement}
			role={role}
			arrow
			phrasingContent={phrasingContent}
			withPortal={withPortal}
			id={`tooltip_${id}-content`}
		>
			{content}
		</Popper>
	</>
}