/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { TutorialsContext } from "../../contexts/Tutorials";
import { spacing } from "../../theme";
import { Button } from "../ui/Button";
import { CardBox } from "../ui/CardBox";
import { Tooltip } from "../ui/Tooltip";
import { Typo } from "../ui/Typo";

export function Tutorial({
	id = '',
	placement = 'top',
	description = '',
	children,
}) {
	const { t } = useTranslation();

	return <TutorialsContext.Consumer>
		{
			({ tutorialsOpen, closeTutorial }) =>
				<Tooltip
					open={tutorialsOpen.includes(id)}
					placement={placement}
					arrow
					content={
						<CardBox
							css={{
								padding: spacing(4),
								maxWidth: 300,
							}}
						>
							<Typo
								component='p'
								variant='small'
								css={{
									marginBottom: spacing(4),
								}}
							>{description}</Typo>
							<Button
								onClick={() => closeTutorial(id)}
							>{t('global.gotIt')}</Button>
						</CardBox>
					}
				>
					{children}
				</Tooltip>
		}
	</TutorialsContext.Consumer>
}