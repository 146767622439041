/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { postEuthanasiaDecision } from "../../../../../api/euthanasiaDecision";
import { updateService } from "../../../../../api/service";
import { Tutorial } from "../../../../../components/common/Tutorial";
import { PeopleDataPresentation } from "../../../../../components/dataPresentation/People";
import { ContainerWithSidebar } from "../../../../../components/display/ContainerWithSidebar";
import { Stepper } from "../../../../../components/stepper/Stepper";
import { StepperMenu } from "../../../../../components/stepper/StepperMenu";
import { Button } from "../../../../../components/ui/Button";
import { CardBox } from "../../../../../components/ui/CardBox";
import { Container } from "../../../../../components/ui/Container";
import { ScrollDisplay } from "../../../../../components/ui/ScrollDisplay";
import { TransListItems, TransParagraphs } from "../../../../../components/ui/Trans";
import { Typo } from "../../../../../components/ui/Typo";
import { PEOPLE__DATA as ENTITY__PEOPLE__DATA, PEOPLE__DATA__SCHEMA as ENTITY__PEOPLE__DATA__SCHEMA } from "../../../../../data/entities/people";
import { EUTHANASIA_DECISION__DATA as SERVICE__EUTHANASIA_DECISION__DATA, EUTHANASIA_DECISION__DATA__SCHEMA as SERVICE__EUTHANASIA_DECISION__DATA__SCHEMA } from "../../../../../data/services/euthanasiaDecision";
import { useConditionValidatedOnce } from "../../../../../hooks/conditionValidatedOnce";
import { useFormValues } from "../../../../../hooks/form";
import { useMediaQuery } from "../../../../../hooks/mediaQuery";
import { isFalse } from "../../../../../lib/conditions";
import { getLocalStorageData, setLocalStorageData } from "../../../../../lib/localStorage";
import { mq, palette, spacing } from "../../../../../theme";

const defaultData = SERVICE__EUTHANASIA_DECISION__DATA;
const dataSchema = SERVICE__EUTHANASIA_DECISION__DATA__SCHEMA;

const peopleDefaultData = ENTITY__PEOPLE__DATA;
const peopleDefaultDataSchema = ENTITY__PEOPLE__DATA__SCHEMA;

const steps = [
	{
		route: 'intro',
		label: ({ t }) => t('euthanasiaDecision.steps.intro.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.intro.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'euthanasiaDecision.steps.intro.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'needs',
		label: ({ t }) => t('euthanasiaDecision.steps.needs.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.needs.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('euthanasiaDecision.steps.needs.list.title')}</Typo>
						<Typo
							component='ul'
							variant='regular'
							css={{
								listStyle: 'disc',
								paddingLeft: spacing(6),
								marginBottom: spacing(4),
								'& li+li': {
									marginTop: spacing(1),
								},
							}}
						>
							<TransListItems
								i18nKey={'euthanasiaDecision.steps.needs.list.items'}
								t={t}
								useMarkdown={true}
							/>
						</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{ marginBottom: spacing(4), fontStyle: 'italic', }}
						>{t('euthanasiaDecision.steps.needs.list.note')}</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('euthanasiaDecision.steps.needs.missingInfo.title')}</Typo>
						<TransParagraphs
							i18nKey={'euthanasiaDecision.steps.needs.missingInfo.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'decision_type',
		label: ({ t }) => t('euthanasiaDecision.steps.decisionType.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.decisionType.title'),
		items: [
			{
				type: 'oneOf',
				name: 'decision_type',
				props: {
					style: 'row',
					options: [
						{ value: 'declaration', label: 'euthanasiaDecision.steps.decisionType.form.declaration' },
						{ value: 'change', label: 'euthanasiaDecision.steps.decisionType.form.change', description: 'euthanasiaDecision.steps.decisionType.form.changeDescription' },
						{ value: 'renew', label: 'euthanasiaDecision.steps.decisionType.form.renew', description: 'euthanasiaDecision.steps.decisionType.form.renewDescription' },
						{ value: 'withdrawal', label: 'euthanasiaDecision.steps.decisionType.form.withdrawal', description: 'euthanasiaDecision.steps.decisionType.form.withdrawalDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.decision_type === 'declaration' ? t('euthanasiaDecision.steps.decisionType.preview.declaration')
			: data.decision_type === 'change' ? t('euthanasiaDecision.steps.decisionType.preview.change')
				: data.decision_type === 'renew' ? t('euthanasiaDecision.steps.decisionType.preview.renew')
					: data.decision_type === 'withdrawal' ? t('euthanasiaDecision.steps.decisionType.preview.withdrawal')
						: '',
	},
	{
		depth: 1,
		route: 'previous_date',
		label: ({ t }) => t('euthanasiaDecision.steps.previousDate.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.previousDate.title'),
		items: [
			{
				type: 'field',
				name: 'previous_date',
				props: {
					type: 'multipart_date',
					label: 'form.date',
				},
			}
		],
		preview: ({ data, t }) => data.previous_date,
		condition: (data) => data.decision_type === 'change' || data.decision_type === 'renew' || data.decision_type === 'withdrawal',
	},
	{
		route: 'witnesses',
		label: ({ t }) => t('euthanasiaDecision.steps.witnesses.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.witnesses.title'),
		subtitle: ({ t }) => t('euthanasiaDecision.steps.witnesses.subtitle'),
		items: [
			{
				type: 'entitiesArray',
				name: 'witnesses',
				refName: 'people_uuid',
				props: {
					maxLength: 2,
					entityName: 'people',
					emptyText: ({ t }) => t('euthanasiaDecision.steps.witnesses.form.emptyList'),
					addButtonText: ({ t }) => t('euthanasiaDecision.steps.witnesses.form.add'),
					defaultValue: peopleDefaultData,
					itemPreviewContent: ({ item, t }) => <PeopleDataPresentation
						data={item}
						partsToShow={[
							'firstNameAndLastName',
							'nationalNumber',
							'address',
							'phone',
							'birthPlaceAndDate',
							'relationship',
						]}
					/>,
					fields: [
						{
							type: 'text',
							label: 'form.firstName',
							name: 'first_name',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.lastName',
							name: 'last_name',
							addRequired: true,
						},
						{
							type: 'national_number',
							label: 'form.nationalNumber',
							sublabel: 'form.nationalNumberSublabel',
							name: 'national_number',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.address.street',
							name: 'address.street',
							addRequired: true,
							gridSizes: {
								lg: 6,
							},
						},
						{
							type: 'text',
							label: 'form.address.number',
							name: 'address.number',
							addRequired: true,
							gridSizes: {
								sm: 6,
								lg: 3,
							},
						},
						{
							type: 'text',
							label: 'form.address.box',
							name: 'address.box',
							gridSizes: {
								sm: 6,
								lg: 3,
							},
						},
						{
							type: 'text',
							label: 'form.address.postalCode',
							name: 'address.postal_code',
							addRequired: true,
							gridSizes: {
								md: 6,
							},
						},
						{
							type: 'text',
							label: 'form.address.town',
							name: 'address.town',
							addRequired: true,
							gridSizes: {
								md: 6,
							},
						},
						{
							type: 'text',
							label: 'form.phone',
							name: 'phone',
							addRequired: true,
						},
						{
							type: 'multipart_date',
							label: 'form.birthDate',
							name: 'birth.date',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.birthPlace',
							name: 'birth.address.town',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.relationship',
							sublabel: 'form.relationshipSublabel',
							name: 'relationship',
						},
					],
				},
			},
		],
		preview: ({ data, t }) => t('euthanasiaDecision.steps.witnesses.preview', { count: data.witnesses?.length || 0 }),
	},
	{
		route: 'has_trusted_person',
		label: ({ t }) => t('euthanasiaDecision.steps.hasTrustedPerson.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.hasTrustedPerson.title'),
		subtitle: ({ t }) => t('euthanasiaDecision.steps.hasTrustedPerson.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_trusted_person',
				props: {
					style: 'row',
					options: [
						{ value: 'yes', label: 'global.yes' },
						{ value: 'no', label: 'global.no' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_trusted_person === 'yes' ? t('global.yes')
			: data.has_trusted_person === 'no' ? t('global.no')
				: '',
	},
	{
		depth: 1,
		route: 'trusted_persons',
		label: ({ t }) => t('euthanasiaDecision.steps.trustedPersons.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.trustedPersons.title'),
		subtitle: ({ t }) => t('euthanasiaDecision.steps.trustedPersons.subtitle'),
		items: [
			{
				type: 'entitiesArray',
				name: 'trusted_persons',
				refName: 'people_uuid',
				props: {
					maxLength: 3,
					entityName: 'people',
					emptyText: ({ t }) => t('euthanasiaDecision.steps.trustedPersons.form.emptyList'),
					addButtonText: ({ t }) => t('euthanasiaDecision.steps.trustedPersons.form.add'),
					defaultValue: peopleDefaultData,
					itemPreviewContent: ({ item, t }) => <PeopleDataPresentation
						data={item}
						partsToShow={[
							'firstNameAndLastName',
							'nationalNumber',
							'address',
							'phone',
							'birthPlaceAndDate',
							'relationship',
						]}
					/>,
					fields: [
						{
							type: 'text',
							label: 'form.firstName',
							name: 'first_name',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.lastName',
							name: 'last_name',
							addRequired: true,
						},
						{
							type: 'national_number',
							label: 'form.nationalNumber',
							sublabel: 'form.nationalNumberSublabel',
							name: 'national_number',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.address.street',
							name: 'address.street',
							addRequired: true,
							gridSizes: {
								lg: 6,
							},
						},
						{
							type: 'text',
							label: 'form.address.number',
							name: 'address.number',
							addRequired: true,
							gridSizes: {
								sm: 6,
								lg: 3,
							},
						},
						{
							type: 'text',
							label: 'form.address.box',
							name: 'address.box',
							gridSizes: {
								sm: 6,
								lg: 3,
							},
						},
						{
							type: 'text',
							label: 'form.address.postalCode',
							name: 'address.postal_code',
							addRequired: true,
							gridSizes: {
								md: 6,
							},
						},
						{
							type: 'text',
							label: 'form.address.town',
							name: 'address.town',
							addRequired: true,
							gridSizes: {
								md: 6,
							},
						},
						{
							type: 'text',
							label: 'form.phone',
							name: 'phone',
							addRequired: true,
						},
						{
							type: 'multipart_date',
							label: 'form.birthDate',
							name: 'birth.date',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.birthPlace',
							name: 'birth.address.town',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.relationship',
							sublabel: 'form.relationshipSublabel',
							name: 'relationship',
						},
					],
				},
			},
		],
		preview: ({ data, t }) => t('euthanasiaDecision.steps.trustedPersons.preview', { count: data.trusted_persons?.length || 0 }),
		condition: (data) => data.has_trusted_person === 'yes',
	},
	{
		route: 'contact_details',
		label: ({ t }) => t('euthanasiaDecision.steps.contactDetails.label'),
		title: ({ t }) => t('euthanasiaDecision.steps.contactDetails.title'),
		items: [
			{
				type: 'field',
				name: 'email',
				props: {
					type: 'email',
					label: 'form.email',
				},
			},
			{
				type: 'field',
				name: 'first_name',
				props: {
					label: 'form.firstName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'last_name',
				props: {
					label: 'form.lastName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'birth_date',
				props: {
					type: 'date',
					label: 'form.birthDate',
				},
				gridSizes: {
					sm: 6,
				},
			},
		],
		preview: ({ data, t }) => data.email,
	},
];

export function Euthanasia() {
	const { t } = useTranslation();

	// Store
	const { isLoading: peoplesLoading, data: peoples = [] } = useQuery('peoples',
		/* No need for remote for now
		() => !!getAuthToken()
			? fetchPeoples()
			: getLocalStorageData('peoples', [])
		*/
		() => getLocalStorageData('peoples', [])
	);
	const peoplesLoaded = useConditionValidatedOnce(peoplesLoading, isFalse);

	// const { isLoading: serviceEuthanasiaDecisionLoading, data: serviceEuthanasiaDecision } = useQuery('serviceEuthanasiaDecision', () => fetchService({ type: 'euthanasia_decision' }));
	const { isLoading: serviceEuthanasiaDecisionLoading, data: serviceEuthanasiaDecision } = useQuery('serviceEuthanasiaDecision',
		/* No need for remote for now
		() => !!getAuthToken()
			? fetchService({ type: 'euthanasia_decision' })
			: getLocalStorageData('euthanasia_decision')
		*/
		() => getLocalStorageData('euthanasia_decision')
	);
	const serviceEuthanasiaDecisionLoaded = useConditionValidatedOnce(serviceEuthanasiaDecisionLoading, isFalse);

	const serviceEuthanasiaDecisionData = (serviceEuthanasiaDecisionLoaded && peoplesLoaded) ? (serviceEuthanasiaDecision?.data || null) : undefined;

	const queryClient = useQueryClient();

	const updateMutation = useMutation((data = defaultData) => {
		return updateService({ type: 'euthanasia_decision', data });
	}, {
		onSettled: () => {
			queryClient.invalidateQueries('serviceEuthanasiaDecision');
		},
	});
	function save(data) {
		// if (!!getAuthToken()) return updateMutation.mutateAsync(data); // No need for remote for now

		setLocalStorageData({ id: 'euthanasia_decision', data: { data: data, }, });
	}

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	} = useFormValues({
		defaultData,
		dataSchema,
		context: { peoples: peoples },
	});

	const postMutation = useMutation(({ data, steps }) => {
		return postEuthanasiaDecision(data, steps);
	});

	async function sendData() {
		const response = await postMutation.mutateAsync({ data, steps });

		setLocalStorageData({ id: 'advance_decisions_completed', data: 'euthanasia', storageType: 'array' });

		// if (!!response) localStorage.removeItem('euthanasia_decision');

		return response;
	}

	const isMedium = useMediaQuery(mq('md'));

	return <>
		<ContainerWithSidebar
			sidebarFitDesktopScreen={true}
			showSidebarOnMobile={true}
			sidebar={
				<>
					<Tutorial
						id='stepper_menu'
						placement={isMedium ? 'right-start' : 'bottom'}
						description={t('tutorials.stepperMenu')}
					>
						<div>
							<Typo
								component='p'
								variant='title-2'
								css={{ marginBottom: spacing(2), }}
							>{t('stepper.menu.title')}</Typo>
						</div>
					</Tutorial>
					<ScrollDisplay
						component={CardBox}
						wrapperCss={{
							padding: 0,
						}}
						css={{
							padding: spacing(4),
						}}
					>
						<StepperMenu
							steps={steps}
							data={data}
							errors={errors}
						/>
					</ScrollDisplay>
				</>
			}
		>
			<div
				css={{
					paddingBottom: spacing(12),
				}}
			>
				<Stepper
					save={save}
					queryKey={'euthanasia_decision'}
					storeData={serviceEuthanasiaDecisionData}
					steps={steps}
					data={data}
					setData={setData}
					defaultData={defaultData}
					errors={errors}
					updateFormErrors={updateErrors}
					getFormErrors={getErrors}
					getFieldValues={getFieldValues}
					allowInvalid={false}
					validEndRedirect={'/advance-decisions?success=euthanasia'}
					endCallback={sendData}
				/>
			</div>
		</ContainerWithSidebar>
		<Container>
			<div
				css={{
					marginBottom: spacing(12),
					borderBottom: '1px solid',
					borderColor: palette.gray[300],
				}}
			/>
		</Container>
	</>
}