import { getFormattedDate } from "../lib/dates";
import { getFlattenedObject } from "../lib/objects";
import { request } from "../lib/requests";
import { insertEntities } from "./_advancedDecisionsHelpers";

export async function postOrganDonationDecision(data, steps) {
	const newData = await insertEntities(data, steps);

	if (!newData) return false;

	const flattenedData = getFlattenedObject(newData);

	alterData(flattenedData);

	try {
		const response = await request({
			method: 'POST',
			url: 'https://hooks.zapier.com/hooks/catch/8288466/3hejnb5/',
			data: flattenedData,
		});

		return true;
	} catch (error) {
		console.error(error);

		return false;
	}
}

function alterData(data) {
	// Add age
	[
		{
			birth_date: 'birth_date',
			age: 'age',
		},
	].forEach(keys => {
		const ageDifMs = Date.now() - new Date(data[keys.birth_date]).getTime();
		const ageDate = new Date(ageDifMs); // miliseconds from epoch
		data[keys.age] = `${Math.abs(ageDate.getUTCFullYear() - 1970)}`;
	});

	// Update dates
	[
		'birth_date',
	].forEach(key => {
		if (!data[key]) return;

		data[key] = getFormattedDate(data[key]);
	});
}
