/** @jsxImportSource @emotion/react */

import { ReactComponent as ChevronLeft } from "@material-design-icons/svg/filled/chevron_left.svg";
import { useTranslation } from "react-i18next";
import { animations, mq, spacing } from "../../theme";
import { Button } from "../ui/Button";
import { NarrowContainer } from "../ui/NarrowContainer";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";

export function StepperStep({
	title = '',
	subtitle = '',
	image = null,
	animateForwards = true, // true = forwards, false = backwards
	data = null,
	handleBack = null,
	children,
}) {
	const { t } = useTranslation();

	return <NarrowContainer
		css={{
			animation: `${animations.fadeIn} 0.5s, ${animateForwards ? animations.slideInRight : animations.slideInLeft} 0.5s`,
		}}
	>
		{
			image &&
			<img
				src={image}
				alt=''
				// height='356'
				css={{
					height: '208px', // h-52
					[mq('sm')]: {
						height: '256px', // h-64
					},
					[mq('md')]: {
						height: '208px',
					},
					[mq('xl')]: {
						height: '256px',
					},
					marginLeft: 'auto',
					marginRight: 'auto',
					marginBottom: spacing(4),
				}}
			/>
		}
		<div
			css={{
				marginBottom: spacing(8),
				marginLeft: spacing(4),
				marginRight: spacing(4),
			}}
		>
			<Typo
				component='h1'
				variant='marketing-title'
				css={{
					textAlign: 'center',
					margin: 'auto',
				}}
			>
				{typeof title === 'function' ? title({ t, data }) : title}
			</Typo>
			{
				subtitle &&
				<Typo
					component='p'
					variant='title-2'
					css={{
						textAlign: 'center',
						margin: 'auto',
						marginTop: spacing(6),
					}}
				>
					{typeof subtitle === 'function' ? subtitle({ t, data }) : subtitle}
				</Typo>
			}
		</div>
		{
			children
		}
		{
			handleBack &&
			<div
				css={{
					marginTop: spacing(8),
					marginBottom: spacing(8),
					marginLeft: spacing(5),
					marginRight: spacing(5),
				}}
			>
				<Button
					variant='underlined'
					onClick={handleBack}
					startIcon={<Svg svg={ChevronLeft} />}
				>{t('global.back')}</Button>
			</div>
		}
	</NarrowContainer>
}