import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationFR from './lang/fr.json';
import translationNL from './lang/nl.json';

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			fr: {
				translation: translationFR,
			},
			nl: {
				translation: translationNL,
			},
		},
		// lng: 'fr', // if you're using a language detector, do not define the lng option
		fallbackLng: 'fr',

		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	});