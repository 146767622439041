import React, { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CardModal } from "./components/ui/CardModal";
import { ScrollTopWrapper } from './components/utils/ScrollTopWrapper';
import { AuthTokenContext } from "./contexts/AuthToken";
import { ErrorContext } from "./contexts/Error";
import { DefaultLayout } from "./layouts/Default";
import { LandingLayout } from "./layouts/Landing";
import { getAuthToken } from "./lib/authToken";
import { AdvanceDecisions as GuestAdvanceDecisions } from "./pages/AdvanceDecisions";
import { SuccessionOnboarding } from "./pages/SuccessionOnboarding";
import { SuccessionOnboardingInvalidUrl } from "./pages/SuccessionOnboardingInvalidUrl";
import { SuccessionOnboardingSuccess } from "./pages/SuccessionOnboardingSuccess";
import { Euthanasia } from "./pages/account/before/legalDocuments/advanceDecisions/Euthanasia";
import { Funeral } from "./pages/account/before/legalDocuments/advanceDecisions/Funeral";
import { Negative } from "./pages/account/before/legalDocuments/advanceDecisions/Negative";
import { OrganDonation } from "./pages/account/before/legalDocuments/advanceDecisions/OrganDonation";

const pathnamesProps = [
	{
		pathname: '/login',
		props: { bgColor: 'white', },
	},
	{
		pathname: '/forgot',
		props: { bgColor: 'white', },
	},
	{
		pathname: '/register',
		props: { bgColor: 'white', },
	},
];

const pathnamesLayouts = [
	{
		pathname: '/succession-onboarding',
		layout: LandingLayout,
	},
	{
		pathname: '/succession-onboarding-success',
		layout: LandingLayout,
	},
	{
		pathname: '/succession-onboarding-invalid-url',
		layout: LandingLayout,
	},
	{
		pathname: '/advance-decisions',
		layout: LandingLayout,
	},
];

export default function App() {
	// Contexts
	const { updateAuthToken } = useContext(AuthTokenContext);
	const { error: appError, setError: setAppError } = useContext(ErrorContext);

	// Token on load
	useEffect(() => {
		const newToken = getAuthToken();
		if (!!newToken) updateAuthToken(newToken);
	}, []);

	return <div>
		<BrowserRouter>
			<DefaultLayout
				pathnamesProps={pathnamesProps}
				pathnamesLayouts={pathnamesLayouts}
			>
				<CardModal
					open={!!appError}
					{...appError}
				/>
				<ScrollTopWrapper>
					<Routes>
						<Route path='*' index element={<Navigate to={'/advance-decisions'} replace />} />
						<Route path='/succession-onboarding/*' element={<SuccessionOnboarding />} />
						<Route path='/succession-onboarding-success' element={<SuccessionOnboardingSuccess />} />
						<Route path='/succession-onboarding-invalid-url' element={<SuccessionOnboardingInvalidUrl />} />
						{
							// <Route path='/login' element={<Login />} />
							// <Route path='/forgot' element={<Forgot />} />
							// <Route path='/register' element={<Register />} />
							// <Route path='/contact' element={<Contact />} />
						}
						{/* <Route path='/pricing' element={<Pricing />} /> */}
						{/* <Route path='/discover/*'
						element={
							<Routes>
								<Route path='/' element={<Discover />} />
								<Route path='/before/*' element={<DiscoverBefore />} />
								<Route path='/after/*' element={<DiscoverAfter />} />
							</Routes>
						}
						/> */}
						<Route path='/advance-decisions/*'
							element={
								<Routes>
									<Route path='/' element={<GuestAdvanceDecisions />} />
									<Route path='/negative/*' element={<Negative />} />
									<Route path='/euthanasia/*' element={<Euthanasia />} />
									<Route path='/organ-donation/*' element={<OrganDonation />} />
									<Route path='/funeral/*' element={<Funeral />} />
								</Routes>
							}
						/>
						{
							// <Route path='/account/*'
							// 	element={
							// 		<RequireAuth>
							// 			<Routes>
							// 				{/* <Route path='/' element={<Account />} /> */}
							// 				<Route path='/' element={<Navigate to={'/account/before/legal-documents/advance-decisions'} replace />} />
							// 				<Route path='/me/*'
							// 					element={
							// 						<Routes>
							// 							{/* <Route path='/' element={<Me />} /> */}
							// 							<Route path='/' element={<Navigate to={'/account/me/data/about/personal'} replace />} />
							// 							{/* <Route path='/settings' element={<WorkInProgress />} /> */}
							// 							<Route path='/data/*'
							// 								element={
							// 									<Routes>
							// 										{/* <Route path='/' element={<Data />} /> */}
							// 										<Route path='/' element={<Navigate to={'/account/me/data/about/personal'} replace />} />
							// 										<Route path='/about/*'
							// 											element={
							// 												<Routes>
							// 													{/* <Route path='/' element={<About />} /> */}
							// 													<Route path='/' element={<Navigate to={'/account/me/data/about/personal'} replace />} />
							// 													<Route path='/personal' element={<Personal />} />
							// 													{/* <Route path='/partner' element={<WorkInProgress />} /> */}
							// 													{/* <Route path='/heirs' element={<WorkInProgress />} /> */}
							// 													{/* <Route path='/pets' element={<WorkInProgress />} /> */}
							// 												</Routes>
							// 											}
							// 										/>
							// 										{/* <Route path='/assets' element={<WorkInProgress />} /> */}
							// 										{/* <Route path='/last-will' element={<WorkInProgress />} /> */}
							// 									</Routes>
							// 								}
							// 							/>
							// 						</Routes>
							// 					}
							// 				/>
							// 				<Route path='/before/*'
							// 					element={
							// 						<Routes>
							// 							{/* <Route path='/' element={<Before />} /> */}
							// 							<Route path='/' element={<Navigate to={'/account/before/legal-documents/advance-decisions'} replace />} />
							// 							{/* <Route path='/summary' element={<Summary />} /> */}
							// 							<Route path='/summary' element={<Navigate to={'/account/before/legal-documents/advance-decisions'} replace />} />
							// 							<Route path='/legal-documents/*'
							// 								element={
							// 									<Routes>
							// 										{/* <Route path='/' element={<LegalDocuments />} /> */}
							// 										<Route path='/' element={<Navigate to={'/account/before/legal-documents/advance-decisions'} replace />} />
							// 										{/* <Route path='/testament' element={<WorkInProgress />} /> */}
							// 										{/* <Route path='/mandate' element={<WorkInProgress />} /> */}
							// 										{/* <Route path='/insurances' element={<Insurances />} /> */}
							// 										<Route path='/advance-decisions/*'
							// 											element={
							// 												<Routes>
							// 													<Route path='/' element={<AdvanceDecisions />} />
							// 													<Route path='/documents' element={<AdvanceDecisionsDocuments />} />
							// 													{/* <Route path='/negative' element={<WorkInProgress />} />
							// 														<Route path='/euthanasia' element={<WorkInProgress />} />
							// 														<Route path='/organ-donation/*' element={<WorkInProgress />} />
							// 														<Route path='/funeral/*' element={<WorkInProgress />} /> */}
							// 												</Routes>
							// 											}
							// 										/>
							// 									</Routes>
							// 								}
							// 							/>
							// 							{/* <Route path='/box/*'
							// 									element={
							// 										<Routes>
							// 											<Route path='/' element={<AccountBox />} />
							// 											<Route path='/contacts' element={<WorkInProgress />} />
							// 											<Route path='/letters' element={<WorkInProgress />} />
							// 											<Route path='/videos' element={<WorkInProgress />} />
							// 											<Route path='/digital-end' element={<WorkInProgress />} />
							// 											<Route path='/practical-information' element={<WorkInProgress />} />
							// 											<Route path='/pets' element={<WorkInProgress />} />
							// 										</Routes>
							// 									}
							// 								/> */}
							// 						</Routes>
							// 					}
							// 				/>
							// 				{/* <Route path='/after/*'
							// 						element={
							// 							<Routes>
							// 								<Route path='/' element={<After />} />
							// 								<Route path='/:folderId/*'
							// 									element={
							// 										<Routes>
							// 											<Route path='/' element={<Folder />} />
							// 											<Route path='/documents' element={<Documents />} />
							// 										</Routes>
							// 									}
							// 								/>
							// 							</Routes>
							// 						}
							// 					/> */}
							// 			</Routes>
							// 		</RequireAuth>
							// 	}
							// />
						}
					</Routes>
				</ScrollTopWrapper>
			</DefaultLayout>
		</BrowserRouter>
	</div>
}
