/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import { ReactComponent as Add } from "@material-design-icons/svg/filled/add.svg";
import { ReactComponent as Delete } from "@material-design-icons/svg/filled/delete.svg";
import { ReactComponent as Edit } from "@material-design-icons/svg/filled/edit.svg";
import { ReactComponent as Sync } from "@material-design-icons/svg/filled/sync.svg";
import _get from 'lodash.get';
import mergeWith from "lodash.mergewith";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useEntity } from "../../hooks/entity";
import { useForm } from "../../hooks/form";
import { getEntity } from "../../lib/entities";
import { replaceArrays } from "../../lib/mergeWithCustomizers";
import { animations, palette, spacing } from "../../theme";
import { LoadingCardBox } from "../common/LoadingCardBox";
import { Field } from "../form/Field";
import { Button } from "../ui/Button";
import { CardBox } from "../ui/CardBox";
import { CardModal } from "../ui/CardModal";
import { FlexGrid } from "../ui/FlexGrid";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";
import { Error } from "./Error";
import { SelectableCardButton } from "./SelectableCardButton";

export function EntitiesArray({
	name = '',
	refName = 'r_uuid',
	defaultValue = null,
	entityName = '',
	allowedTypes = [],
	maxLength = 0,
	fields = [],
	emptyText = '',
	addButtonText = '',
	selectTypeText = '',
	itemPreviewContent = '',
	data = null,
	errors = null,
	handleChange: givenHandleChange = () => { },
	handleAdd: givenHandleAdd = () => { },
	handleRemove: givenHandleRemove = () => { },
	updateFormErrors = () => { },
	handleChange = () => { },
	handleBlur = () => { },
	triedSubmit = false,
}) {
	const { t } = useTranslation();

	// Store
	const {
		isLoading: entitiesLoading,
		data: entities = [],
		defaultData: entityDefaultData,
		dataSchema: entityDataSchema,
		save: entitySave,
	} = useEntity(entityName);

	const dataArray = _get(data, name);
	const dataArrayLength = dataArray?.length || 0;
	const uuidsArray = dataArray.map(i => i[refName]).filter(i => !!i);

	const [modalUuid, setModalUuid] = useState(null); // uuid string when updating, true when creating
	function handleOpen(index) {
		const entity = entities.find(entity => entity.uuid === dataArray[index][refName]);

		if (!entity?.uuid) return; // TODO: Handle as error?

		setModalUuid(entity.uuid);
	}
	function handleNew() {
		setModalUuid(true);
	}
	function closeModal() {
		setModalUuid(null);
		setEntityModalError(false);
	}

	const [entityModalError, setEntityModalError] = useState(false);

	async function handleAdd(data, type = '') {
		const entity = await entitySave(null, type ? { ...data, _type: type, } : data);

		if (!entity?.uuid) {
			setEntityModalError(true);

			return;
		}

		givenHandleAdd(name, { [refName]: entity.uuid });

		closeModal();
		updateFormErrors(true);
	}
	function handleSelect(uuid) {
		givenHandleAdd(name, { [refName]: uuid });

		closeModal();
		updateFormErrors(true);
	}
	async function handleUpdate(uuid, data) {
		const response = await entitySave(uuid, data);

		if (!response) {
			setEntityModalError(true);

			return;
		}

		closeModal();
		updateFormErrors(true);
	}
	function handleRemove(index) {
		givenHandleRemove(name, index);
		updateFormErrors(true);
	}

	useEffect(() => {
		// When updating store data
		updateFormErrors();
	}, [entities]);

	return <>
		{
			entitiesLoading
				?
				<LoadingCardBox />
				:
				<>
					<FlexGrid container spacing={2}>
						{
							dataArrayLength > 0 ?
								dataArray.map((item, itemIndex) => {
									const entity = entities.find(entity => entity.uuid === item[refName]);

									const currentError = _get(errors, `${name}[${itemIndex}].${refName}`);

									return <FlexGrid
										key={itemIndex}
										xs={12}
									>
										<CardBox
											css={{
												padding: spacing(4),
											}}
										>
											{
												!!entity
													?
													!!entity.data
														?
														<>
															<div
																css={{
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																}}
															>
																<div>
																	{
																		typeof itemPreviewContent === 'function' ? itemPreviewContent({ item: entity.data, t }) : itemPreviewContent
																	}
																</div>
																<div
																	css={{
																		marginLeft: 'auto',
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																	}}
																>
																	<Button
																		onClick={() => handleOpen(itemIndex)}
																		variant='icon'
																		css={{ marginLeft: spacing(2), }}
																	><Svg svg={Edit} aria-hidden={true} /></Button>
																	<Button
																		onClick={() => handleRemove(itemIndex)}
																		variant='icon'
																		css={{ marginLeft: spacing(2), }}
																	><Svg svg={Delete} aria-hidden={true} /></Button>
																</div>
															</div>
															{
																currentError &&
																<div
																	css={{
																		marginTop: spacing(2),
																	}}
																>
																	<Error>
																		{
																			(typeof currentError === 'function') ?
																				t(currentError().key, currentError().values)
																				:
																				t(currentError)
																		}
																	</Error>
																</div>
															}
														</>
														:
														<div
															css={{
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
															}}
														>
															<div>
																<Error>{t('stepper.entitiesArray.noData')}</Error>
															</div>
															<div
																css={{
																	marginLeft: 'auto',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																}}
															>
																<Button
																	onClick={() => handleRemove(itemIndex)}
																	variant='icon'
																	css={{ marginLeft: spacing(2), }}
																><Svg svg={Delete} aria-hidden={true} /></Button>
															</div>
														</div>
													:
													<div
														css={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														<div>
															<Error>{t('stepper.entitiesArray.deleted')}</Error>
														</div>
														<div
															css={{
																marginLeft: 'auto',
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
															}}
														>
															<Button
																onClick={() => handleRemove(itemIndex)}
																variant='icon'
																css={{ marginLeft: spacing(2), }}
															><Svg svg={Delete} aria-hidden={true} /></Button>
														</div>
													</div>
											}
										</CardBox>
									</FlexGrid>
								}
								)
								:
								<FlexGrid
									xs={12}
								>
									<CardBox
										css={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<Typo
											variant='regular'
											component='p'
										>{(typeof emptyText === 'function' ? emptyText({ t }) : emptyText) || t('form.emptyList')}</Typo>
									</CardBox>
								</FlexGrid>
						}
					</FlexGrid>
					{
						(triedSubmit && _get(errors, `${name}`) && !Array.isArray(_get(errors, `${name}`))) &&
						<Error>
							{
								(typeof _get(errors, `${name}`) === 'function') ?
									t(_get(errors, `${name}`)().key, _get(errors, `${name}`)().values)
									:
									t(_get(errors, `${name}`))
							}
						</Error>
					}
					<Button
						variant='outlined'
						color='secondary'
						onClick={handleNew}
						startIcon={<Svg svg={Add} />}
						css={{
							marginTop: spacing(4),
							marginBottom: spacing(8),
							...dataArrayLength >= maxLength && {
								opacity: 0.5,
								cursor: 'not-allowed',
							},
						}}
						disabled={dataArrayLength >= maxLength}
					>{(typeof addButtonText === 'function' ? addButtonText({ t }) : addButtonText) || t('global.add')}</Button>
				</>
		}
		{
			!!modalUuid && // This will force `EntityModal` to remount and reset its states
			<EntityModal
				entityName={entityName}
				allowedTypes={allowedTypes}
				uuid={modalUuid}
				entities={entities}
				entityDefaultData={entityDefaultData}
				entityDataSchema={entityDataSchema}
				uuidsBlacklist={uuidsArray}
				onClose={closeModal}
				data={data}
				errors={errors}
				fields={fields}
				name={name}
				handleChange={handleChange}
				handleBlur={handleBlur}
				updateFormErrors={updateFormErrors}
				triedSubmit={triedSubmit}
				handleAdd={handleAdd}
				handleSelect={handleSelect}
				handleUpdate={handleUpdate}
				saveError={entityModalError}
				selectTypeText={selectTypeText}
				entitiesLoading={entitiesLoading}
			/>
		}
	</>
}

function EntityModal({
	entityName = '',
	allowedTypes = [],
	uuid = null, // uuid string when updating, true when creating 
	entities = [],
	entityDefaultData = null,
	entityDataSchema = null,
	uuidsBlacklist = [],
	onClose: givenOnClose = () => { },
	data: givenData = null,
	errors: givenErrors = null,
	fields = [],
	name: givenName = '',
	handleChange: givenHandleChange = () => { },
	handleBlur: givenHandleBlur = () => { },
	updateFormErrors: givenUpdateFormErrors = () => { },
	triedSubmit: givenTriedSubmit = false,
	handleAdd: givenHandleAdd = () => { },
	handleSelect: givenHandleSelect = () => { },
	handleUpdate: givenHandleUpdate = () => { },
	saveError = false,
	selectTypeText = '',
	entitiesLoading = false,
}) {
	const { t } = useTranslation();

	const [entityCreation, setEntityCreation] = useState(false);

	// TODO: Find a way to handle entities' own data AND service data (but differ the service data update and apply only after entity submit)
	// This way we could update/create the entity but when submitting, service data would be updated if needed (e.g.: some extra data to put aside the 'r_uuid' in the service)

	const requiredPaths = fields.map(i => i.addRequired ? i.name : null).filter(i => i);

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		triedSubmit,
		setTriedSubmit,
		message: formMessage,
		setMessage: setFormMessage,
		getFieldProps,
	} = useForm({
		defaultData: entityDefaultData,
		dataSchema: entityDataSchema,
		context: {
			requiredPaths: requiredPaths, // Add context for `customPotentiallyRequired`
		},
	});

	async function handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();

		setTriedSubmit(true);

		if (!triedSubmit) {
			setTriedSubmit(true);

			const errors = await getErrors(); // Error state not up to date

			if (!!errors) return;
		}

		setEntityCreation(false);
		selectType('');

		if (entityCreation) {
			givenHandleAdd(data, typeData.type);
		} else {
			givenHandleUpdate(uuid, data);
		}
	}

	// Load user data
	useEffect(() => {
		if (!uuid || uuid === true) {
			setData(mergeWith({}, entityDefaultData, replaceArrays));
			return;
		};

		const entityData = entities.find(entity => entity.uuid === uuid)?.data;

		if (!entityData) return;

		setData(mergeWith({}, entityDefaultData, entityData, replaceArrays));
		updateErrors(true);
	}, [entities, uuid]);

	function handleNew() {
		setEntityCreation(true);
		selectType('');
	}

	function handleSelect(uuid) {
		givenHandleSelect(uuid);
	}

	// Selected type form
	const {
		data: typeData,
		handleChange: handleTypeChange,
		getFieldProps: getTypeFieldProps,
	} = useForm({
		defaultData: { type: '', },
		dataSchema: yup.object(),
	});

	function selectType(type) {
		handleTypeChange(['type', type]);
	}

	useEffect(() => {
		if (allowedTypes.length === 1) selectType(allowedTypes[0].value || '');
	}, [allowedTypes, selectType]);

	function onClose() {
		setEntityCreation(false);
		selectType('');

		givenOnClose();
	}

	// Data presentation
	const {
		selectDataPresentationComponent,
		selectDataPresentationPartsToShow,
	} = getEntity(entityName);

	const selectableEntities = entities
		.filter(entity => !!entity.data) // No data in entity shouldn't happen
		.filter(entity => !uuidsBlacklist.includes(entity.uuid));

	return (uuid !== true || entityCreation)
		?
		( // If...
			!entities.find(i => i.uuid === uuid)?.type // Type not already given (eg.: by updating existing entry)
			&& allowedTypes.length > 1 // Mutliple types allowed
			&& !typeData?.type // Type not already selected
		) // ...then show type selector
			?
			<CardModal
				open={!!uuid}
				onClose={onClose}
				header={
					<Typo
						component='h2'
						variant='title-2'
					>{(typeof selectTypeText === 'function' ? selectTypeText({ t }) : selectTypeText) || t('stepper.entitiesArray.selectType')}</Typo>
				}
			>
				<FlexGrid
					container
					spacing={2}
				>
					{
						allowedTypes.map((type, typeIndex) =>
							<FlexGrid
								key={typeIndex}
								xs={12}
							>
								<SelectableCardButton
									{...getTypeFieldProps('type')}
									htmlValue={type.value}
									label={type.label}
									size='small'
									textAlign='left'
								/>
							</FlexGrid>
						)
					}
				</FlexGrid>
			</CardModal>
			:
			<CardModal
				open={!!uuid}
				onClose={onClose}
			>
				<form
					{...entitiesLoading && { disabled: true, }}
					onSubmit={handleSubmit}
					{...entitiesLoading && { css: { opacity: 0.5, }, }}
				>
					<FlexGrid
						container
						spacing={2}
						css={{
							marginBottom: spacing(4),
						}}
					>
						{
							fields.map(({
								name: fieldName,
								gridSizes: fieldGridSizes = {},
								addRequired, /* Remove from in `fieldProps` */
								...fieldProps
							}, fieldIndex) =>
								<FlexGrid
									key={fieldIndex}
									xs={12}
									{...fieldGridSizes}
								>
									<Field
										// TODO: Maybe use fieldName as a function to handle entity/service data separatly?
										// {...getFieldProps(typeof fieldName === 'function' ? fieldName(`${givenName}[${uuid}]`) : fieldName)}
										{...getFieldProps(fieldName)}
										{...fieldProps}
									// handleBlur={handleBlur}
									/>
								</FlexGrid>
							)
						}
					</FlexGrid>
					{
						saveError &&
						<div
							css={{
								marginBottom: spacing(4),
							}}
						>
							<Error>{t('global.errorOccurredContactAdmin')}</Error>
						</div>
					}
					{
						(triedSubmit && !!errors) ?
							<CardBox
								css={{
									padding: spacing(4),
									backgroundColor: palette.bg.light,
								}}
							>
								<Typo
									component='p'
									variant='regular'
									css={{
										color: palette.red[500],
										marginBottom: spacing(2),
									}}
								>{t('stepper.entitiesArray.formInvalid')}</Typo>
								<Button
									type='submit'
								>{t('global.saveAndContinue')}</Button>
							</CardBox>
							:
							<Button
								type='submit'
							>{t('global.continue')}</Button>
					}
				</form>
			</CardModal>
		:
		<CardModal
			open={!!uuid}
			onClose={onClose}
			header={
				<Typo
					component='h2'
					variant='title-2'
				>{t('stepper.entitiesArray.selectEntry')}</Typo>
			}
			footer={
				<Button
					{...entitiesLoading && { disabled: true, }}
					{...selectableEntities.length === 0 ? {
						variant: 'contained',
						color: 'primary',
					} : {
						variant: 'outlined',
						color: 'secondary',
					}}
					onClick={handleNew}
					startIcon={<Svg svg={Add} />}
				>{t('global.create')}</Button>
			}
		>
			{
				entitiesLoading
					?
					<CardBox
						css={{
							padding: spacing(4),
						}}
					>
						<Typo
							component='p'
							variant='regular'
							css={{
								fontWeight: 600,
								marginBottom: spacing(2),
							}}
						>
							{t('global.loading.title')}
							<Svg
								svg={Sync}
								aria-hidden={true}
								css={{
									marginLeft: spacing(2),
									animation: `${animations.rotateAntiClockwise} 1s linear infinite`,
								}}
							/>
						</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{
								color: palette.blue[200],
							}}
						>{t('global.loading.description')}</Typo>
					</CardBox>
					:
					<FlexGrid container spacing={2}>
						{
							selectableEntities.length > 0
								?
								selectableEntities.map((entity) =>
									<FlexGrid
										key={entity.uuid}
										xs={12}
									>
										<CardBox
											component={Button}
											variant='none'
											onClick={() => handleSelect(entity.uuid)}
											css={{
												// display: 'flex',
												// flexDirection: 'row',
												// alignItems: 'center',
												padding: spacing(4),
												width: '100%',
												textAlign: 'left',
												boxShadow: '0 4px 8px -2px rgb(0 0 0 / 10%)',
												border: '2px solid',
												borderColor: palette.gray[200],
												'&:hover, &:focus': {
													borderColor: palette.blue[400],
												},
											}}
										>
											{
												jsx(
													selectDataPresentationComponent,
													{
														key: entity.uuid,
														data: entity.data,
														partsToShow: selectDataPresentationPartsToShow,
													},
												)
											}
										</CardBox>
									</FlexGrid>
								)
								:
								<FlexGrid
									xs={12}
								>
									<CardBox
										css={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<Typo
											variant='regular'
											component='p'
										>{t('form.emptyList')}</Typo>
									</CardBox>
								</FlexGrid>
						}
					</FlexGrid>
			}
		</CardModal>
}