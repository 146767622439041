/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import { ReactComponent as East } from "@material-design-icons/svg/filled/east.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { palette, spacing } from "../../theme";
import { FlexGrid } from "../ui/FlexGrid";
import { LinkButton } from "../ui/LinkButton";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";

export function HeaderComplexMenu({
	items = [],
	label = '',
	route = '',
	handleClose = () => { },
	...props
}) {
	const { t } = useTranslation();

	const columns = (function () {
		// position: ['mobileColumnOrder', 'desktopColumnOrder.desktopSubColumnOrder']
		const sortedItems = items.sort((a, b) => Number(a.position[1].split('.')[0]) - Number(b.position[1].split('.')[0])
			|| Number(a.position[1].split('.')[1]) - Number(b.position[1].split('.')[1]));
		const orderedColumns = [];
		sortedItems.forEach(i => {
			const columnNumber = Number(i.position[1].split('.')[0]);
			orderedColumns[columnNumber]?.push(i) || (orderedColumns[columnNumber] = [i]);
		});

		return orderedColumns;
	})();

	return <div
		css={{
			margin: 'auto',
			padding: spacing(4),
			paddingTop: spacing(8),
			paddingBottom: spacing(6),
		}}
		{...props}
	>
		<FlexGrid
			container
			css={{ justifyContent: 'center', }}
		>
			{
				columns.map((column, columnIndex) =>
					<FlexGrid
						key={columnIndex}
						xs={12} md
					>
						{
							column.map((subColumn, subColumnIndex) =>
								<div key={subColumnIndex}>
									<LinkButton
										to={subColumn.route}
										onClick={handleClose}
										variant='underlinedHover'
										{...subColumn.icon && {
											startIcon: jsx(subColumn.icon),
											startIconProps: {
												css: {
													marginRight: spacing(1),
													width: 24,
													'& svg .secondaryColor': {
														fill: palette.yellow[400],
														stroke: palette.yellow[400],
													},
												},
											},
										}
										}
										css={{
											fontWeight: 500,
										}}
									>
										{
											// subColumn.icon &&
											// <div
											// 	aria-hidden={true}
											// 	css={{
											// 		marginRight: spacing(1),
											// 		width: 24,
											// 		'& svg .secondaryColor': {
											// 			fill: palette.yellow[400],
											// 			stroke: palette.yellow[400],
											// 		},
											// 	}}
											// >
											// 	{jsx(subColumn.icon)}
											// </div>
										}
										{typeof subColumn.label === 'function' ? subColumn.label({ t }) : subColumn.label}
									</LinkButton>
									{
										subColumn.items &&
										<Typo
											component='ul'
											variant='small'
											css={{
												paddingLeft: spacing(10),
												margintop: spacing(2),
												marginBottom: spacing(4),
											}}
										>
											{
												subColumn.items.map((item, itemIndex) =>
													<Typo
														key={itemIndex}
														component='li'
														variant='inherit'
													>
														<LinkButton
															to={item.route}
															onClick={handleClose}
															variant='underlinedHover'
														>{typeof item.label === 'function' ? item.label({ t }) : item.label}</LinkButton>
													</Typo>
												)
											}
										</Typo>
									}
								</div>
							)
						}
					</FlexGrid>
				)
			}
		</FlexGrid>
		{
			label &&
			<div
				css={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: spacing(4),
				}}
			>
				<LinkButton
					to={route}
					onClick={handleClose}
					variant='underlinedHover'
					endIcon={<Svg svg={East} />}
				>{typeof label === 'function' ? label({ t }) : label}</LinkButton>
			</div>
		}
	</div>
}