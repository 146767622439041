/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../lib/dates";
import { palette, spacing } from "../../theme";
import { Typo } from "../ui/Typo";

export function InsuranceDataPresentation({
	data = {},
	partsToShow = [],
}) {
	const { t } = useTranslation();

	function getString(data, part) {
		switch (part) {
			case 'companyName':
				return `${t('dataPresentation.companyName')} ${data.company?.name || '.....'}`;
			case 'contractNumber':
				return `${t('dataPresentation.contractNumber')} ${data.contract_number || '.....'}`;
			case 'signatureDate':
				return `${t('dataPresentation.signatureDate')} ${getFormattedDate(data.signature?.date) || '.....'}`;
			default:
				return '';
		}
	}

	// TODO: Show type of insurance

	return <>
		{
			partsToShow.map((item, index, array) =>
				<Typo
					key={index}
					variant='regular'
					component='p'
					css={{
						...index === 0
							? {
								fontWeight: 600,
								...array.length > 1 && {
									marginBottom: spacing(2),
								},
							}
							: {
								color: palette.blue[200],
							}
					}}
				>{getString(data, item)}</Typo>
			)
		}
	</>
}