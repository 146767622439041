/** @jsxImportSource @emotion/react */

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { postLogin, postRegister } from "../../api/auth";
import { AuthTokenContext } from "../../contexts/AuthToken";
import { ErrorContext } from "../../contexts/Error";
import { useForm } from "../../hooks/form";
import { setYupLocale } from "../../lib/setYupLocale";
import { addCustomEmailValidator } from "../../lib/validators";
import { palette, spacing } from "../../theme";
import { Field } from "../form/Field";
import { Button } from "../ui/Button";
import { CardBox } from "../ui/CardBox";
import { CardModal } from "../ui/CardModal";
import { LinkButton } from "../ui/LinkButton";
import { Typo } from "../ui/Typo";

const getErrorModalProps = ({ t, to, navigate, setAppError }) => ({
	header: <Typo
		component='p'
		variant='title-1'
	>{t('global.errorOccurred')}</Typo>,
	footer: <Button
		onClick={() => {
			navigate(to || '/account');
			setAppError(null);
		}}
		variant='contained'
	>{t('global.continue')}</Button>,
	children: <Typo
		component='p'
		variant='regular'
	>{t('stepper.authentication.saveFailed')}</Typo>,
});

setYupLocale(yup);
addCustomEmailValidator(yup);

const dataSchema = yup.object().shape({
	email: yup.string().customEmail().required(),
	password: yup.string().when('$type', {
		is: (type) => type === 'login' || type === 'register',
		then: (schema) => schema.required(),
	}),
	consent: yup.boolean().when('$type', {
		is: 'register',
		then: (schema) => schema.oneOf([true], 'validation.required').required(),
	}),
	sensitive_data_consent: yup.boolean().when('$type', {
		is: 'register',
		then: (schema) => schema.oneOf([true], 'validation.required').required(),
	}),
});

const defaultData = {
	email: '',
	password: '',
	consent: false,
	sensitive_data_consent: false,
};

export function AuthenticationCard({
	to = '',
	type: defaultType = 'register',
	typesBlacklist = [],
	successCallback = async () => { return true; },
	...props
}) {
	const { t } = useTranslation();

	// Contexts
	const { updateAuthToken } = useContext(AuthTokenContext);
	const { setError: setAppError } = useContext(ErrorContext);

	// Form type
	const [type, setType] = useState(defaultType);

	function displayForgot() {
		return console.error('Forgot is not handled'); // TODO: Handle `forgot` properly in 2 steps (generate token then reset password)
		// setType('forgot');
	}
	function displayLogin() {
		setType('login');
	}
	function toggleRegister() {
		setType(prevState => prevState === 'register' ? 'login' : 'register');
	}

	// Loading
	const [loading, setLoading] = useState(false);

	const {
		data,
		getErrors,
		setTriedSubmit,
		message: formMessage,
		setMessage: setFormMessage,
		getFieldProps,
	} = useForm({
		defaultData,
		dataSchema,
		context: {
			type: type,
		},
	});

	const navigate = useNavigate();

	const loginMutation = useMutation(data => postLogin(data));

	const registerMutation = useMutation(data => postRegister(data));

	const [callbackFailed, setCallbackFailed] = useState(false);

	async function handleSubmit(event) {
		event.preventDefault();

		setTriedSubmit(true);

		const errors = await getErrors(); // Error state not up to date

		if (!!errors) {
			setFormMessage(t('authentication.invalidIds'));

			return;
		}

		if (type === 'forgot') return; // TODO: Handle forgot

		setLoading(true);

		let token = null;
		if (type === 'login') {
			token = await loginMutation.mutateAsync(data);
		} else if (type === 'register') {
			token = await registerMutation.mutateAsync(data);
		}

		if (!token) {
			setFormMessage(t('authentication.errorOccurred'));
			setLoading(false);

			return;
		}

		setFormMessage('');

		// Update token before making requests in `successCallback`
		updateAuthToken(token);

		const callbackSuccess = await successCallback();

		if (!callbackSuccess) {
			setCallbackFailed(true);
			setLoading(false);

			setAppError(getErrorModalProps({ t, to, navigate, setAppError }));

			return;
		}

		setLoading(false);

		navigate(to || '/account');

		return;
	}

	return <>
		<CardModal
			open={callbackFailed}
			header={
				<Typo
					component='h2'
					variant='title-1'
				>{t('global.errorOccurred')}</Typo>
			}
			footer={
				<LinkButton
					to={to || '/account'}
					variant='contained'
				>{t('global.continue')}</LinkButton>
			}
		>
			<Typo
				component='p'
				variant='regular'
			>{t('stepper.authentication.saveFailed')}</Typo>
		</CardModal>
		<CardBox
			component='form'
			onSubmit={handleSubmit}
			{...loading && { disabled: true, }}
			css={{
				...loading && { opacity: 0.5, },
			}}
			{...props}
		>
			<div>
				<Typo
					component='h2'
					variant='title-2'
					css={{
						marginBottom: spacing(4),
						fontWeight: 500,
					}}
				>
					{
						type === 'login' ?
							t('login.form.title')
							: type === 'register' ?
								t('register.form.title')
								: type === 'forgot' ?
									t('forgot.form.title')
									: ''
					}
				</Typo>
				{
					formMessage &&
					<div
						css={{
							marginBottom: spacing(8),
							color: palette.red[500],
						}}
					>{formMessage}</div>
				}
				<div
					css={{
						marginBottom: spacing(8),
					}}
				>
					<Field
						{...getFieldProps('email')}
						label={'form.email'}
						type='email'
						css={{
							marginBottom: spacing(2),
						}}
					/>
					{
						(type === 'forgot' && !typesBlacklist.includes('login')) &&
						<div
							css={{
								textAlign: 'right',
							}}
						>
							<Button
								variant='underlined'
								onClick={displayLogin}
							>{t('authentication.login')}</Button>
						</div>
					}
				</div>
				{
					(type === 'login' || type === 'register') &&
					<div
						css={{
							marginBottom: spacing(8),
						}}
					>
						<Field
							{...getFieldProps('password')}
							label={'form.password'}
							type='password'
							autoComplete='new-password'
							css={{
								marginBottom: spacing(2),
							}}
						/>
						{
							(type === 'login' && !typesBlacklist.includes('forgot')) &&
							<div
								css={{
									textAlign: 'right',
								}}
							>
								<Button
									variant='underlined'
									onClick={displayForgot}
								>{t('authentication.forgotPassword')}</Button>
							</div>
						}
					</div>
				}
				{
					type === 'register' &&
					<div
						css={{
							marginBottom: spacing(8),
						}}
					>
						<Field
							{...getFieldProps('consent')}
							label={t('register.form.consent')}
							labelLinks={['https://www.legacio.be/eula', 'https://www.legacio.be/privacy']}
							type='checkbox'
							css={{
								marginBottom: spacing(2),
							}}
						/>
						<Field
							{...getFieldProps('sensitive_data_consent')}
							label={'register.form.sensitiveDataConsent'}
							labelLinks={['https://www.legacio.be/privacy']}
							type='checkbox'
							css={{
								marginBottom: spacing(2),
							}}
						/>
					</div>
				}
			</div>
			<Button
				type='submit'
			>
				{
					type === 'login' ?
						t('authentication.login')
						: type === 'register' ?
							t('authentication.register')
							: type === 'forgot' ?
								t('authentication.reset')
								: ''
				}
			</Button>
			{
				!(
					(type === 'login' && typesBlacklist.includes('register'))
					|| (type === 'register' && typesBlacklist.includes('login'))
				) &&
				<Button
					variant='underlined'
					onClick={toggleRegister}
					css={{
						marginTop: spacing(4),
					}}
				>
					{
						(type === 'login' || type === 'forgot') ?
							t('authentication.noAccount')
							: type === 'register' ?
								t('authentication.hasAccount')
								: ''
					}
				</Button>
			}
		</CardBox>
	</>
}