import _get from 'lodash.get';
import _set from 'lodash.set';
import { useCallback, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
import { getFormErrors } from '../lib/form';

/**
 * Creates all form's data, utils, and handlers.
 * 
 * @param {object} object Object containing the parameters:
 * - `object.defaultData` The default data.
 * - `object.dataSchema` The data schema used for validation, **\/!\ Should be created with `yup`**.
 * - `object.context` Any context which should be used for validation.
 * @return {object}
 * An object containing everything.
 */
export function useForm({
	defaultData = {},
	dataSchema = yup.object(),
	context: extraContext,
}) {
	const formValues = useFormValues({
		defaultData,
		dataSchema,
		...extraContext && { context: extraContext },
	});

	const formUpdaters = useFormUpdaters({
		setData: formValues.setData,
	});

	const formUtils = useFormUtils();

	const formShorthands = useFormShorthands({
		handleChange: formUpdaters.handleChange,
		updateErrors: formValues.updateErrors,
		triedSubmit: formUtils.triedSubmit,
		getFieldValues: formValues.getFieldValues,
	});

	return {
		...formValues,
		...formUpdaters,
		...formUtils,
		...formShorthands,
	}
}

/**
 * Creates and returns a form's values (data and errors) and ways to access/update it.
 * 
 * @param {object} object Object containing the parameters:
 * - `object.defaultData` The default data.
 * - `object.dataSchema` The data schema used for validation, **\/!\ Should be created with `yup`**.
 * - `object.context` Any context which should be used for validation.
 * @return {object}
 * An object containing the data state, its setter, and its errors:
 * - `object.data` The current `data` state.
 * - `object.setData` The `data` state's setter.
 * - `object.errors` The current `errors` state.
 * - `object.getErrors` The function to get all errors at the given time, without having to wait for the `errors` state to update.
 * - `object.updateErrors` The function to update errors, can ask to do it on next effect cycle by passing `true` as a parameter.
 * - `object.getFieldValues` The function to get a field's values (data and error), returns the given `name`, and the corresponding `value` and `error`.
 */
export function useFormValues({
	defaultData = {},
	dataSchema = yup.object(),
	context: extraContext,
}) {
	const [data, setData] = useImmer(defaultData);
	const [errors, setErrors] = useState(null);

	const [shouldUpdateErrors, setShouldUpdateErrors] = useState(false);
	useEffect(() => {
		if (!shouldUpdateErrors) return;

		updateErrors();

		setShouldUpdateErrors(false);
	}, [shouldUpdateErrors, setShouldUpdateErrors]);

	async function updateErrors(nextEffect = false) {
		if (nextEffect) {
			setShouldUpdateErrors(true);
			return;
		}

		setErrors(
			await getFormErrors({
				data,
				dataSchema,
				...extraContext && { context: extraContext },
			})
		);
	}

	async function getErrors() {
		return await getFormErrors({
			data,
			dataSchema,
			...extraContext && { context: extraContext },
		});
	}

	function getFieldValues(name = '') {
		return {
			name: name,
			value: _get(data, name),
			error: _get(errors, name),
		};
	}

	return {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	}
}

/**
 * Creates a form's methods to update its data.
 * 
 * @param {object} object Object containing the parameters:
 * - `object.setData` The state setter, **\/!\ Should be created with `useImmer`**.
 * @return {object}
 * An object containing the functions:
 * - `handleChange` Function to update the data, accepts a `payload`
 * `payload` can be:
 * 	- An array (`[name, value]`), for programatically updated data
 * 	- An array of arrays (`[[name, value],]`), for batch updates
 * 	- An event (`SynteticEvent`), when used as an eventListener
 * - `handleArrayAdd` Function to add an entry in an array inside the data
 * - `handleArrayRemove` Function to remove an entry in an array inside the data
 */
export function useFormUpdaters({
	setData = () => { }, // State setter should be created with useImmer
}) {
	const handleChange = useCallback((payload) => {
		setData(prevState => {
			// `payload` can be:
			// - An array (`[name, value]`), for programatically updated data
			// - An array of arrays (`[[name, value],]`), for batch updates
			// - An event (`SynteticEvent`), when used as an eventListener
			if (Array.isArray(payload)) {
				if (Array.isArray(payload[0])) {
					payload.forEach(valuePair => {
						const [name, value] = valuePair;

						_set(prevState, name, value);
					})
				} else {
					const [name, value] = payload;

					_set(prevState, name, value);
				}
			} else {
				const target = payload.target;
				let { name, value, type } = target;
				// console.log(name, value);

				target.dataset?.formattingPipes?.split(' ').forEach(pipe => {
					switch (pipe) {
						case 'capitalize':
							value = value.charAt(0).toUpperCase() + value.slice(1);
							// value = value.charAt(0).toLocaleUpperCase(locale) + value.slice(1);
							break;

						default:
							break;
					}
				});

				_set(prevState, name, (type === 'checkbox' && !target.checked) ? '' : value);
			}

			// Using Immer, no return needed
		});
	}, [setData]);

	const handleArrayAdd = useCallback((name, value) => {
		setData(prevState => {
			_get(prevState, name).push(value);

			// Using Immer, no return needed
		});
	}, [setData]);

	const handleArrayRemove = useCallback((name, index) => {
		setData(prevState => {
			_get(prevState, name).splice(index, 1);

			// Using Immer, no return needed
		});
	}, [setData]);

	return {
		handleChange,
		handleArrayAdd,
		handleArrayRemove,
	}
}

/**
 * Creates a form's utils.
 * 
 * @return {object}
 * An object containing the utils.
 */
export function useFormUtils() {
	const [triedSubmit, setTriedSubmit] = useState(false);
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);

	return {
		triedSubmit,
		setTriedSubmit,
		message,
		setMessage,
		success,
		setSuccess,
	}
}

/**
 * Creates a form's shorthand functions, such as `getFieldProps`.
 * 
 * @return {object}
 * An object containing the functions:
 * - `getFieldProps` Function get all props needed for a field by passing it its `name`
 */
export function useFormShorthands({
	handleChange = () => { },
	updateErrors = () => { },
	triedSubmit = false,
	getFieldValues = () => { },
}) {
	function getFieldProps(name = '') {
		return {
			handleChange,
			updateErrors,
			triedSubmit,
			...getFieldValues(name),
		}
	}

	return {
		getFieldProps,
	}
}