import { useState } from "react";

export function BooleanHandler({ children }) {

	const [boolean, setBoolean] = useState(false);
	function setTrue() {
		setBoolean(true);
	}
	function setFalse() {
		setBoolean(false);
	}
	function toggle() {
		setBoolean(prevState => !prevState);
	}

	return children(boolean, setTrue, setFalse, toggle)
}