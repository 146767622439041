import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthTokenContext } from "../contexts/AuthToken";
import { palette } from "../theme";
import { GuestLayout } from "./Guest";
import { LoggedInLayout } from "./LoggedIn";

function findMatch(list = [], pathname = '') {
	// Slice trailing `'/'` (if is not the full url)
	if (pathname.length > 1 && pathname[pathname.length - 1] === '/') pathname = pathname.slice(0, -1);

	const pathnameArray = pathname.split('/');

	return list.find(element => {
		let elPathname = element.pathname;

		// Slice trailing `'/'` (if is not the full url)
		if (elPathname.length > 1 && elPathname[elPathname.length - 1] === '/') elPathname = elPathname.slice(0, -1);

		if (element.strict) {
			return elPathname === pathname;
		}

		const elPathnameArray = elPathname.split('/');

		if (elPathnameArray.length > pathnameArray.length) return false;

		for (
			let i = 1; /* No need to test `'/'` */
			i < elPathnameArray.length;
			i += 2 /* No need to test `'/'` */
		) {
			if (elPathnameArray[i] !== pathnameArray[i]) return false;
		}

		return true;
	});
}

/**
 * The layout handler. Will define which layout to use depending of conditions.
 * Notes about pathnames:
 * - They will be trimmed of any trailing `'/'` for comparison (`'/example' == '/example/'`).
 * - They allow sub-urls by default. (`'/example' == '/example/test'`). Add a `strict` property set to `true` to avoid it
 * 
 * @param {object} props Object containing the props:
 * - `props.pathnamesProps` An array of object with the `pathname` to match and the `props` to pass.
 * Defaults to `[]`.
 * - `props.pathnamesLayouts` An array of object with the `pathname` to match and the `layout` to use.
 * Defaults to `[]`.
 * If the current pathname is not provided, the default layout will be `LoggedIn` or `Guest` depending on the authentication status.
 * - `props.children` The element can contains children.
 * @returns {any} The component
 */
export function DefaultLayout({
	pathnamesProps = [],
	pathnamesLayouts = [],
	children,
}) {
	const { authToken } = useContext(AuthTokenContext);
	const location = useLocation();
	const pathname = location?.pathname || null;

	const matchProps = findMatch(pathnamesProps, pathname)?.props;
	const matchLayout = findMatch(pathnamesLayouts, pathname)?.layout;

	const layoutProps = {
		bgColor: palette.bg.light,
		...matchProps,
	}

	if (matchLayout) {
		return React.createElement(
			matchLayout,
			layoutProps,
			children
		)
	}

	return !!authToken ?
		<LoggedInLayout {...layoutProps}>{children}</LoggedInLayout>
		:
		<GuestLayout {...layoutProps}>{children}</GuestLayout>
}