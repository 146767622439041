import { email } from "./regex";

export function addCustomEmailValidator(yup) {
	yup.addMethod(yup.string, 'customEmail', function (message = 'validation.email') {
		return this.matches(email, {
			// name: 'customEmail',
			message: message,
			excludeEmptyString: true,
		});
	});
}

export function addCustomNationalNumberValidator(yup) {
	yup.addMethod(yup.string, 'customNationalNumber', function (message = 'validation.nationalNumber') {
		return this.matches(/^\d{11}$/, {
			// name: 'customNationalNumber',
			message: message,
			excludeEmptyString: true,
		});
	});
}

export function addCustomDateValidator(yup) {
	yup.addMethod(yup.string, 'customDate', function (givenOptions = {}) {
		const {
			message = 'validation.date',
			min = new Date('1800-01-01'),
			max = new Date('2099-12-31'),
			minMessage = 'validation.dateMin',
			maxMessage = 'validation.dateMax',
		} = givenOptions;

		function getDateFormatValidity(string) {
			const match = string.match(/^(\d{4})-(\d{2})-(\d{2})$/);

			// Quick global format check
			if (!match) return false;

			// Now check each part

			const year = match[1];
			const month = match[2];
			const day = match[3];
			const yearNumber = Number(year);
			const monthNumber = Number(month);
			const dayNumber = Number(day);

			// Check month
			if (monthNumber < 1 || monthNumber > 12) return false;

			// Check day
			const isLeapYear = yearNumber % 400 === 0 || (yearNumber % 4 === 0 && yearNumber % 100 !== 0);
			const maxDay = monthNumber === 2
				? (isLeapYear ? 29 : 28)
				: [4, 6, 9, 11].includes(monthNumber) ? 30
					: 31;
			if (dayNumber < 1 || dayNumber > maxDay) return false;

			return true;
		}

		return this.test('custom-date', message, function (value) {
			const { path, createError } = this;

			if (value === '') return true;

			if (!getDateFormatValidity(value)) return createError({ path, message: message });

			if (new Date(value) < min) return createError({ path, message: minMessage });
			if (new Date(value) > max) return createError({ path, message: maxMessage });

			return true;
		});
	});
}

export function addCustomPotentiallyRequiredValidator(yup) {
	// This validator uses the `requiredPaths` context to check if `.required()` should be added
	yup.addMethod(yup.BaseSchema, 'customPotentiallyRequired', function () {
		return this.test('potentially-required', 'validation.required', async function (value, testContext) {
			const path = testContext.path;
			const requiredPaths = testContext.options?.context?.requiredPaths;

			// Check if `.required()` should be added, otherwise validate itself
			return requiredPaths?.includes(path)
				? await yup[testContext.schema?.type || 'mixed']().required().validate(value).then(() => true).catch(() => false) // `.required()` added through a dummy schema
				: true;
		})
	});
}

export function addCustomRefEntityValidator(yup) {
	yup.addMethod(yup.string, 'customRefEntity', function (givenOptions = {}) {
		const {
			message = 'validation.entity', // Error message to display
			entitySchema = null, // The schema used to validate matching entity
			validations = [], // All the paths we need to check in the schema, and if we should add `required()` to it: { path: 'path', addRequired: boolean = false }
			entitiesPath = '', // The path to the entities value as provided in context
		} = givenOptions;

		return this.test('custom-ref-entity', message, async function (value, testContext) {
			const { path, createError } = this;

			if (value === '') return true;

			// Get the entities list from the specified path in the context and find the correct one
			const validationContext = testContext.options?.context;
			if (!validationContext) return false;
			const entity = validationContext[entitiesPath]?.find(e => e.uuid === value);
			if (!entity?.data) return false;

			const validationPaths = validations.map(i => i.path);
			const requiredPaths = validations.map(i => i.addRequired ? i.path : null).filter(i => i);

			// Validate every path with entity's data, return false if at least one is invalid 
			return await Promise.all(validationPaths.map((path) => entitySchema.validateAt(path, entity.data, {
				context: {
					data: entity.data,
					requiredPaths: requiredPaths, // Add context for `customPotentiallyRequired`
				},
			}))).then(() => true).catch(() => false);
		});
	});
}