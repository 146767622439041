/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { postSuccessionOnboarding } from '../api/successionOnboarding';
import { Tutorial } from "../components/common/Tutorial";
import { ContainerWithSidebar } from "../components/display/ContainerWithSidebar";
import { Stepper } from "../components/stepper/Stepper";
import { StepperMenu } from "../components/stepper/StepperMenu";
import { Button } from '../components/ui/Button';
import { CardBox } from "../components/ui/CardBox";
import { Container } from "../components/ui/Container";
import { ScrollDisplay } from "../components/ui/ScrollDisplay";
import { TransParagraphs } from '../components/ui/Trans';
import { Typo } from "../components/ui/Typo";
import { useFormUpdaters, useFormValues } from "../hooks/form";
import { useMediaQuery } from '../hooks/mediaQuery';
import { setYupLocale } from "../lib/setYupLocale";
import { addCustomDateValidator, addCustomEmailValidator } from '../lib/validators';
import { mq, palette, spacing } from "../theme";

setYupLocale(yup);
addCustomEmailValidator(yup);
addCustomDateValidator(yup);

const dataSchema = yup.object().shape({
	relationship: yup.string(),
	civil_status: yup.string(),
	has_marriage_contract: yup.string(),
	death_date: yup.string().customDate({ max: new Date(), }),
	death_cause: yup.string(),
	address_region: yup.string(),
	street_name: yup.string(),
	street_number: yup.string(),
	street_box: yup.string(),
	zip_code: yup.string(),
	city: yup.string(),
	has_testament: yup.string(),
	children_number: yup.number().min(0),
	has_heir_minor_or_incapable: yup.string(),
	heirs_minor_or_incapable_number: yup.number().min(0),
	has_heir_abroad: yup.string(),
	has_property: yup.string(),
	has_selled_property: yup.string(),
	selled_properties_number: yup.number(),
	has_succession: yup.string(),
	successions_number: yup.number(),
	has_vehicle: yup.string(),
	vehicles_number: yup.number(),
	has_asset_abroad: yup.string(),
	referral: yup.string(),
	undertaker: yup.string(),
	other: yup.string(),
	full_name: yup.string(), // Hidden, fallback if no token
});

const defaultData = {
	relationship: '',
	civil_status: '',
	has_marriage_contract: '',
	death_date: '',
	death_cause: '',
	address_region: '',
	street_name: '',
	street_number: '',
	street_box: '',
	zip_code: '',
	city: '',
	has_testament: '',
	children_number: 0,
	has_heir_minor_or_incapable: '',
	heirs_minor_or_incapable_number: 0,
	has_heir_abroad: '',
	has_property: '',
	has_selled_property: '',
	selled_properties_number: 0,
	has_succession: '',
	successions_number: 0,
	has_vehicle: '',
	vehicles_number: 0,
	has_asset_abroad: '',
	referral: '',
	undertaker: '',
	other: '',
	full_name: '', // Hidden, fallback if no token
};

const steps = [
	{
		route: 'intro',
		label: ({ t }) => t('successionOnboarding.steps.intro.label'),
		title: ({ t }) => t('successionOnboarding.steps.intro.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.intro.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'part_one',
		label: ({ t }) => t('successionOnboarding.steps.partOne.label'),
		title: ({ t }) => t('successionOnboarding.steps.partOne.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partOne.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		depth: 1,
		route: 'relationship',
		label: ({ t }) => t('successionOnboarding.steps.relationship.label'),
		title: ({ t }) => t('successionOnboarding.steps.relationship.title'),
		items: [
			{
				type: 'field',
				name: 'relationship',
				props: {
					type: 'select',
					label: 'successionOnboarding.steps.relationship.form.label',
					options: [
						{ value: 'mom', label: 'successionOnboarding.steps.relationship.form.mom' },
						{ value: 'dad', label: 'successionOnboarding.steps.relationship.form.dad' },
						{ value: 'wife', label: 'successionOnboarding.steps.relationship.form.wife' },
						{ value: 'husband', label: 'successionOnboarding.steps.relationship.form.husband' },
						{ value: 'grandfather', label: 'successionOnboarding.steps.relationship.form.grandfather' },
						{ value: 'grandmother', label: 'successionOnboarding.steps.relationship.form.grandmother' },
						{ value: 'sister', label: 'successionOnboarding.steps.relationship.form.sister' },
						{ value: 'brother', label: 'successionOnboarding.steps.relationship.form.brother' },
						{ value: 'daughter', label: 'successionOnboarding.steps.relationship.form.daughter' },
						{ value: 'son', label: 'successionOnboarding.steps.relationship.form.son' },
						{ value: 'aunt', label: 'successionOnboarding.steps.relationship.form.aunt' },
						{ value: 'uncle', label: 'successionOnboarding.steps.relationship.form.uncle' },
						{ value: 'stepmother', label: 'successionOnboarding.steps.relationship.form.stepmother' },
						{ value: 'stepdad', label: 'successionOnboarding.steps.relationship.form.stepdad' },
						{ value: 'cousin_f', label: 'successionOnboarding.steps.relationship.form.cousinF' },
						{ value: 'cousin_m', label: 'successionOnboarding.steps.relationship.form.cousinM' },
						{ value: 'relative', label: 'successionOnboarding.steps.relationship.form.relative' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.relationship === 'mom' ? t('successionOnboarding.steps.relationship.form.mom')
			: data.relationship === 'dad' ? t('successionOnboarding.steps.relationship.form.dad')
				: data.relationship === 'wife' ? t('successionOnboarding.steps.relationship.form.wife')
					: data.relationship === 'husband' ? t('successionOnboarding.steps.relationship.form.husband')
						: data.relationship === 'grandfather' ? t('successionOnboarding.steps.relationship.form.grandfather')
							: data.relationship === 'grandmother' ? t('successionOnboarding.steps.relationship.form.grandmother')
								: data.relationship === 'sister' ? t('successionOnboarding.steps.relationship.form.sister')
									: data.relationship === 'brother' ? t('successionOnboarding.steps.relationship.form.brother')
										: data.relationship === 'daughter' ? t('successionOnboarding.steps.relationship.form.daughter')
											: data.relationship === 'son' ? t('successionOnboarding.steps.relationship.form.son')
												: data.relationship === 'aunt' ? t('successionOnboarding.steps.relationship.form.aunt')
													: data.relationship === 'uncle' ? t('successionOnboarding.steps.relationship.form.uncle')
														: data.relationship === 'stepmother' ? t('successionOnboarding.steps.relationship.form.stepmother')
															: data.relationship === 'stepdad' ? t('successionOnboarding.steps.relationship.form.stepdad')
																: data.relationship === 'cousin_f' ? t('successionOnboarding.steps.relationship.form.cousinF')
																	: data.relationship === 'cousin_m' ? t('successionOnboarding.steps.relationship.form.cousinM')
																		: data.relationship === 'relative' ? t('successionOnboarding.steps.relationship.form.relative')
																			: '',
	},
	{
		depth: 1,
		route: 'civil_status',
		label: ({ t }) => t('successionOnboarding.steps.civilStatus.label'),
		title: ({ t }) => t('successionOnboarding.steps.civilStatus.title'),
		items: [
			{
				type: 'oneOf',
				name: 'civil_status',
				props: {
					options: [
						{ value: "married", label: "successionOnboarding.steps.civilStatus.form.married" },
						{ value: "widowed", label: "successionOnboarding.steps.civilStatus.form.widowed" },
						{ value: "divorced", label: "successionOnboarding.steps.civilStatus.form.divorced" },
						{ value: "single", label: "successionOnboarding.steps.civilStatus.form.single" },
						{ value: "legal_cohabitant", label: "successionOnboarding.steps.civilStatus.form.legal_cohabitant" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.civil_status === 'married' ? t('successionOnboarding.steps.civilStatus.form.married')
			: data.civil_status === 'widowed' ? t('successionOnboarding.steps.civilStatus.form.widowed')
				: data.civil_status === 'divorced' ? t('successionOnboarding.steps.civilStatus.form.divorced')
					: data.civil_status === 'single' ? t('successionOnboarding.steps.civilStatus.form.single')
						: data.civil_status === 'legal_cohabitant' ? t('successionOnboarding.steps.civilStatus.form.legal_cohabitant')
							: data.civil_status === 'unknown' ? t('form.unknown')
								: '',
		condition: (data) => data.relationship !== 'wife' && data.relationship !== 'husband',
	},
	{
		depth: 2,
		route: 'has_marriage_contract',
		label: ({ t }) => t('successionOnboarding.steps.hasMarriageContract.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasMarriageContract.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_marriage_contract',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_marriage_contract === 'yes' ? t('global.yes')
			: data.has_marriage_contract === 'no' ? t('global.no')
				: data.has_marriage_contract === 'unknown' ? t('form.unknown')
					: '',
		condition: (data) => (data.relationship === 'wife' || data.relationship === 'husband') || data.civil_status === 'married',
	},
	{
		depth: 1,
		route: 'death_date',
		label: ({ t }) => t('successionOnboarding.steps.deathDate.label'),
		title: ({ t }) => t('successionOnboarding.steps.deathDate.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.deathDate.subtitle'),
		items: [
			{
				type: 'field',
				name: 'death_date',
				props: {
					type: 'multipart_date',
					label: 'successionOnboarding.steps.deathDate.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.death_date,
	},
	{
		depth: 1,
		route: 'death_cause',
		label: ({ t }) => t('successionOnboarding.steps.deathCause.label'),
		title: ({ t }) => t('successionOnboarding.steps.deathCause.title'),
		items: [
			{
				type: 'oneOf',
				name: 'death_cause',
				props: {
					style: 'row',
					options: [
						{ value: "natural", label: "successionOnboarding.steps.deathCause.form.natural", description: "successionOnboarding.steps.deathCause.form.naturalDescription" },
						{ value: "accidental", label: "successionOnboarding.steps.deathCause.form.accidental" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.death_cause === 'natural' ? t('successionOnboarding.steps.deathCause.form.natural')
			: data.death_cause === 'accidental' ? t('successionOnboarding.steps.deathCause.form.accidental')
				: data.death_cause === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'address_region',
		label: ({ t }) => t('successionOnboarding.steps.addressRegion.label'),
		title: ({ t }) => t('successionOnboarding.steps.addressRegion.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.addressRegion.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'address_region',
				props: {
					options: [
						{ value: "changed_recently", label: "successionOnboarding.steps.addressRegion.form.changedRecently", description: "successionOnboarding.steps.addressRegion.form.changedRecentlyDescription" },
						{ value: "brussels", label: "successionOnboarding.steps.addressRegion.form.brussels" },
						{ value: "wallonia", label: "successionOnboarding.steps.addressRegion.form.wallonia" },
						{ value: "flanders", label: "successionOnboarding.steps.addressRegion.form.flanders" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.address_region === 'brussels' ? t('successionOnboarding.steps.addressRegion.form.brussels')
			: data.address_region === 'wallonia' ? t('successionOnboarding.steps.addressRegion.form.wallonia')
				: data.address_region === 'flanders' ? t('successionOnboarding.steps.addressRegion.form.flanders')
					: data.address_region === 'changed_recently' ? t('successionOnboarding.steps.addressRegion.form.changedRecently')
						: '',
	},
	{
		depth: 1,
		route: 'address',
		label: ({ t }) => t('successionOnboarding.steps.address.label'),
		title: ({ t }) => t('successionOnboarding.steps.address.title'),
		items: [
			{
				type: 'field',
				name: 'street_name',
				gridSizes: {
					lg: 6,
				},
				props: {
					type: 'text',
					label: 'form.address.street',
				},
			},
			{
				type: 'field',
				name: 'street_number',
				props: {
					type: 'text',
					label: 'form.address.number',
				},
				gridSizes: {
					sm: 6,
					lg: 3,
				},
			},
			{
				type: 'field',
				name: 'street_box',
				props: {
					type: 'text',
					label: 'form.address.box',
				},
				gridSizes: {
					sm: 6,
					lg: 3,
				},
			},
			{
				type: 'field',
				name: 'zip_code',
				props: {
					type: 'text',
					label: 'form.address.postalCode',
				},
				gridSizes: {
					md: 6,
				},
			},
			{
				type: 'field',
				name: 'city',
				props: {
					type: 'text',
					label: 'form.address.town',
				},
				gridSizes: {
					md: 6,
				},
			},
		],
		preview: ({ data, t }) => ((data.street_name || data.street_number || data.street_box) && (data.zip_code || data.city)) ? `${data.street_name} ${data.street_number}${data.street_box ? ` / bte ${data.street_box}` : ''}, ${data.zip_code} ${data.city}` : '',
	},
	{
		depth: 1,
		route: 'has_testament',
		label: ({ t }) => t('successionOnboarding.steps.hasTestament.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasTestament.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_testament',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_testament === 'yes' ? t('global.yes')
			: data.has_testament === 'no' ? t('global.no')
				: data.has_testament === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		route: 'part_two',
		label: ({ t }) => t('successionOnboarding.steps.partTwo.label'),
		title: ({ t }) => t('successionOnboarding.steps.partTwo.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partTwo.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		depth: 1,
		route: 'children_number',
		label: ({ t }) => t('successionOnboarding.steps.childrenNumber.label'),
		title: ({ t }) => t('successionOnboarding.steps.childrenNumber.title'),
		items: [
			{
				type: 'field',
				name: 'children_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => t('successionOnboarding.steps.childrenNumber.preview', { count: data.children_number || 0 }),
	},
	{
		depth: 1,
		route: 'has_heir_minor_or_incapable',
		label: ({ t }) => t('successionOnboarding.steps.hasHeirMinorOrIncapable.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasHeirMinorOrIncapable.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_heir_minor_or_incapable',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_heir_minor_or_incapable === 'yes' ? t('global.yes')
			: data.has_heir_minor_or_incapable === 'no' ? t('global.no')
				: data.has_heir_minor_or_incapable === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'heirs_minor_or_incapable_number',
		label: ({ t }) => t('successionOnboarding.steps.heirsMinorOrIncapableNumber.label'),
		title: ({ t }) => t('successionOnboarding.steps.heirsMinorOrIncapableNumber.title'),
		items: [
			{
				type: 'field',
				name: 'heirs_minor_or_incapable_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.heirs_minor_or_incapable_number,
		condition: (data) => data.has_heir_minor_or_incapable === 'yes',
	},
	{
		depth: 1,
		route: 'has_heir_abroad',
		label: ({ t }) => t('successionOnboarding.steps.hasHeirAbroad.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasHeirAbroad.title'),
		items: [
			{
				type: 'oneOf',
				name: 'has_heir_abroad',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_heir_abroad === 'yes' ? t('global.yes')
			: data.has_heir_abroad === 'no' ? t('global.no')
				: data.has_heir_abroad === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		route: 'part_tree',
		label: ({ t }) => t('successionOnboarding.steps.partTree.label'),
		title: ({ t }) => t('successionOnboarding.steps.partTree.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'successionOnboarding.steps.partTree.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		depth: 1,
		route: 'has_property',
		label: ({ t }) => t('successionOnboarding.steps.hasProperty.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasProperty.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasProperty.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_property',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_property === 'yes' ? t('global.yes')
			: data.has_property === 'no' ? t('global.no')
				: data.has_property === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'has_selled_property',
		label: ({ t }) => t('successionOnboarding.steps.hasSelledProperty.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.hasSelledProperty.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasSelledProperty.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_selled_property',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_selled_property === 'yes' ? t('global.yes')
			: data.has_selled_property === 'no' ? t('global.no')
				: data.has_selled_property === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'selled_properties_number',
		label: ({ t }) => t('successionOnboarding.steps.selledPropertiesNumber.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.selledPropertiesNumber.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		items: [
			{
				type: 'field',
				name: 'selled_properties_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.selled_properties_number,
		condition: (data) => data.has_selled_property === 'yes',
	},
	{
		depth: 1,
		route: 'has_succession',
		label: ({ t }) => t('successionOnboarding.steps.hasSuccession.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.hasSuccession.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		items: [
			{
				type: 'oneOf',
				name: 'has_succession',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_succession === 'yes' ? t('global.yes')
			: data.has_succession === 'no' ? t('global.no')
				: data.has_succession === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'successions_number',
		label: ({ t }) => t('successionOnboarding.steps.successionsNumber.label'),
		title: ({ t, data }) => t('successionOnboarding.steps.successionsNumber.title', { number: (data.address_region === 'wallonia' || data.address_region === 'unknown') ? 5 : 3 }),
		items: [
			{
				type: 'field',
				name: 'successions_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.successions_number,
		condition: (data) => data.has_succession === 'yes',
	},
	{
		depth: 1,
		route: 'has_vehicle',
		label: ({ t }) => t('successionOnboarding.steps.hasVehicle.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasVehicle.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasVehicle.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_vehicle',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_vehicle === 'yes' ? t('global.yes')
			: data.has_vehicle === 'no' ? t('global.no')
				: data.has_vehicle === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		depth: 1,
		route: 'vehicles_number',
		label: ({ t }) => t('successionOnboarding.steps.vehiclesNumber.label'),
		title: ({ t }) => t('successionOnboarding.steps.vehiclesNumber.title'),
		items: [
			{
				type: 'field',
				name: 'vehicles_number',
				props: {
					type: 'number',
					label: 'form.number',
				},
			},
		],
		preview: ({ data, t }) => data.vehicles_number,
		condition: (data) => data.has_vehicle === 'yes',
	},
	{
		depth: 1,
		route: 'has_asset_abroad',
		label: ({ t }) => t('successionOnboarding.steps.hasAssetAbroad.label'),
		title: ({ t }) => t('successionOnboarding.steps.hasAssetAbroad.title'),
		subtitle: ({ t }) => t('successionOnboarding.steps.hasAssetAbroad.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'has_asset_abroad',
				props: {
					options: [
						{ value: "yes", label: "global.yes" },
						{ value: "no", label: "global.no" },
						{ value: "unknown", label: "form.unknown" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_asset_abroad === 'yes' ? t('global.yes')
			: data.has_asset_abroad === 'no' ? t('global.no')
				: data.has_asset_abroad === 'unknown' ? t('form.unknown')
					: '',
	},
	{
		route: 'referral',
		label: ({ t }) => t('successionOnboarding.steps.referral.label'),
		title: ({ t }) => t('successionOnboarding.steps.referral.title'),
		items: [
			{
				type: 'oneOf',
				name: 'referral',
				props: {
					options: [
						{ value: "google", label: "successionOnboarding.steps.referral.form.google" },
						{ value: "facebook", label: "successionOnboarding.steps.referral.form.facebook" },
						{ value: "undertaker", label: "successionOnboarding.steps.referral.form.undertaker" },
						{ value: "press", label: "successionOnboarding.steps.referral.form.press" },
						{ value: "legacio_testament", label: "successionOnboarding.steps.referral.form.legacio_testament" },
						{ value: "relative", label: "successionOnboarding.steps.referral.form.relative" },
						{ value: "undertaker_website", label: "successionOnboarding.steps.referral.form.undertaker_website" },
						{ value: "legacio_succession", label: "successionOnboarding.steps.referral.form.legacio_succession" },
					],
				},
			},
		],
		preview: ({ data, t }) => data.referral === 'google' ? t('successionOnboarding.steps.referral.form.google')
			: data.referral === 'facebook' ? t('successionOnboarding.steps.referral.form.facebook')
				: data.referral === 'undertaker' ? t('successionOnboarding.steps.referral.form.undertaker')
					: data.referral === 'press' ? t('successionOnboarding.steps.referral.form.press')
						: data.referral === 'legacio_testament' ? t('successionOnboarding.steps.referral.form.legacio_testament')
							: data.referral === 'relative' ? t('successionOnboarding.steps.referral.form.relative')
								: data.referral === 'undertaker_website' ? t('successionOnboarding.steps.referral.form.undertaker_website')
									: data.referral === 'legacio_succession' ? t('successionOnboarding.steps.referral.form.legacio_succession')
										: '',
	},
	{
		route: 'undertaker',
		label: ({ t }) => t('successionOnboarding.steps.undertaker.label'),
		title: ({ t }) => t('successionOnboarding.steps.undertaker.title'),
		items: [
			{
				type: 'field',
				name: 'undertaker',
				props: {
					label: 'successionOnboarding.steps.undertaker.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.undertaker,
		condition: (data) => ['undertaker', 'undertaker_website'].includes(data.referral),
	},
	{
		route: 'other',
		label: ({ t }) => t('successionOnboarding.steps.other.label'),
		title: ({ t }) => t('successionOnboarding.steps.other.title'),
		items: [
			{
				type: 'field',
				name: 'other',
				props: {
					type: 'textarea',
					label: 'successionOnboarding.steps.other.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.other ? t('successionOnboarding.steps.other.preview') : '',
	},
];

export function SuccessionOnboarding() {
	const { t } = useTranslation();

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	} = useFormValues({
		defaultData,
		dataSchema,
	});
	const {
		handleChange,
	} = useFormUpdaters({
		setData,
	});

	const postMutation = useMutation((data) => {
		return postSuccessionOnboarding(data);
	});

	async function sendData() {
		const response = await postMutation.mutateAsync(data);

		if (!!response) localStorage.removeItem('succession_onboarding');

		return response;
	}

	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		/* 1. Token */
		let token = '';

		const tokenEncoded = searchParams.get('token') || '';
		if (!!tokenEncoded) {
			try {
				token = window.atob(tokenEncoded.replace(/_/g, '/').replace(/-/g, '+'));
			} catch {
				// In case of an error when copy pasting the link for example, avoid crashing
			}
		}

		if (!token) {
			// No token from url (can happen after a reload or if the user comes back) or incorrectly encoded
			// Look inside the SuccessionOnboarding data in the `localStorage`
			const storedItem = localStorage.getItem('succession_onboarding');
			token = storedItem ? (JSON.parse(storedItem)?.token || '') : '';
		}

		if (!!token) {
			localStorage.setItem('succession_onboarding', JSON.stringify({ token: token, }));
			setSearchParams({});

			return;
		}

		/* 2. Full name (fallback) */
		let fullName = '';

		const fullNameEncoded = searchParams.get('full_name') || '';
		if (!!fullNameEncoded) {
			try {
				fullName = window.atob(fullNameEncoded.replace(/_/g, '/').replace(/-/g, '+'));
			} catch {
				// In case of an error when copy pasting the link for example, avoid crashing
			}
		}

		if (!fullName) {
			// No name from url (can happen after a reload or if the user comes back) or incorrectly encoded
			// Look inside the SuccessionOnboarding data in the `localStorage`
			const storedItem = localStorage.getItem('succession_onboarding');
			fullName = storedItem ? (JSON.parse(storedItem)?.full_name || '') : '';
		}

		if (!!fullName) {
			localStorage.setItem('succession_onboarding', JSON.stringify({ full_name: fullName, }));
			setSearchParams({});
			handleChange(['full_name', fullName]);

			return;
		}

		/* 3. None, should not be there! */
		navigate('/succession-onboarding-invalid-url', { replace: true, });
	}, []);

	const isMedium = useMediaQuery(mq('md'));

	return <>
		<ContainerWithSidebar
			sidebarFitDesktopScreen={true}
			showSidebarOnMobile={true}
			sidebar={
				<>
					<Tutorial
						id='stepper_menu'
						placement={isMedium ? 'right-start' : 'bottom'}
						description={t('tutorials.stepperMenu')}
					>
						<div>
							<Typo
								component='p'
								variant='title-2'
								css={{ marginBottom: spacing(2), }}
							>{t('stepper.menu.title')}</Typo>
						</div>
					</Tutorial>
					<ScrollDisplay
						component={CardBox}
						wrapperCss={{
							padding: 0,
						}}
						css={{
							padding: spacing(4),
						}}
					>
						<StepperMenu
							steps={steps}
							data={data}
							errors={errors}
						/>
					</ScrollDisplay>
					{/* <NeedHelp
						css={{ marginTop: spacing(4), }}
					/> */}
				</>
			}
		>
			{/* <Breadcrumb
						css={{
							marginBottom: spacing(2),
						}}
					/> */}
			{/* <Typo
						component='h1'
						variant='marketing-title'
						css={{
							marginBottom: spacing(7),
							[mq('lg')]: {
								marginBottom: spacing(12),
							},
						}}
					>Ma déclaration de dernières volontés quant aux obsèques</Typo> */}
			<div
				css={{
					paddingBottom: spacing(12),
				}}
			>
				<Stepper
					steps={steps}
					data={data}
					setData={setData}
					dataSchema={dataSchema}
					errors={errors}
					updateFormErrors={updateErrors}
					getFormErrors={getErrors}
					getFieldValues={getFieldValues}
					allowInvalid={false}
					validEndRedirect={'/succession-onboarding-success'}
					endCallback={sendData}
				/>
			</div>
		</ContainerWithSidebar>
		<Container>
			<div
				css={{
					marginBottom: spacing(12),
					borderBottom: '1px solid',
					borderColor: palette.gray[300],
				}}
			/>
			{/* <section
			css={{
				// marginBottom: spacing(12),
			}}
		>
			<Support />
		</section> */}
		</Container>
	</>
}
