import * as yup from 'yup';
import { setYupLocale } from '../../lib/setYupLocale';
import { addCustomEmailValidator, addCustomNationalNumberValidator } from '../../lib/validators';

export const ORGAN_DONATION_DECISION__DATA = {
	version: '1.0',
	organ_transplantation_will: '',
	body_material_transplantation_will: '',
	body_material_drug_manufacturing_will: '',
	body_material_research_will: '',
	email: '',
	first_name: '',
	last_name: '',
	birth_date: '',
};

setYupLocale(yup);
addCustomNationalNumberValidator(yup);
addCustomEmailValidator(yup);

export const ORGAN_DONATION_DECISION__DATA__SCHEMA = yup.object().shape({
	organ_transplantation_will: yup.string().required(),
	body_material_transplantation_will: yup.string().required(),
	body_material_drug_manufacturing_will: yup.string().required(),
	body_material_research_will: yup.string().required(),
	email: yup.string().customEmail().required(),
	first_name: yup.string().required(),
	last_name: yup.string().required(),
	birth_date: yup.string().customDate({ max: new Date() }).required(),
});