/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as OpenInNew } from "@material-design-icons/svg/filled/open_in_new.svg";
import { getLinkComponentProps } from "../../lib/links";
import { getButtonAndLinkStyle, spacing } from "../../theme";
import { Svg } from "./Svg";

export const LinkButton = styled(
	function LinkButton({
		startIcon = null,
		startIconProps = null,
		endIcon = null,
		endIconProps = null,
		to = '',
		avoidBlank = false,
		hideExternalIcon = false,
		children,
		...props
	}) {
		const { component, ...linkComponentProps } = { ...getLinkComponentProps(to) };

		return jsx(
			component,
			{
				...linkComponentProps,
				...avoidBlank && { target: '_self', },
				...props,
			},
			<>
				{
					startIcon &&
					<div
						aria-hidden={true}
						css={{ display: 'flex', marginRight: spacing(2), }}
						{...startIconProps}
					>{startIcon}</div>
				}
				{children}
				{
					endIcon ?
						<div
							aria-hidden={true}
							css={{ display: 'flex', marginLeft: spacing(2), }}
							{...endIconProps}
						>{endIcon}</div>
						:
						(linkComponentProps.target === '_blank' && !avoidBlank && !hideExternalIcon) &&
						<Svg svg={OpenInNew} aria-hidden={true} fontSize='small' css={{ marginLeft: spacing(1), }} />
				}
			</>
		)
	},
	{
		shouldForwardProp: prop => prop !== 'variant' && prop !== 'color' && prop !== 'size',
	}
)(({ variant = 'underlined', color = 'primary', size = 'large', }) => getButtonAndLinkStyle({ variant, color, size }));