/** @jsxImportSource @emotion/react */

import { Trans } from "react-i18next";
import { spacing } from "../../theme";
import { Typo } from "./Typo";

export function TransListItems({ i18nKey = '', t, useMarkdown = false }) {
	return t(i18nKey, { returnObjects: true })?.map((transItem, transIndex) =>
		<Typo
			key={transIndex}
			variant='inherit'
			component='li'
		>
			{
				useMarkdown ?
					<Trans
						components={{ bold: <strong /> }}
					>{transItem}</Trans>
					: transItem
			}
		</Typo>
	)
}

export function TransParagraphs({ i18nKey = '', t, useMarkdown = false }) {
	return t(i18nKey)?.split('\n')?.map((transItem, transIndex) =>
		<Typo
			key={transIndex}
			component='p'
			variant='regular'
			css={{ marginBottom: spacing(4), }}
		>
			{
				useMarkdown ?
					<Trans
						components={{ bold: <strong /> }}
					>{transItem}</Trans>
					: transItem
			}
		</Typo>
	)
}