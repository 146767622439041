import { fetchInsurances, updateInsurance } from "../api/insurance";
import { fetchPeoples, updatePeople } from "../api/people";
import { InsuranceDataPresentation } from "../components/dataPresentation/Insurance";
import { PeopleDataPresentation } from "../components/dataPresentation/People";
import { INSURANCE__DATA as ENTITY__INSURANCE__DATA, INSURANCE__DATA__SCHEMA as ENTITY__INSURANCE__DATA__SCHEMA } from "../data/entities/insurance";
import { PEOPLE__DATA as ENTITY__PEOPLE__DATA, PEOPLE__DATA__SCHEMA as ENTITY__PEOPLE__DATA__SCHEMA } from "../data/entities/people";

const peopleDefaultData = ENTITY__PEOPLE__DATA;
const peopleDataSchema = ENTITY__PEOPLE__DATA__SCHEMA;

const insuranceDefaultData = ENTITY__INSURANCE__DATA;
const insuranceDataSchema = ENTITY__INSURANCE__DATA__SCHEMA;

export function getEntity(entityName) {
	switch (entityName) {
		case 'people':
			return {
				queryKey: 'peoples',
				// fetcher: fetchPeoples, // No need for remote for now
				// updater: updatePeople, // No need for remote for now
				defaultData: peopleDefaultData,
				dataSchema: peopleDataSchema,
				modifyEntity: (data) => ({ // Sometimes we need to use part of the data the modify the entity root
					first_name: data.first_name,
					last_name: data.last_name,
					data: data,
				}),
				selectDataPresentationComponent: PeopleDataPresentation,
				selectDataPresentationPartsToShow: ['firstNameAndLastName',],
			};
		case 'insurance':
			return {
				queryKey: 'insurances',
				// fetcher: fetchInsurances, // No need for remote for now
				// updater: updateInsurance, // No need for remote for now
				defaultData: insuranceDefaultData,
				dataSchema: insuranceDataSchema,
				modifyEntity: ({ _type = '', ...data }) => ({ // Sometimes we need to use part of the data the modify the entity root
					type: _type,
					title: `${data.company?.name || ''} ${data.contract_number || ''}`,
					data: data,
				}),
				selectDataPresentationComponent: InsuranceDataPresentation,
				selectDataPresentationPartsToShow: ['companyName', 'contractNumber',],
			};
		default:
			return null;
	}
}