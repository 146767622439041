/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button } from "../components/ui/Button";
import { palette, spacing } from "../theme";

export function LandingLayout({
	bgColor = palette.bg.light,
	children,
}) {
	const { t, i18n } = useTranslation();

	const location = useLocation();

	const lng = i18n.language;

	function changeLocale() {
		const newLng = lng.startsWith('fr') ? 'nl' : 'fr';

		i18n.changeLanguage(newLng, (err) => {
			if (err) return console.log('Loading locale failed', err);
		});
	}

	return <div
		css={{
			backgroundColor: bgColor,
		}}
	>
		{/* <header
			css={{
				paddingTop: spacing(2),
			}}
		>
			<Button
				onClick={changeLocale}
				variant='underlinedHover'
				css={{
					marginLeft: 'auto',
					marginRight: spacing(4),
				}}
			>
				{
					lng.startsWith('fr') ? 'NL' : 'FR'
				}
			</Button>
		</header> */}
		<main
			css={{
				minHeight: '100vh',
				boxSizing: 'border-box',
				paddingTop: spacing(8),
				paddingBottom: spacing(12),
			}}
		>
			<div>{children}</div>
		</main>
	</div>
}