// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abort-controller/polyfill.js';
import 'element-scroll-polyfill';
import 'core-js/modules/esnext.string.replace-all.js';

// i18n polyfills
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/nl';

// CSS polyfill
import 'focus-visible';

// Import Immer ES5 enabler
import { enableES5 as immerEnableES5 } from 'immer';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import TagManager from 'react-gtm-module'
import { AppWrapper } from './AppWrapper';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Use Immer ES5 enabler
immerEnableES5();

// Other 'polyfills'
if (!window.crypto) window.crypto = window.msCrypto; // 'Polyfill' by accessing experimental feature in IE11



// Initialize GTM
TagManager.initialize({
  gtmId: 'GTM-WRH55VT',
});



// Start of app
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppWrapper />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
