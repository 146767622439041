import * as yup from 'yup';
import { setYupLocale } from '../../lib/setYupLocale';
import { addCustomDateValidator, addCustomEmailValidator, addCustomNationalNumberValidator, addCustomRefEntityValidator } from '../../lib/validators';
import { PEOPLE__DATA__SCHEMA as ENTITY__PEOPLE__DATA__SCHEMA } from '../entities/people';

const peopleDataSchema = ENTITY__PEOPLE__DATA__SCHEMA;

export const NEGATIVE_DECISION__DATA = {
	version: '1.0',
	context: '',
	context_specific: '',
	does_refuse_treatment: '',
	refused_treatments: [], // strings
	refused_treatments_other: '',
	preserve_organ_donation: '',
	has_representative: '',
	representatives: [], // { people_uuid : uuid }
	email: '',
	first_name: '',
	last_name: '',
	birth_date: '',
};

setYupLocale(yup);
addCustomNationalNumberValidator(yup);
addCustomDateValidator(yup);
addCustomEmailValidator(yup);
addCustomRefEntityValidator(yup);

export const NEGATIVE_DECISION__DATA__SCHEMA = yup.object().shape({
	context: yup.string().required(),
	context_specific: yup.string().when('context', {
		is: 'specific',
		then: (schema) => schema.required(),
	}),
	does_refuse_treatment: yup.string().required(),
	refused_treatments: yup.array().of(yup.string()).when('does_refuse_treatment', {
		is: 'yes',
		then: (schema) => schema.min(1),
	}),
	refused_treatments_other: yup.string().when(['does_refuse_treatment', 'refused_treatments'], {
		is: (does_refuse_treatment, refused_treatments) => does_refuse_treatment === 'yes' && refused_treatments.includes('other'),
		then: (schema) => schema.required(),
	}),
	preserve_organ_donation: yup.string().required(),
	has_representative: yup.string().required(),
	representatives: yup.array().when('has_representative', {
		is: 'yes',
		then: (schema) => schema.of(
			yup.object().shape({
				people_uuid: yup.string().uuid().customRefEntity({
					message: 'validation.negativeDecision.representative',
					entitySchema: peopleDataSchema,
					entitiesPath: 'peoples',
					validations: [
						{ path: 'first_name', addRequired: true, },
						{ path: 'last_name', addRequired: true, },
						{ path: 'national_number', addRequired: true, },
						{ path: 'phone', addRequired: true, },
						{ path: 'address.street', addRequired: true, },
						{ path: 'address.number', addRequired: true, },
						{ path: 'address.box', },
						{ path: 'address.postal_code', addRequired: true, },
						{ path: 'address.town', addRequired: true, },
						{ path: 'birth.date', addRequired: true, },
						{ path: 'birth.address.town', addRequired: true, },
						{ path: 'relationship', },
					],
				}),
			}),
		).min(1, ({ min }) => ({ key: 'validation.negativeDecision.representativesMin', values: { count: min }, })),
	}),
	email: yup.string().customEmail().required(),
	first_name: yup.string().required(),
	last_name: yup.string().required(),
	birth_date: yup.string().customDate({ max: new Date() }).required(),
});
