import { getFormattedDate } from "../lib/dates";
import { getFlattenedObject } from "../lib/objects";
import { request } from "../lib/requests";
import { getFormattedNationalNumber } from "../lib/strings";
import { getFormattedAddress, insertEntities } from "./_advancedDecisionsHelpers";

export async function postNegativeDecision(data, steps) {
	const newData = await insertEntities(data, steps);

	if (!newData) return false;

	const flattenedData = getFlattenedObject(newData);

	alterData(flattenedData);

	try {
		const response = await request({
			method: 'POST',
			url: 'https://hooks.zapier.com/hooks/catch/8288466/3hnuk83/',
			data: flattenedData,
		});

		return true;
	} catch (error) {
		console.error(error);

		return false;
	}
}

function alterData(data) {
	// Add missing keys
	[
		'data.first_name',
		'data.last_name',
		'data.national_number',
		'data.address.street',
		'data.address.number',
		'data.address.box',
		'data.address.postal_code',
		'data.address.town',
		'data.phone',
		'data.birth.date',
		'data.birth.address.town',
		'data.relationship',
	].forEach(key => {
		for (let i = 0; i < 3; i++) {
			data[`representatives[${i}].${key}`] ??= '';
		}
	});

	// Add age
	[
		{
			birth_date: 'birth_date',
			age: 'age',
		},
	].forEach(keys => {
		const ageDifMs = Date.now() - new Date(data[keys.birth_date]).getTime();
		const ageDate = new Date(ageDifMs); // miliseconds from epoch
		data[keys.age] = `${Math.abs(ageDate.getUTCFullYear() - 1970)}`;
	});

	// Update dates
	[
		'representatives[0].data.birth.date',
		'representatives[1].data.birth.date',
		'representatives[2].data.birth.date',
		'birth_date',
	].forEach(key => {
		if (!data[key]) return;

		data[key] = getFormattedDate(data[key]);
	});

	// Update national numbers
	[
		'representatives[0].data.national_number',
		'representatives[1].data.national_number',
		'representatives[2].data.national_number',
	].forEach(key => {
		if (!data[key]) return;

		data[key] = getFormattedNationalNumber(data[key]);
	});

	// Add full names
	[
		{
			first_name: 'representatives[0].data.first_name',
			last_name: 'representatives[0].data.last_name',
			full_name: 'representatives[0].data.full_name',
		},
		{
			first_name: 'representatives[1].data.first_name',
			last_name: 'representatives[1].data.last_name',
			full_name: 'representatives[1].data.full_name',
		},
		{
			first_name: 'representatives[2].data.first_name',
			last_name: 'representatives[2].data.last_name',
			full_name: 'representatives[2].data.full_name',
		},
	].forEach(keys => {
		data[keys.full_name] = `${data[keys.last_name]} ${data[keys.first_name]}`;
	});

	// Add full address
	[
		{
			street: 'representatives[0].data.address.street',
			number: 'representatives[0].data.address.number',
			box: 'representatives[0].data.address.box',
			postal_code: 'representatives[0].data.address.postal_code',
			town: 'representatives[0].data.address.town',
			full_address: 'representatives[0].data.full_address',
		},
		{
			street: 'representatives[1].data.address.street',
			number: 'representatives[1].data.address.number',
			box: 'representatives[1].data.address.box',
			postal_code: 'representatives[1].data.address.postal_code',
			town: 'representatives[1].data.address.town',
			full_address: 'representatives[1].data.full_address',
		},
		{
			street: 'representatives[2].data.address.street',
			number: 'representatives[2].data.address.number',
			box: 'representatives[2].data.address.box',
			postal_code: 'representatives[2].data.address.postal_code',
			town: 'representatives[2].data.address.town',
			full_address: 'representatives[2].data.full_address',
		},
	].forEach(keys => {
		data[keys.full_address] = getFormattedAddress({
			street: data[keys.street],
			number: data[keys.number],
			box: data[keys.box],
			postal_code: data[keys.postal_code],
			town: data[keys.town],
		});
	});

	// Rewrite context
	data.full_context = data.context === 'any' ? 'Peu importe la raison de mon incapacité' : data.context_specific;

	// Split refused treatments array into separate values
	[
		'antibiotic',
		'artificial_hydration_nutrition',
		'chemotherapy',
		'radiation',
		'surgery',
		'artificial_respiration',
		'renal_dialysis_hemofiltration',
		'intensive_care',
		'hospitalization',
		'blood_product_transfusion',
		'other',
	].forEach(value => {
		data[`refused_treatments__${value}`] = data.refused_treatments.includes(value);
	});

	// Empty refused treatments fields if they're not supposed to exist
	if (data.does_refuse_treatment !== 'yes') {
		Object.keys(data).forEach(key => {
			if (key.startsWith('refused_treatments__')) data[key] = false;
		});
	}
	if (!data.refused_treatments__other) {
		data.refused_treatments_other = '';
	}

	// Empty representatives fields if they're not supposed to exist
	if (data.has_representative !== 'yes') {
		Object.keys(data).forEach(key => {
			if (key.startsWith('representatives[')) data[key] = false;
		});
	}

	// Separate full context in multiple lines
	data.full_context__0 = data.full_context.replaceAll('\n', ' ');
	data.full_context__1 = '';
	if (data.full_context__0.length >= 120) {
		const index = data.full_context__0.lastIndexOf(' ', 120);
		if (index !== -1) {
			data.full_context__1 = data.full_context__0.substring(index + 1);
			data.full_context__0 = data.full_context__0.substring(0, index + 1);

			data.full_context__1 = data.full_context__1.substring(0, 120);
		}
	}
	// Separate treaatments other in multiple lines
	data.refused_treatments_other__0 = data.refused_treatments_other.replaceAll('\n', ' ');
	data.refused_treatments_other__1 = '';
	if (data.refused_treatments_other__0.length >= 100) {
		const index = data.refused_treatments_other__0.lastIndexOf(' ', 100);
		if (index !== -1) {
			data.refused_treatments_other__1 = data.refused_treatments_other__0.substring(index + 1);
			data.refused_treatments_other__0 = data.refused_treatments_other__0.substring(0, index + 1);

			data.refused_treatments_other__1 = data.refused_treatments_other__1.substring(0, 100);
		}
	}

	// Replace yes/no with true/false
	[
		'preserve_organ_donation',
		'has_representative',
	].forEach(key => {
		data[key] = data[key] === 'yes';
	});
}
