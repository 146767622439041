import { request } from "../lib/requests";
import { BEARER, ENDPOINTS, URL } from "../variables/api";

// Login
export async function postLogin(credentials) {
	try {
		if (!credentials.email || !credentials.password) throw new Error('Missing credentials');

		const response = await request({
			method: 'POST',
			url: `${URL}${ENDPOINTS.login}`,
			data: {
				email: credentials.email,
				password: credentials.password,
			},
			extraHeaders: [
				['Authorization', `Bearer ${BEARER}`]
			]
		});

		if (!response.token) throw new Error('Token is missing from server response');

		return response.token;
	} catch (error) {
		console.error(error);

		return false;
	}
}

// Generate reset token
export async function postGenerateResetToken(credentials) {
	try {
		if (!credentials.email) throw new Error('Missing credentials');

		const response = await request({
			method: 'POST',
			url: `${URL}${ENDPOINTS.generateResetToken}`,
			data: {
				email: credentials.email,
			},
			extraHeaders: [
				['Authorization', `Bearer ${BEARER}`]
			]
		});

		if (!response.token) throw new Error('Token is missing from server response');

		return response.token;
	} catch (error) {
		console.error(error);

		return false;
	}
}

// Reset password
export async function postResetPassword(credentials) {
	try {
		if (!credentials.email || !credentials.token || !credentials.new_password) throw new Error('Missing credentials');

		const response = await request({
			method: 'POST',
			url: `${URL}${ENDPOINTS.resetPassword}`,
			data: {
				email: credentials.email,
				token: credentials.token,
				new_password: credentials.new_password,
			},
			extraHeaders: [
				['Authorization', `Bearer ${BEARER}`]
			]
		});

		if (response.status !== 'ok') throw new Error('Status ok is missing from server response');

		return true;
	} catch (error) {
		console.error(error);

		return false;
	}
}

// Register
export async function postRegister(credentials) {
	try {
		if (!credentials.email || !credentials.password || !credentials.consent || !credentials.sensitive_data_consent) throw new Error('Missing credentials');

		const response = await request({
			method: 'POST',
			url: `${URL}${ENDPOINTS.register}`,
			data: {
				email: credentials.email,
				user_name: credentials.email,
				password: credentials.password,
				consent: credentials.consent,
				sensitive_data_consent: credentials.sensitive_data_consent,
				role: 'user',
			},
			extraHeaders: [
				['Authorization', `Bearer ${BEARER}`]
			]
		});

		// Now log in
		return await postLogin({
			email: credentials.email,
			password: credentials.password,
		});
	} catch (error) {
		console.error(error);

		return false;
	}
}