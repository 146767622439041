/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import yellowBg from '../assets/backgrounds/yellow.png';
import { Header } from "../components/base/Header";
import { LinkButton } from "../components/ui/LinkButton";
import { Logo } from "../components/Logo";
import { Container } from "../components/ui/Container";
import { mq, palette, spacing, typography } from "../theme";

const footerLinks = [
	{
		href: 'https://www.legacio.be/eula',
		display: ({ t }) => t('menu.terms'),
	},
	{
		href: 'https://www.legacio.be/privacy',
		display: ({ t }) => t('menu.privacy'),
	},
	{
		href: 'https://www.legacio.be/cookies',
		display: ({ t }) => t('menu.cookies'),
	},
];

export function LoggedInLayout({
	bgColor = palette.bg.light,
	children,
}) {
	const { t } = useTranslation();

	return <div
		css={{
			backgroundColor: bgColor,
		}}
	>
		{/* <global-notifications /> */}
		<Header />
		<main
			css={{
				minHeight: '100vh',
				paddingTop: '68px',
			}}
		>
			<div
				css={{
					paddingBottom: spacing(12),
					paddingTop: spacing(4),
					[mq('md')]: {
						paddingTop: spacing(12),
					},
				}}
			>
				{children}
			</div>
		</main>
		<footer
			css={{
				background: `url(${yellowBg})`,
				paddingTop: spacing(6),
				paddingBottom: spacing(6),
			}}
		>
			<Container>
				<div
					css={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Logo
						color='white'
					/>
					<ul
						css={{
							display: 'block',
							marginLeft: spacing(10),
							[mq('md')]: {
								display: 'flex',
								marginLeft: 0,
							},
						}}
					>
						{
							footerLinks.map((link, linkIndex) =>
								<li
									key={linkIndex}
								>
									<LinkButton
										to={link.href}
										hideExternalIcon={true}
										variant='underlinedHover'
										css={{
											...typography['small'],
											color: 'white',
											marginLeft: 0,
											marginRight: 0,
											[mq('md')]: {
												marginLeft: spacing(2),
												marginRight: spacing(2),
											},
										}}
									>{typeof link.display === 'function' ? link.display({ t }) : link.display}</LinkButton>
								</li>
							)
						}
					</ul>
				</div>
			</Container>
		</footer>
	</div>
}