/** @jsxImportSource @emotion/react */

import { ReactComponent as Check } from "@material-design-icons/svg/filled/check.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/filled/close.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { palette, spacing } from "../../theme";
import { IconCircleWrapper } from "../ui/IconCircleWrapper";
import { LinkButton } from "../ui/LinkButton";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";
import { useCurrentStep, useFilteredSteps, useNestedSteps } from "./hooks";
import { getStepValidity } from "./utils";

export function StepperMenu({
	steps = [],
	data = {},
	errors = null,
}) {
	const { currentStepIndex, currentStepRoute } = useCurrentStep(steps);
	const [biggestStepIndex, setBiggestStepIndex] = useState(0);
	useEffect(() => {
		if (currentStepIndex > biggestStepIndex) setBiggestStepIndex(currentStepIndex);
	}, [currentStepIndex, setBiggestStepIndex]);
	const filteredSteps = useFilteredSteps(steps, data, Math.max(data?.stepper_data?.biggest_step_index || 0, biggestStepIndex)); // Up to the biggest 'biggest step index' between the saved one (if existing) and the local one
	const nestedSteps = useNestedSteps(filteredSteps);

	return <div
		css={{
			marginBottom: `-${spacing(4)}`,
		}}
	>
		<StepperMenuList
			nestedSteps={nestedSteps}
			data={data}
			errors={errors}
			currentStepRoute={currentStepRoute}
		/>
	</div>
}

function StepperMenuList({
	nestedSteps = [],
	data,
	errors = null,
	currentStepRoute = '',
	currentPath = '',
}) {
	const { t } = useTranslation();

	return <nav
		aria-label={t('stepper.menu.ariaLabel')}
	>
		<Typo
			component='ol'
			variant='regular'
			css={{
				paddingLeft: 0,
				listStyle: 'none',
				'& li': {
					position: 'relative',
					// pl: '1em',
					'&:after': {
						content: '""',
						width: '1px',
						height: 'calc(100% - 14px)',
						position: 'absolute',
						left: 12,
						top: 28,
						borderLeft: '1px dashed',
						borderColor: palette.gray[400],
					},
					'& li:first-of-type:before': {
						content: '""',
						width: 8,
						height: '1px',
						position: 'absolute',
						top: 12,
						right: 'calc(100% + 4px)',
						borderTop: '1px dashed',
						borderColor: palette.gray[400],
					},
					'&:last-child': {
						'&:after': {
							height: 'calc(100% - 28px)',
						},
					},
					'& [aria-current="step"]': {
						fontWeight: 600,
					},
				},
				'& ol': {
					paddingLeft: spacing(6),
				},
			}}
		>
			{
				nestedSteps.map(({ object: stepObject, substeps: stepSubsteps }, stepIndex) =>
					<Typo
						key={stepIndex}
						component='li'
						variant='inherit'
					>
						<div
							css={{
								marginBottom: spacing(4),
								display: 'flex',
							}}
						>
							{
								stepObject.route === currentStepRoute ?
									<span
										css={{
											width: 24,
											height: 24,
											backgroundColor: palette.gray[500],
											borderRadius: '100px',
											flexShrink: 0,
											display: 'inline-flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginRight: spacing(2),
										}}
									>
										<Typo
											component='span'
											variant='extra-small'
											css={{ color: 'white', }}
										>{getNewPath(currentPath, stepIndex)}</Typo>
									</span>
									:
									getStepValidity(stepObject, errors) ?
										<IconCircleWrapper
											ariaHidden={false}
											css={{
												marginRight: spacing(2),
												display: 'inline-flex',
												flexShrink: 0,
											}}
										>
											<Svg svg={Check} aria-label='Complété' />
										</IconCircleWrapper>
										:
										<IconCircleWrapper
											backgroundColor={palette.red[500]}
											ariaHidden={false}
											css={{
												marginRight: spacing(2),
												display: 'inline-flex',
												flexShrink: 0,
											}}
										>
											<Svg svg={Close} aria-label='Incomplet' />
										</IconCircleWrapper>
							}
							<LinkButton
								to={stepObject.route}
								state={{ from: currentStepRoute, }}
								variant='underlinedHover'
								{...(stepObject.route === currentStepRoute) && {
									'aria-current': 'step',
								}}
								css={{
									display: 'block',
								}}
							>
								{typeof stepObject.label === 'function' ? stepObject.label({ t }) : stepObject.label}
								<Typo
									component='span'
									variant='extra-small'
									css={{ display: 'block', }}
								>{stepObject.preview ? stepObject.preview({ data, t }) || '' : ''}</Typo>
							</LinkButton>
						</div>
						{
							stepSubsteps?.length > 0 &&
							<StepperMenuList
								nestedSteps={stepSubsteps}
								data={data}
								errors={errors}
								currentStepRoute={currentStepRoute}
								currentPath={getNewPath(currentPath, stepIndex)}
							/>
						}
					</Typo>
				)
			}
			{
				!currentPath && // Only if main list
				'authentication' === currentStepRoute && // Only if at this step
				<Typo
					component='li'
					variant='inherit'
				>
					<div
						css={{
							marginBottom: spacing(4),
							display: 'flex',
						}}
					>
						<span
							css={{
								width: 24,
								height: 24,
								backgroundColor: palette.gray[500],
								borderRadius: '100px',
								flexShrink: 0,
								display: 'inline-flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: spacing(2),
							}}
						>
							<Typo
								component='span'
								variant='extra-small'
								css={{ color: 'white', }}
							>{nestedSteps.length + 1}</Typo>
						</span>
						<LinkButton
							to='#'
							variant='underlinedHover'
							aria-current='step'
							css={{
								display: 'block',
							}}
						>
							{t('stepper.authentication.label')}
							<Typo
								component='span'
								variant='extra-small'
								css={{ display: 'block', }}
							>{t('stepper.authentication.sublabel')}</Typo>
						</LinkButton>
					</div>
				</Typo>
			}
		</Typo>
	</nav>
}

function getNewPath(currentPath, stepIndex) {
	return currentPath ? `${currentPath}.${stepIndex + 1}` : `${stepIndex + 1}`;
}