import * as yup from 'yup';
import { setYupLocale } from '../../lib/setYupLocale';
import { addCustomDateValidator, addCustomEmailValidator, addCustomNationalNumberValidator, addCustomRefEntityValidator } from '../../lib/validators';
import { PEOPLE__DATA__SCHEMA as ENTITY__PEOPLE__DATA__SCHEMA } from '../entities/people';

const peopleDataSchema = ENTITY__PEOPLE__DATA__SCHEMA;

export const EUTHANASIA_DECISION__DATA = {
	version: '1.0',
	decision_type: '',
	previous_date: '',
	witnesses: [], // { people_uuid : uuid }
	has_trusted_person: '',
	trusted_persons: [], // { people_uuid : uuid }
	email: '',
	first_name: '',
	last_name: '',
	birth_date: '',
};

setYupLocale(yup);
addCustomNationalNumberValidator(yup);
addCustomDateValidator(yup);
addCustomEmailValidator(yup);
addCustomRefEntityValidator(yup);

export const EUTHANASIA_DECISION__DATA__SCHEMA = yup.object().shape({
	decision_type: yup.string().required(),
	previous_date: yup.string().customDate({ max: new Date() }).when('decision_type', {
		is: (decision_type) => decision_type === 'change' || decision_type === 'renew' || decision_type === 'withdrawal',
		then: (schema) => schema.required(),
	}),
	witnesses: yup.array().of(
		yup.object().shape({
			people_uuid: yup.string().uuid().customRefEntity({
				message: 'validation.euthanasiaDecision.witness',
				entitySchema: peopleDataSchema,
				entitiesPath: 'peoples',
				validations: [
					{ path: 'first_name', addRequired: true, },
					{ path: 'last_name', addRequired: true, },
					{ path: 'national_number', addRequired: true, },
					{ path: 'phone', addRequired: true, },
					{ path: 'address.street', addRequired: true, },
					{ path: 'address.number', addRequired: true, },
					{ path: 'address.box', },
					{ path: 'address.postal_code', addRequired: true, },
					{ path: 'address.town', addRequired: true, },
					{ path: 'birth.date', addRequired: true, },
					{ path: 'birth.address.town', addRequired: true, },
					{ path: 'relationship', },
				],
			}),
			// relationship: yup.string().when('$data.witnesses', {
			// 	is: (witnesses) => !witnesses.some(i => i.relationship?.trim() === ''),
			// 	then: (schema) => schema.trim().length(0, 'validation.euthanasiaDecision.witnessesNoRelationship'),
			// }),
		}),
	).length(2, ({ length }) => ({ key: 'validation.euthanasiaDecision.witnessesLength', values: { count: length }, })).test('witnesses-no-relationship', 'validation.euthanasiaDecision.witnessesNoRelationship', function (value, testContext) {
		const { path, createError } = this;

		if (!(value && Array.isArray(value) && value.length)) return true; // If not array or if empty, bypass

		// Get the peoples list from the specified path in the context and find the matching peoples
		const validationContext = testContext.options?.context;
		if (!validationContext) return false;

		const witnesses = value
			.map(item => {
				if (!item?.people_uuid) return null;
				return validationContext['peoples']?.find(i => i.uuid === item.people_uuid);
			})
			.filter(i => !!i);

		if (witnesses.filter(item => !!item.data?.relationship?.trim()).length > 1) return false;

		return true;
	}),
	has_trusted_person: yup.string().required(),
	trusted_persons: yup.array().when('has_trusted_person', {
		is: 'yes',
		then: (schema) => schema.of(
			yup.object().shape({
				people_uuid: yup.string().uuid().customRefEntity({
					message: 'validation.negativeDecision.representative',
					entitySchema: peopleDataSchema,
					entitiesPath: 'peoples',
					validations: [
						{ path: 'first_name', addRequired: true, },
						{ path: 'last_name', addRequired: true, },
						{ path: 'national_number', addRequired: true, },
						{ path: 'phone', addRequired: true, },
						{ path: 'address.street', addRequired: true, },
						{ path: 'address.number', addRequired: true, },
						{ path: 'address.box', },
						{ path: 'address.postal_code', addRequired: true, },
						{ path: 'address.town', addRequired: true, },
						{ path: 'birth.date', addRequired: true, },
						{ path: 'birth.address.town', addRequired: true, },
						{ path: 'relationship', },
					],
				}),
			}),
		).min(1, ({ min }) => ({ key: 'validation.euthanasiaDecision.trustedPersonsMin', values: { count: min }, })),
	}),
	email: yup.string().customEmail().required(),
	first_name: yup.string().required(),
	last_name: yup.string().required(),
	birth_date: yup.string().customDate({ max: new Date() }).required(),
});