/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LinkButton } from "../components/ui/LinkButton";
import { Logo } from "../components/Logo";
import { Container } from "../components/ui/Container";
import { palette, spacing, typography } from "../theme";

export function GuestLayout({
	bgColor = palette.bg.light,
	children,
}) {
	const { t } = useTranslation();

	const location = useLocation();

	return <div
		css={{
			backgroundColor: bgColor,
		}}
	>
		{/* <global-notifications /> */}
		<Container
			component='header'
			css={{
				display: 'flex',
				justifyContent: 'flex-start',
				marginLeft: 'auto',
				marginRight: 'auto',
				padding: spacing(6),
				height: 88,
			}}
		>
			<Logo />
			<LinkButton
				to={location.pathname === '/register' ? '/login' : '/register'}
				variant='underlinedHover'
				css={{
					marginLeft: 'auto',
					...typography['regular'],
					color: palette.blue[300],
				}}
			>{location.pathname === '/register' ? t('menu.login') : t('menu.register')}</LinkButton>
		</Container>
		<main
			css={{
				minHeight: 'calc(100vh - 88px)',
				boxSizing: 'border-box',
				paddingBottom: spacing(12),
			}}
		>
			<div>{children}</div>
		</main>
	</div>
}