/** @jsxImportSource @emotion/react */

import { ReactComponent as Check } from "@material-design-icons/svg/filled/check.svg";
import { ReactComponent as CheckBox } from "@material-design-icons/svg/filled/check_box.svg";
import { ReactComponent as CheckBoxOutlineBlank } from "@material-design-icons/svg/filled/check_box_outline_blank.svg";
import { ReactComponent as RadioButtonChecked } from "@material-design-icons/svg/filled/radio_button_checked.svg";
import { ReactComponent as RadioButtonUnchecked } from "@material-design-icons/svg/filled/radio_button_unchecked.svg";
import { palette, spacing } from "../../theme";
import { CardBox } from "../ui/CardBox";
import { IconCircleWrapper } from "../ui/IconCircleWrapper";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";

/**
 * A component to use for card-like form components or other interactive/selectable card-like component.
 * Has a selected state and checkbox/radio icon variants.
 * 
 * @param {object} props Object containing the props:
 * - `props.selected` Defines if the component is selected.
 * Defaults to `false`.
 * - `props.label` The label.
 * - `props.description` The description.
 * - `props.size` The size, used for styling.
 * Defaults to `'large'`.
 * - `props.textAlign` Defines the `textAlign`.
 * Accepts any `textAlign` as a string.
 * Defaults to `'center'`.
 * - `props.selectionIconType` Defines the icon to show. Will interact with `selected` if possible.
 * Accepts `'checkbox'`, `'radio'`, or an empty value.
 * - `...props` Any other props will be passed.
 * @returns {any} The component
 */
export function InteractiveCard({
	selected = false,
	label = '',
	description = '',
	size = 'large',
	textAlign = 'center',
	selectionIconType = '',
	...props
}) {
	return <CardBox
		backgroundColor='white'
		css={{
			width: '100%',
			textAlign: textAlign,
			boxShadow: (size === 'large') ? '0 6px 8px -2px rgb(0 0 0 / 10%)' : '0 4px 8px -2px rgb(0 0 0 / 10%)',
			border: '2px solid',
			borderColor: selected ? palette.yellow[400] : palette.gray[200],
			'&:hover, &:focus': {
				// backgroundColor: 'white',
				borderColor: palette.blue[400],
			},
			paddingLeft: spacing(4),
			paddingRight: spacing(4),
			...(selected && !selectionIconType) && { paddingLeft: (size === 'large') ? spacing(8) : spacing(6), },
			paddingTop: (size === 'large') ? spacing(6) : spacing(2),
			paddingBottom: (size === 'large') ? spacing(6) : spacing(2),
			'&:not([disabled])': {
				cursor: 'pointer',
			},
		}}
		{...props}
	>
		<div
			css={{
				display: 'flex',
				alignItems: 'center',
				width: '100%',
			}}
		>
			{
				selectionIconType ?
					<div
						css={{
							display: 'inline-block',
							marginRight: spacing(2),
							color: selected ? palette.yellow[400] : palette.gray[300],
							transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter', // transition
							transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', // transition
							transitionDuration: '100ms',
						}}
					>
						{(selectionIconType === 'checkbox'
							?
							selected
								? <Svg svg={CheckBox} />
								: <Svg svg={CheckBoxOutlineBlank} />
							:
							selected
								? <Svg svg={RadioButtonChecked} />
								: <Svg svg={RadioButtonUnchecked} />
						)}
					</div>
					:
					selected &&
					<IconCircleWrapper
						wrapperSize={(size === 'large') ? 24 : 16}
						iconSize={(size === 'large') ? 18 : 12}
						backgroundColor={palette.yellow[400]}
						ariaHidden={false}
						css={{
							display: 'inline-flex',
							flexShrink: 0,
							position: 'absolute',
							top: 4,
							left: 4,
							borderRadius: '100%',
						}}
					>
						<Svg svg={Check} aria-label='Sélectionné' />
					</IconCircleWrapper>
			}
			<div
				css={{
					width: '100%',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: textAlign === 'left' ? 'flex-start' : textAlign === 'right' ? 'flex-end' : 'center',
				}}
			>
				<Typo
					component='p'
					variant={size === 'large' ? 'title-2' : 'regular'}
					css={{ color: palette.blue[400], }}
				>{label}</Typo>
				{
					description &&
					<Typo
						component='p'
						variant={size === 'large' ? 'regular' : 'small'}
						css={{
							marginTop: spacing(2),
							color: palette.blue[200],
						}}
					>{description}</Typo>
				}
			</div>
		</div>
	</CardBox>
}