import { getEntity } from "../lib/entities";
import { getLocalStorageData } from "../lib/localStorage";

export async function insertEntities(data, steps) {
	// Move every uuid's entity directly in the data

	let dataJson = JSON.stringify(data);

	const entityNames = [...new Set( // Delete potential duplicates by doing Array -> Set -> Array 
		steps.flatMap(step => step.items.filter(item => item.type === 'entitiesArray').map(item => item.props?.entityName))
			.filter(i => !!i)
	)];

	try {
		// For every type of entities
		await Promise.all(entityNames.map(async entityName => {
			const {
				queryKey: entityQueryKey,
			} = getEntity(entityName);

			const entities = await getLocalStorageData(entityQueryKey, []);

			// Saving all the used entities
			const uuidsPairs = await Promise.all(entities.map(async entity => {
				const { uuid: localUuid, ...entityObject } = entity;
				if (!localUuid || !dataJson.includes(localUuid)) return null; // We don't need to save the entity if it is not used

				return {
					from: new RegExp(`"\\w+_uuid":"${localUuid}"`, 'g'), // Match the key AND the value
					to: JSON.stringify(entityObject).slice(1, -1) // Remove first and last characters `{` & `}` to avoid wrong nesting
				};
			}));

			uuidsPairs.filter(i => !!i).forEach(({ from, to }) => {
				dataJson = dataJson.replaceAll(from, to);
			});

			// Remove entities from localStorage now that they are saved
			// -> For now keep them as the user data is not saved remotely
			// localStorage?.removeItem(entityQueryKey);
		}));

		// All occurences of local uuids in `dataJson` are now replaced
		// TODO: Check if parsing the data causes any problem (it will be re-stringified later on)
		console.log(dataJson)
		return JSON.parse(dataJson);

	} catch (error) {
		console.error(error);

		return false;
	}
}

export function getFormattedAddress({
	street = '',
	number = '',
	box = '',
	postal_code = '',
	town = '',
	locale = 'fr',
}) {
	if (!street && !number && !box && !postal_code && !town) return '';

	return `${street} ${number}${box ? ` ${locale === 'nl' ? 'bus' : 'bte'} ${box}` : ''}, ${postal_code} ${town}`;
}
