import * as yup from 'yup';
import { setYupLocale } from '../../lib/setYupLocale';
import { addCustomDateValidator, addCustomPotentiallyRequiredValidator } from '../../lib/validators';

export const INSURANCE__DATA = {
	version: '1.0',
	company: {
		name: '',
	},
	contract_number: '',
	signature: {
		date: '',
	},
};

setYupLocale(yup);
addCustomDateValidator(yup);
addCustomPotentiallyRequiredValidator(yup);

export const INSURANCE__DATA__SCHEMA = yup.object().shape({
	company: yup.object().shape({
		name: yup.string().customPotentiallyRequired(),
	}),
	contract_number: yup.string(),
	signature: yup.object().shape({
		date: yup.string().customDate({ max: new Date() }),
	}),
});