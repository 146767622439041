/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react';
import { ReactComponent as North } from "@material-design-icons/svg/filled/north.svg";
import { ReactComponent as South } from "@material-design-icons/svg/filled/south.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animations, palette, spacing } from "../../theme";
import { CardBox } from "./CardBox";
import { Svg } from './Svg';
import { Tooltip } from "./Tooltip";
import { Typo } from "./Typo";

/**
 * A UI component to display a disclaimer when there is more content to scroll to.
 * 
 * @param {object} props Object containing the props:
 * - `props.component` Defines the component of the wrapper element.
 * - `props.wrapperCss` Defines the css to apply to the wrapper element.
 * - `props.showContentAbove` Defines if a disclaimer should be displayed when there is content to scroll to above the current view.
 * Defaults to `false`.
 * - `props.showContentBelow` Defines if a disclaimer should be displayed when there is content to scroll to below the current view.
 * Defaults to `true`.
 * - `props.children` The children to wrapelement can contains children.
 * - `...props` Any other props will be passed to the inner `div`.
 * @returns {any} The component.
 */
export function ScrollDisplay({
	component = 'div',
	wrapperCss = null,
	showContentAbove = false,
	showContentBelow = true,
	children,
	...props
}) {
	const { t } = useTranslation();

	const elementRef = useRef();

	const [contentAboveBelow, setContentAboveBelow] = useState([false, false]);

	useEffect(() => {
		const element = elementRef?.current;
		if (!element) return;

		function updateContentAboveBelow(element) {
			setContentAboveBelow([
				!(element.scrollTop < 10 /* Allow 10px of inaccuracy */),
				!((element.scrollTop + element.clientHeight) >= (element.scrollHeight - 10 /* Allow 10px of inaccuracy */)),
			]);
		}

		function handleScroll(event) {
			updateContentAboveBelow(event.target);
		}

		updateContentAboveBelow(element);

		element.addEventListener('scroll', handleScroll)

		return () => {
			element.removeEventListener('scroll', handleScroll)
		}
	}, [elementRef]);

	return jsx(
		component,
		{
			css: {
				position: 'relative',
				minHeight: 0,
				display: 'flex',
				...wrapperCss,
			},
		},
		<>
			{
				showContentAbove && contentAboveBelow[0] &&
				<div
					aria-hidden={true}
					css={{
						position: 'absolute',
						top: 3,
						left: 3,
						color: palette.blue[200],
						// backgroundColor: 'white',
						// borderRadius: '4px,
						// border: '1px solid',
						// borderColor: palette.blue[200],
						animation: `${animations.fadeIn} 0.5s`,
						zIndex: 1,
					}}
				>
					<Tooltip
						placement='bottom'
						arrow
						anchorAriaRefType='aria-labelledby'
						role='tooltip'
						content={
							<CardBox
								css={{
									maxWidth: 150,
									padding: spacing(2),
								}}
							>
								<Typo
									component='p'
									variant='small'
								>{t('global.contentAbove')}</Typo>
							</CardBox>
						}
					>
						<div>
							<Svg
								svg={North}
								fontSize='small'
							/>
						</div>
					</Tooltip>
				</div>
			}
			{
				showContentBelow && contentAboveBelow[1] &&
				<div
					aria-hidden={true}
					css={{
						position: 'absolute',
						bottom: spacing(3),
						left: 3,
						color: palette.blue[200],
						// backgroundColor: 'white',
						// borderRadius: '4px',
						// border: '1px solid',
						// borderColor: palette.blue[200],
						animation: `${animations.fadeIn} 0.5s`,
						zIndex: 1,
					}}
				>
					<Tooltip
						placement='top'
						arrow
						anchorAriaRefType='aria-labelledby'
						role='tooltip'
						content={
							<CardBox
								css={{
									maxWidth: 150,
									padding: spacing(2),
								}}
							>
								<Typo
									component='p'
									variant='small'
								>{t('global.contentBelow')}</Typo>
							</CardBox>
						}
					>
						<div>
							<Svg
								svg={South}
								fontSize='small'
							/>
						</div>
					</Tooltip>
				</div>
			}
			<div
				ref={elementRef}
				css={{
					overflowY: 'auto',
					width: '100%',
				}}
				{...props}
			>
				{children}
			</div>
		</>
	)
}