export function getAuthToken() {
	if (!localStorage) throw new Error('`localStorage` not available');

	return localStorage.getItem('token') || null;
}

export function setAuthToken(token) {
	if (!localStorage) throw new Error('`localStorage` not available');

	if (token === null) {
		localStorage.removeItem('token');
	} else {
		localStorage.setItem('token', token);
	}

	
	return;
}