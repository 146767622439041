import { Link as RouterLink } from "react-router-dom";

export function isExternalLink(url = '') {
	if (!url) return false;

	return (url.startsWith('http://') || url.startsWith('https://'));
}

export function isTelLink(url = '') {
	if (!url) return false;

	return (url.startsWith('tel:'));
}

export function isEmailLink(url = '') {
	if (!url) return false;

	return (url.startsWith('mailto:'));
}

export function getLinkComponentProps(url) {
	return {
		...isExternalLink(url)
			? {
				component: 'a',
				href: url,
				target: '_blank',
			}
			: (isTelLink(url) || isEmailLink(url))
				? {
					component: 'a',
					href: url,
				}
				: {
					component: RouterLink,
					to: url,
				}
	}
}