import { request } from "../lib/requests";
import { ENDPOINTS, URL } from "../variables/api";

export async function fetchService({ type = '' }) {
	try {
		const response = await request({
			method: 'GET',
			url: `${URL}${ENDPOINTS.services.root}`,
			data: {
				type,
			},
			needToken: true,
		});

		// if (response.length === 0) {
		// 	return await createService({ type });
		// }

		return response[0];
	} catch (error) {
		console.error(error);

		return null;
	}
}

export async function updateService({ type = '', data }) {
	try {
		if (!data) throw new Error('Missing data parameter');

		// Get current service
		const service = await fetchService({ type });
		// If no service, create it
		if (!service) return createService({ type, data });

		// Else update it
		const response = await request({
			method: 'PUT',
			url: `${URL}${ENDPOINTS.services.root}/${service.uuid}`,
			data: {
				type,
				data,
			},
			needToken: true,
		});

		return response;
	} catch (error) {
		console.error(error);

		return null;
	}
}

export async function createService({ type = '', data }) {
	try {
		const response = await request({
			method: 'POST',
			url: `${URL}${ENDPOINTS.services.create}`,
			data: {
				type,
				data,
			},
			needToken: true,
		});

		return Array.isArray(response) ? response[0] : response;
	} catch (error) {
		console.error(error);

		return null;
	}
}