export const URL = process.env.REACT_APP_API_URL;
export const BEARER = process.env.REACT_APP_API_BEARER;

export const ENDPOINTS = {
	login: '/api/front/users/login',
	generateResetToken: '/api/front/users/reset/token',
	resetPassword: '/api/front/users/reset/password',
	register: '/api/front/users/signup',
	contact: '/api/front/contact',
	user: '/api/front/users/me',
	profiles: {
		root: '/api/front/profiles',
		create: '/api/front/profiles/create',
	},
	services: {
		root: '/api/front/services',
		create: '/api/front/services/create',
	},
	people: {
		root: '/api/front/peoples',
		create: '/api/front/peoples/create',
	},
	insurance: {
		root: '/api/front/insurances',
		create: '/api/front/insurances/create',
	},
};