/** @jsxImportSource @emotion/react */

import { mq, spacing } from "../../theme";
import { Container } from "../ui/Container";
import { FlexGrid } from "../ui/FlexGrid";

export function ContainerWithSidebar({
	sidebar = null,
	sidebarSticky = true,
	sidebarFitDesktopScreen = false, // Should be set to true only when the sidebar contains an element with "overflowY: 'auto' || 'scroll'", otherwise it will glitch out
	showSidebarOnMobile = false, // Should be set to true only when the sidebar contains an element with "overflowY: 'auto' || 'scroll'", otherwise it will glitch out
	children,
}) {
	return <Container>
		<FlexGrid container spacing={4}>
			<FlexGrid
				xs={12} md={3}
				css={{
					...!showSidebarOnMobile && {
						display: 'none',
						[mq('md')]: {
							display: 'block',
						},
					},
				}}
			>
				<div
					css={{
						height: '100%',
					}}
				>
					<div
						css={{
							...sidebarSticky && {
								boxSizing: 'border-box',
								position: 'sticky',
								zIndex: 1, // To ensure absolute elements in this `'sticky'` sidebar are above `'relative'` elements in main content, which comes later in the DOM
								left: 0,
								top: 68,
								paddingTop: spacing(4),
								paddingBottom: spacing(4),
								marginTop: `-${spacing(4)}`,
								display: 'flex',
								flexDirection: 'column',
								maxHeight: '33vh',
							},
							[mq('md')]: {
								marginBottom: spacing(12),
								...sidebarSticky && {
									maxHeight: sidebarFitDesktopScreen ? 'calc(100vh - 68px)' : 'none',
								},
							},
						}}
					>
						{sidebar}
					</div>
				</div>
			</FlexGrid>
			<FlexGrid
				xs={12} md={9} lg={8}
				css={{
					marginLeft: 'auto',
				}}
			>
				{children}
			</FlexGrid>
		</FlexGrid>
	</Container >
}