import * as yup from 'yup';
import { setYupLocale } from '../../lib/setYupLocale';
import { addCustomDateValidator, addCustomEmailValidator, addCustomNationalNumberValidator, addCustomRefEntityValidator } from '../../lib/validators';
import { INSURANCE__DATA__SCHEMA as ENTITY__INSURANCE__DATA__SCHEMA } from '../entities/insurance';

const insuranceDataSchema = ENTITY__INSURANCE__DATA__SCHEMA;

export const FUNERAL_DECISION__DATA = {
	version: '1.0',
	decision_type: '',
	previous_town: '',
	funeral: {
		type: '',
		cremation: {
			type: '',
		},
		town: '',
		other_place: '',
	},
	has_funeral_contract: '',
	funeral_contracts: [], // { insurance_uuid : uuid }
	funeral_belief: '',
	// funeral_home: '',
	// other_will: '',
	email: '',
	first_name: '',
	last_name: '',
	birth_date: '',
};

setYupLocale(yup);
addCustomNationalNumberValidator(yup);
addCustomDateValidator(yup);
addCustomEmailValidator(yup);
addCustomRefEntityValidator(yup);

export const FUNERAL_DECISION__DATA__SCHEMA = yup.object().shape({
	decision_type: yup.string().required(),
	previous_town: yup.string().when('decision_type', {
		is: (decision_type) => decision_type === 'change' || decision_type === 'withdrawal',
		then: (schema) => schema.required(),
	}),
	funeral: yup.object().when('decision_type', {
		is: (decision_type) => decision_type !== 'withdrawal',
		then: (schema) => schema.shape({
			type: yup.string().required(),
			cremation: yup.object().when('type', {
				is: 'cremation',
				then: (schema) => schema.shape({
					type: yup.string().required(),
				}),
			}),
			town: yup.string().when(['type', 'cremation.type'], {
				is: (type, cremation_type) => type !== 'cremation' || (cremation_type !== 'scattering_other_place' && cremation_type !== 'burial_other_place' && cremation_type !== 'conservation_other_place'),
				then: (schema) => schema.required(),
			}),
			other_place: yup.string().when(['type', 'cremation.type'], {
				is: (type, cremation_type) => type === 'cremation' && (cremation_type === 'scattering_other_place' || cremation_type === 'burial_other_place' || cremation_type === 'conservation_other_place'),
				then: (schema) => schema.required(),
			}),
		}),
	}),
	has_funeral_contract: yup.string().when('decision_type', {
		is: (decision_type) => decision_type !== 'withdrawal',
		then: (schema) => schema.required(),
	}),
	funeral_contracts: yup.array().when(['decision_type', 'has_funeral_contract'], {
		is: (decision_type, has_funeral_contract) => decision_type !== 'withdrawal' && has_funeral_contract === 'yes',
		then: (schema) => schema.of(
			yup.object().shape({
				insurance_uuid: yup.string().uuid().customRefEntity({
					message: 'validation.funeralDecision.funeralContract',
					entitySchema: insuranceDataSchema,
					entitiesPath: 'insurances',
					validations: [
						{ path: 'company.name', addRequired: true, },
						{ path: 'contract_number', },
						{ path: 'signature.date', },
					],
				}),
			}),
		).min(1, ({ min }) => ({ key: 'validation.funeralDecision.funeralContractsMin', values: { count: min }, })),
	}),
	funeral_belief: yup.string().when('decision_type', {
		is: (decision_type) => decision_type !== 'withdrawal',
		then: (schema) => schema.required(),
	}),
	// funeral_belief_consent: yup.boolean().when('decision_type', {
	// 	is: (decision_type) => decision_type !== 'withdrawal',
	// 	then: (schema) => schema.required().oneOf([true], i18next.t('validation.required')),
	// }),
	// funeral_home: yup.string(),
	// other_will: yup.string(),
	email: yup.string().customEmail().required(),
	first_name: yup.string().required(),
	last_name: yup.string().required(),
	birth_date: yup.string().customDate({ max: new Date() }).required(),
});