import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollTopWrapper({ children }) {
	const location = useLocation();
	useLayoutEffect(() => { // CHECK: useEffect seems to work too and is recommended in general, see if there is any reason for using useLayoutEffect
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);

	return children
}