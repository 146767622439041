/** @jsxImportSource @emotion/react */

import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import { spacing } from "../../theme";
import { CardBox } from "./CardBox";
import { Svg } from "./Svg";
import { Tooltip } from "./Tooltip";
import { Typo } from "./Typo";

export function Infobubble({
	title = '',
}) {
	return <Tooltip
		placement='top'
		arrow
		phrasingContent
		anchorAriaRefType='aria-describedby'
		content={
			<CardBox
				component='span'
				css={{
					maxWidth: 300,
					padding: spacing(2),
				}}
			>
				<Typo
					component='span'
					variant='small'
				>{title}</Typo>
			</CardBox>
		}
	>
		<span><Svg svg={Info} /></span>
	</Tooltip>
}