/** @jsxImportSource @emotion/react */

import { mq } from "../../theme";

const gutterBase = 8;

function getWidth(columns) {
	return `${Math.round((columns / 12) * 10e7) / 10e5}%`;
}
function getSizeStyles(columns) {
	if (!columns) return null;

	if (columns === true) return {
		flexGrow: 1,
		flexBasis: 0,
		maxWidth: '100%',
	};

	if (columns === 'auto') return {
		flexGrow: 0,
		flexBasis: 'auto',
		maxWidth: 'none',
	};

	return {
		flexGrow: 0,
		flexBasis: getWidth(columns),
		maxWidth: getWidth(columns),
	}
}

function getStyleSizeWithMediaQuery(size, columns) {
	if (!size) return null;

	const styles = getSizeStyles(columns);

	if (size === 'xs') return styles;

	return {
		[mq(size)]: {
			...styles,
		},
	}
}

function getGutterSizesStyles(spacings) {
	let gutterSizes = {};

	Object.keys(spacings).forEach(size => {
		const gutterSize = gutterBase * (spacings[size]);

		const styles = {
			margin: -gutterSize / 2,
			width: `calc(100% + ${gutterSize}px)`,
			'&>div': {
				padding: gutterSize / 2,
			},
		};

		gutterSizes = {
			...gutterSizes,
			...(
				size === 'xs'
					? styles
					: {
						[mq(size)]: {
							...styles,
						},
					}
			)
		};
	});

	return gutterSizes;
}

export function FlexGrid({
	container = false,
	spacing = 0,
	xs = false, // If a number, it sets the number of columns the grid item uses. If `'auto'`, the grid item's width matches its content. If `true`, the grid item's width grows to use the space available in the grid container.
	sm = false,
	md = false,
	lg = false,
	xl = false,
	children,
	...props
}) {
	if (typeof spacing !== 'object') {
		spacing = { xs: spacing, };
	}

	if (container) return <div
		css={{
			boxSizing: 'border-box',
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			...getGutterSizesStyles(spacing),
		}}
		{...props}
	>
		{children}
	</div>

	return <div
		css={{
			boxSizing: 'border-box',
			margin: 0,
			flexDirection: 'row',
			flexGrow: 0,
			flexBasis: '100%',
			maxWidth: '100%',
			...getStyleSizeWithMediaQuery('xs', xs),
			...getStyleSizeWithMediaQuery('sm', sm),
			...getStyleSizeWithMediaQuery('md', md),
			...getStyleSizeWithMediaQuery('lg', lg),
			...getStyleSizeWithMediaQuery('xl', xl),
		}}
		{...props}
	>
		{children}
	</div>
}