import _set from 'lodash.set';

export async function getFormErrors({
	data = {},
	dataSchema = null,
	context: extraContext = {},
}) {
	return await dataSchema.validate(data, {
		abortEarly: false, // This will return all errors and not stop at first one
		context: {
			data: data, // Used when a schema needs to check a value higher in the data tree, as Yup only allows to look for a property's parent or siblings
			...extraContext, // For conditional validations based on external factors or other datasets
		},
	})
		.then(() => null)
		.catch(error => {
			const validationErrors = error.inner.map(innerError => ({
				path: innerError.path,
				type: innerError.type,
				message: innerError.message,
			}));

			const newErrors = {};
			validationErrors.forEach(({ path = '', message = '' }) => {
				// _set(newErrors, path, type === 'required' ? 'required' : message);
				// _set(newErrors, path, message);
				_set(newErrors, path, (typeof message === 'string' ? message : () => message)); // Pass functions rather than objects to avoid nested objects (otherwise will mess up `getFlattenedObject`)
			});

			return newErrors;
		});
}