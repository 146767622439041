/** @jsxImportSource @emotion/react */

import { ReactComponent as Sync } from "@material-design-icons/svg/filled/sync.svg";
import { useTranslation } from "react-i18next";
import { animations, spacing } from "../theme";
import { CardModal } from "./ui/CardModal";
import { Svg } from "./ui/Svg";
import { Typo } from "./ui/Typo";

export function LoadingModal({
	open = false,
}) {
	const { t } = useTranslation();

	return <CardModal
		open={open}
		header={
			<Typo
				component='h2'
				variant='title-1'
			>
				{t('global.loading.title')}
				<Svg
					svg={Sync}
					aria-hidden={true}
					css={{
						marginLeft: spacing(2),
						animation: `${animations.rotateAntiClockwise} 1s linear infinite`,
					}}
				/>
			</Typo>
		}
	>
		<Typo
			component='p'
			variant='regular'
		>{t('global.loading.description')}</Typo>
	</CardModal>
}