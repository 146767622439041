/** @jsxImportSource @emotion/react */

import { Infobubble } from "../components/ui/Infobubble";

export function parseCustomMarkdown(string) {
	const startMatch = string.match(/<infobubble>/);
	if (!startMatch) return string;

	const beforeStart = string.slice(0, startMatch.index);
	const afterStart = string.slice(startMatch.index + startMatch[0].length);

	const endMatch = afterStart.match(/<\/infobubble>/);

	if (!endMatch) return string;

	const beforeEnd = afterStart.slice(0, endMatch.index);
	const afterEnd = afterStart.slice(endMatch.index + endMatch[0].length);

	return <>
		{beforeStart}
		<Infobubble
			title={beforeEnd}
		/>
		{afterEnd}
	</>;
}