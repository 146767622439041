import AnimateHeight from 'react-animate-height';

export function Collapse({
	open = false,
	children,
}) {
	return <AnimateHeight
		height={open ? 'auto' : 0}
		duration={300}
	>
		{children}
	</AnimateHeight>
}