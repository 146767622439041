import { createContext, useState } from "react";

const defaultContext = {
	error: null,
	setError: () => { },
};

export const ErrorContext = createContext(defaultContext);

export function useErrorContext() {
	const [error, setError] = useState(null);

	return {
		error,
		setError,
	};
}