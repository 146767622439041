/** @jsxImportSource @emotion/react */

import { useId, useState } from "react";
import { spacing } from "../../theme";
import { CardBox } from "./CardBox";
import { Collapse } from "./Collapse";
import { CollapseButton } from "./CollapseButton";

/**
 * A card-like component with its content under a collapse.
 * 
 * @param {object} props Object containing the props:
 * - `props.buttonContent` The content of the button
 * - `props.children` The children under the collapse.
 * - `...props` Any other props will be passed.
 * @returns {any} The component
 */
export function CollapseCard({
	buttonContent = null,
	children,
	...props
}) {
	const id = useId();

	const [open, setOpen] = useState(false);

	function toggleOpen() {
		setOpen(prevState => !prevState);
	}

	return <CardBox
		{...props}
	>
		<CollapseButton
			id={`collapseCard_${id}-button`}
			rid={`collapseCard_${id}-content`}
			open={open}
			handleClick={toggleOpen}
		>
			{buttonContent}
		</CollapseButton>
		<Collapse
			open={open}
		>
			<div
				aria-labelledby={`collapseCard_${id}-button`}
				id={`collapseCard_${id}-content`}
				role='region'
				css={{
					marginTop: spacing(4),
					paddingLeft: spacing(4),
				}}
			>
				{children}
			</div>
		</Collapse>
	</CardBox>
}