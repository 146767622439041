import { useEffect, useState } from "react";

export function useConditionValidatedOnce(value = null, condition = () => { }) {
	const [validatedOnce, setValidatedOnce] = useState(false);

	useEffect(() => {
		if (validatedOnce) return;
		if (condition(value)) setValidatedOnce(true);
	}, [value, validatedOnce, setValidatedOnce]);

	return validatedOnce;
}