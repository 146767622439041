import { createContext, useState } from "react";
import { setAuthToken } from "../lib/authToken";

const defaultContext = {
	authToken: null,
	updateAuthToken: () => { },
};

export const AuthTokenContext = createContext(defaultContext);

export function useAuthTokenContext() {
	const [token, setToken] = useState(null);

	function tokenSet(payload) {
		setAuthToken(payload);

		setToken(payload);
	}

	return {
		authToken: token,
		updateAuthToken: tokenSet,
	};
}