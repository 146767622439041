import { keyframes } from "@emotion/react";

export const breakpoints = [
	{ shorthand: 'xs', value: 0 },
	{ shorthand: 'sm', value: 600 },
	{ shorthand: 'md', value: 900 },
	{ shorthand: 'lg', value: 1200 },
	{ shorthand: 'xl', value: 1536 },
];

export function mq(breakpoint) {
	return `@media (min-width: ${breakpoints.find(bp => breakpoint === bp.shorthand)?.value || 0}px)`;
}

let spacings = [
	// Tailwind's defaults
	[0, '0px'],
	['px', '1px'],
	[0.5, '2px'],
	[1, '4px'],
	[1.5, '6px'],
	[2, '8px'],
	[2.5, '10px'],
	[3, '12px'],
	[3.5, '14px'],
	[4, '16px'],
	[5, '20px'],
	[6, '24px'],
	[7, '28px'],
	[8, '32px'],
	[9, '36px'],
	[10, '40px'],
	[11, '44px'],
	[12, '48px'],
	[14, '56px'],
	[16, '64px'],
	[20, '80px'],
	[24, '96px'],
	[28, '112px'],
	[32, '128px'],
	[36, '144px'],
	[40, '160px'],
	[44, '176px'],
	[48, '192px'],
	[52, '208px'],
	[56, '224px'],
	[60, '240px'],
	[64, '256px'],
	[72, '288px'],
	[80, '320px'],
	[96, '384px'],
];

let palette = {
	// Tailwind's defaults
	gray: {
		50: '#f9fafb',
		100: '#f3f4f6',
		200: '#e5e7eb',
		300: '#d1d5db',
		400: '#9ca3af',
		500: '#6b7280',
		600: '#4b5563',
		700: '#374151',
		800: '#1f2937',
		900: '#111827',
	},
	red: {
		50: '#fef2f2',
		100: '#fee2e2',
		200: '#fecaca',
		300: '#fca5a5',
		400: '#f87171',
		500: '#ef4444',
		600: '#dc2626',
		700: '#b91c1c',
		800: '#991b1b',
		900: '#7f1d1d',
	},
	green: {
		50: '#f0fdf4',
		100: '#dcfce7',
		200: '#bbf7d0',
		300: '#86efac',
		400: '#4ade80',
		// 500: '#22c55e',
		500: '#4e9c4c', // Custom color
		600: '#16a34a',
		700: '#15803d',
		800: '#166534',
		900: '#14532d',
	},
	yellow: {
		50: '#fefce8',
		100: '#fef9c3',
		// 200: '#fef08a',
		200: '#fedb69', // Custom color
		300: '#fde047',
		// 400: '#facc15',
		400: '#f5c424', // Custom color
		500: '#eab308',
		600: '#ca8a04',
		700: '#a16207',
		800: '#854d0e',
		900: '#713f12',
	},
	blue: { // Custom color set
		200: '#889dae',
		300: '#335270',
		400: '#03123b',
	},
	bg: { // Custom color set
		light: '#f5f7f8',
		medium: '#e5e5e5',
	},
};

let typography = {
	// Tailwind's defaults
	'text-xs': {
		fontSize: '0.75rem',
		lineHeight: '1rem', /* 16px */
	},
	'text-sm': {
		fontSize: '0.875rem',
		lineHeight: '1.25rem', /* 20px */
	},
	'text-base': {
		fontSize: '1rem',
		lineHeight: '1.5rem', /* 24px */
	},
	'text-lg': {
		fontSize: '1.125rem',
		lineHeight: '1.75rem', /* 28px */
	},
	'text-xl': {
		fontSize: '1.25rem',
		lineHeight: '1.75rem', /* 28px */
	},
	'text-2xl': {
		fontSize: '1.5rem',
		lineHeight: '2rem', /* 32px */
	},
	'text-3xl': {
		fontSize: '1.875rem',
		lineHeight: '2.25rem', /* 36px */
	},
	'text-4xl': {
		fontSize: '2.25rem',
		lineHeight: '2.5rem', /* 40px */
	},
	'text-5xl': {
		fontSize: '3rem',
		lineHeight: 1,
	},
	'text-6xl': {
		fontSize: '3.75rem',
		lineHeight: 1,
	},
	'text-7xl': {
		fontSize: '4.5rem',
		lineHeight: 1,
	},
	'text-8xl': {
		fontSize: '6rem',
		lineHeight: 1,
	},
	'text-9xl': {
		fontSize: '8rem',
		lineHeight: 1,
	},
};

// Custom typography
typography = {
	...typography,
	'marketing-title': {
		color: palette.blue[400],
		fontFamily: "'Literata', sans-serif",
		fontSize: typography['text-2xl'].fontSize,
		lineHeight: typography['text-2xl'].lineHeight,
		[mq('md')]: {
			fontSize: typography['text-3xl'].fontSize,
			lineHeight: typography['text-3xl'].lineHeight,
		},
		[mq('lg')]: {
			fontSize: typography['text-4xl'].fontSize,
			lineHeight: typography['text-4xl'].lineHeight,
		},
	},
	'title-1': {
		color: palette.blue[400],
		fontWeight: 500,
		fontFamily: "'DM Sans', sans-serif",
		fontSize: typography['text-xl'].fontSize,
		lineHeight: typography['text-xl'].lineHeight,
		[mq('md')]: {
			fontSize: typography['text-2xl'].fontSize,
			lineHeight: typography['text-2xl'].lineHeight,
		},
		[mq('lg')]: {
			fontSize: typography['text-3xl'].fontSize,
			lineHeight: typography['text-3xl'].lineHeight,
		},
	},
	'title-2': {
		color: palette.blue[400],
		fontWeight: 500,
		fontFamily: "'DM Sans', sans-serif",
		fontSize: typography['text-lg'].fontSize,
		lineHeight: typography['text-lg'].lineHeight,
		[mq('md')]: {
			fontSize: typography['text-xl'].fontSize,
			lineHeight: typography['text-xl'].lineHeight,
		},
	},
	'regular': {
		fontFamily: "'DM Sans', sans-serif",
		fontSize: typography['text-base'].fontSize,
		// lineHeight: typography['text-base'].lineHeight,
		[mq('lg')]: {
			fontSize: typography['text-lg'].fontSize,
			// lineHeight: typography['text-lg'].lineHeight,
		},
		lineHeight: '1.5rem', /* 24px */
	},
	'small': {
		fontFamily: "'DM Sans', sans-serif",
		fontSize: typography['text-sm'].fontSize,
		// lineHeight: typography['text-sm'].lineHeight,
		[mq('lg')]: {
			fontSize: typography['text-base'].fontSize,
			// lineHeight: typography['text-base'].lineHeight,
		},
		lineHeight: '1.25rem', /* 20px */
	},
	'extra-small': {
		fontSize: typography['text-sm'].fontSize,
		lineHeight: typography['text-sm'].lineHeight,
		fontFamily: "'DM Sans', sans-serif",
	},
};

export const spacing = (value) => spacings.find(pair => pair[0] === value)?.[1] || '0px';
export { typography, palette };

export function getButtonAndLinkStyle({
	variant,
	color,
	size,
}) {
	return {
		border: 0,
		transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter', // transition
		transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', // transition
		transitionDuration: '300ms', // duration-300
		textDecoration: 'none',
		textTransform: 'none',
		borderRadius: '4px',
		padding: 0,
		fontWeight: 400,
		backgroundColor: 'transparent',
		boxShadow: 'none',
		boxSizing: 'border-box',
		...((variant === 'contained' || variant === 'outlined') ? {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: typography['regular'].fontSize,
			lineHeight: typography['regular'].lineHeight,
			padding: `${spacing(2)} ${spacing(5)}`,
			...(variant === 'contained' ? {
				backgroundColor: color === 'primary'
					? palette.yellow[400]
					: color === 'secondary'
						? palette.gray[700]
						: 'transparent',
				color: 'white',
				// color: palette.blue[300],
			} : variant === 'outlined' && {
				border: 'solid 1px',
				borderColor: 'currentColor',
				color: color === 'primary'
					? palette.yellow[400]
					: color === 'secondary'
						? palette.gray[700]
						: 'currentColor',
			}),
			...(size === 'small' ? {
				padding: `${spacing(2)} ${spacing(3)}`,
			} : size === 'large' && {
				minHeight: 48,
				paddingLeft: spacing(4),
				paddingRight: spacing(4),
				[mq('md')]: {
					paddingLeft: spacing(6),
					paddingRight: spacing(6),
				},
			}),
		} : variant === 'icon' ? {
			minWidth: 'auto',
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: spacing(2),
			border: 'solid 1px',
			borderColor: 'currentColor',
			color: color === 'primary'
				? palette.yellow[400]
				: color === 'secondary'
					? palette.gray[700]
					: 'currentColor',
		} : (variant === 'underlined' || variant === 'underlinedHover') && {
			width: 'auto',
			display: 'flex',
			alignItems: 'center',
			color: palette.gray[700],
			fontSize: typography['regular'].fontSize,
			lineHeight: typography['regular'].lineHeight,
			...(variant === 'underlined' && {
				textDecoration: 'underline',
			}),
		}),
		'&:focus': {
			outline: 'none',
		},
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent',
			...(variant === 'contained' ? {
				...(color === 'primary' && {
					backgroundColor: palette.yellow[200],
				}),
			} : variant === 'outlined' ? {
				textDecoration: 'underline',
			} : variant === 'icon' ? {
				color: palette.blue[400],
			} : variant === 'underlined' ? {
				textDecoration: 'none',
				color: palette.blue[400],
				backgroundColor: 'transparent',
			} : variant === 'underlinedHover' && {
				textDecoration: 'underline',
				color: palette.blue[400],
				backgroundColor: 'transparent',
			}),
		},
		'&:not([disabled])': {
			cursor: 'pointer',
		},
	}
}

export const animations = {
	rotateClockwise: keyframes`
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	`,
	rotateAntiClockwise: keyframes`
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(-360deg);
		}
	`,
	fadeIn: keyframes`
		from {
			opacity: 0;
		}
	`,
	slideInLeft: keyframes`
		from {
			transform: translateX(-80px);
		}
	`,
	slideInRight: keyframes`
		from {
			transform: translateX(80px);
		}
	`,
	slideInTop: keyframes`
		from {
			transform: translateY(-80px);
		}
	`,
	slideInBottom: keyframes`
		from {
			transform: translateY(80px);
		}
	`,
};