import { request } from "../lib/requests";
import { ENDPOINTS, URL } from "../variables/api";

export async function fetchUser() {
	try {
		const response = await request({
			method: 'GET',
			url: `${URL}${ENDPOINTS.user}`,
			needToken: true,
		});

		return response;
	} catch (error) {
		console.error(error);

		return false;
	}
}

export async function updateUser(data) {
	try {
		const response = await request({
			method: 'PUT',
			url: `${URL}${ENDPOINTS.user}`,
			data,
			needToken: true,
		});

		return response;
	} catch (error) {
		console.error(error);

		return false;
	}
}

export async function deleteUser() {

}