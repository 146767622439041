/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { palette } from "../../theme";
import { InteractiveCard } from "../common/InteractiveCard";

export function CheckboxCardButton({
	name = '',
	value = false, // Accepts an `array`, `boolean`, or a `string`
	htmlValue = true,
	label = '',
	description = '',
	size = 'large',
	textAlign = 'center',
	handleChange: givenHandleChange = () => { },
	updateErrors: updateFormErrors = () => { },
}) {
	const { t } = useTranslation();

	const valueType = (value === true || value === false)
		? 'boolean'
		: Array.isArray(value)
			? 'array'
			: typeof value === 'string'
				? 'string'
				: 'other';

	const checked = valueType === 'boolean'
		? value
		: valueType === 'array'
			? value.includes(htmlValue)
			: !!value;

	useEffect(() => {
		updateFormErrors();
	}, [checked]);

	function handleChange() {
		if (valueType === 'boolean') {
			givenHandleChange([name, !checked]);
		} else if (valueType === 'array') {
			if (htmlValue === 'n/a') {
				// If special case `'n/a'`, delete all other entries from array, as they cannot coexist with it
				givenHandleChange([name, !checked ? [htmlValue] : []]);
				return;
			}

			const arrayCopy = [...value];
			if (value.includes('n/a')) arrayCopy.splice(value.indexOf('n/a'), 1); // Delete `'n/a'` if present as it cannot coexist with another value
			if (value.includes(htmlValue)) arrayCopy.splice(value.indexOf(htmlValue), 1);
			givenHandleChange([name, !checked ? [...arrayCopy, htmlValue] : arrayCopy]);
		} else {
			givenHandleChange([name, !checked ? htmlValue : '']);

			if (valueType === 'other') console.warn(`'valueType' of '${typeof value}' is not supported, defaults to 'string behaviour'.`)
		}
	}

	const inputRef = useRef();

	function handleCardClick() {
		inputRef.current?.click();
		inputRef.current?.focus();
	}

	return <>
		<input
			ref={inputRef}
			type='checkbox'
			checked={checked}
			aria-labelledby={`${name}[${htmlValue}]`}
			className='visuallyHidden'
			onChange={handleChange}
			css={{
				'&:hover+*, &:focus+*': {
					backgroundColor: 'white',
					borderColor: palette.blue[400],
				},
			}}
		/>
		<InteractiveCard
			id={`${name}[${htmlValue}]`}
			onClick={handleCardClick}
			selectionIconType='checkbox'
			selected={checked}
			label={t(label)}
			description={t(description)}
			size={size}
			textAlign={textAlign}
		/>
	</>
}