/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import { breakpoints, mq, spacing } from "../../theme";

const fixedStyle = {
	...breakpoints.map(({ shorthand, value }) => ({
		[mq(shorthand)]: {
			maxWidth: value,
		},
	})),
}

export function Container({
	component = 'div',
	fixed = true,
	maxWidth: givenMaxWidth = 'lg',
	disableGutters = false,
	children,
	...props
}) {
	const maxWidth = breakpoints.find(bp => givenMaxWidth === bp.shorthand)?.value || 0;

	return jsx(
		component,
		{
			css: {
				boxSizing: 'border-box',
				marginLeft: 'auto',
				marginRight: 'auto',
				...!disableGutters && {
					paddingLeft: spacing(4),
					paddingRight: spacing(4),
					[mq('sm')]: {
						paddingLeft: spacing(6),
						paddingRight: spacing(6),
					},
				},
				...fixed && fixedStyle,
				...maxWidth && { maxWidth: maxWidth },
			},
			...props
		},
		children
	)
}