export function getCurrentDate() {
	const date = new Date();
	let day = date.getDate();
	let month = date.getMonth() + 1;
	let year = date.getFullYear();

	if (day < 10) day = '0' + day;
	if (month < 10) month = '0' + month;

	return day + '/' + month + '/' + year;

}

export function getDateArray(string) {
	const [year = '', month = '', day = ''] = string.split('-');

	return [year, month, day];
}

export function getFormattedDate(string, inputFormat = 'Y-m-d', outputFormat = 'd/m/Y') {
	if (!string) return '';

	const [year = '', month = '', day = ''] = string.split('-');
	return `${day}/${month}/${year}`;
}