/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react';
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/filled/chevron_right.svg";
import { ReactComponent as OpenInNew } from "@material-design-icons/svg/filled/open_in_new.svg";
import React from "react";
import { isExternalLink } from '../../lib/links';
import { palette, spacing } from "../../theme";
import { Svg } from './Svg';

/**
 * A UI component to use as a base for card-like elements.
 * 
 * @param {object} props Object containing the props:
 * - `props.component` Defines the component which should be used.
 * Defaults to `'div'`.
 * - `props.borderColor` Defines the `borderColor`.
 * Accepts shorthands `'base'`, `'invalid'`, `'cta'`, or any color as a string.
 * Defaults to `'base'`.
 * - `props.backgroundColor` Defines the `backgroundColor`.
 * Accepts any `backgroundColor` as a string.
 * Defaults to `'white'`.
 * - `props.hideLinkIcon` Defines if the icon for link elements should be hidden.
 * Defaults to `false`.
 * - `props.children` The element can contains children.
 * - `...props` Any other props will be passed.
 * @returns {any} The component
 */
export function CardBox({
	component = 'div',
	borderColor = 'base',
	backgroundColor = 'white',
	hideLinkIcon = false,
	children,
	...props
}) {
	return jsx(
		component,
		{
			css: {
				// Base
				display: 'block',
				border: '1px solid',
				height: '100%',
				boxSizing: 'border-box',
				borderRadius: '4px',
				position: 'relative',
				padding: spacing(8),
				// Props
				backgroundColor: backgroundColor,
				borderColor: (borderColor === 'base'
					? palette.gray[300]
					: borderColor === 'invalid'
						? palette.yellow[500]
						: borderColor === 'cta'
							? palette.yellow[400]
							: borderColor),
				...(props.to || props.onClick) && {
					textDecoration: 'none',
					transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter', // transition
					transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', // transition
					transitionDuration: '300ms', // duration-300
					'&:hover': {
						borderColor: palette.blue[400],
						backgroundColor: backgroundColor,
						'&>.MuiSvgIcon-root': {
							color: palette.blue[400],
						},
					},
				},
			},
			...props,
		},
		<>
			{children}
			{
				(props.to && !hideLinkIcon) &&
				(
					isExternalLink(props.to)
						?
						<Svg
							svg={OpenInNew}
							css={{
								position: 'absolute',
								right: 9,
								top: 9,
								color: palette.blue[200],
								// width: 30,
								// height: 30,
								transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter', // transition
								transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', // transition
								transitionDuration: '300ms', // duration-300
							}}
						/>
						:
						<Svg
							svg={ChevronRight}
							css={{
								position: 'absolute',
								right: 0,
								bottom: spacing(2),
								color: palette.blue[200],
								width: 30,
								height: 30,
								transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter', // transition
								transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', // transition
								transitionDuration: '300ms', // duration-300
							}}
						/>
				)
			}
		</>
	)
}