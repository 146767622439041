/** @jsxImportSource @emotion/react */

import { palette, spacing, typography } from "../../theme";
import { CollapseButton } from "../ui/CollapseButton";

export function DropdownButton({
	label = '',
	...props
}) {
	return <CollapseButton
		{...props}
		css={{
			...typography['regular'],
			color: palette.blue[300],
			width: '100%',
			paddingLeft: spacing(4),
			paddingRight: spacing(4),
			paddingTop: spacing(2),
			paddingBottom: spacing(2),
		}}
	>{label}</CollapseButton>
}