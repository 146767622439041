import { getFlattenedObject } from "../../lib/objects";

/**
 * A function to get all `name`s of a step. Arrays will be written as `'[*]'`.
 * 
 * @param {object} step The step.
 * @returns The list of names as an array.
 */
export function getStepNames(step = {}) {
	const names = [];

	step.items?.forEach(item => {
		if (!item.name) return;

		names.push(item.name);
		if (item.refName) names.push(`${item.name}[*].${item.refName}`);
	});

	return names;
}

/**
 * A function to test a step's validity based on its items and the `errors` state. Will automatically extract the corresponding `name`s of its items and look for them in the errors.
 * 
 * @param {object} step The step.
 * @param {object} step The `errors` state.
 * @returns `true` if valid, `false` if invalid
 */
export function getStepValidity(step = {}, errors = null) {
	if (!errors) return true;

	const flattenedErrors = getFlattenedObject(errors);
	if (!flattenedErrors) return true;

	const noIndexflattenedErrors = Object.keys(flattenedErrors)?.map(key => key.replace(/\[\d+\]/g, '[*]')) || [];

	return !getStepNames(step).some(name => noIndexflattenedErrors.includes(name));
}