import { useRef } from "react";

/**
 * A hook which returns a value's history.
 * 
 * @param {any} value The value to store in history
 * @param {boolean} rewindPrevious Defines if adding the previous entry should rewind to it rather than adding it again
 * - `true`: [0, 1] + 0 -> [0]
 * - `false` (default): [0, 1] + 0 -> [0, 1, 0]
 * @param {boolean} addSame Defines if adding the current entry should add it again or not
 * - `true`: [0, 1] + 1 -> [0, 1, 1]
 * - `false` (default): [0, 1] + 1 -> [0, 1]
 * @returns 
 */
export function useValueHistory(value, rewindPrevious = false, addSame = false) {
	const ref = useRef([]);

	if (ref.current.length && ref.current[ref.current.length - 1] === value) {
		// New entry is equal to the current one
		if (addSame) ref.current.push(value);
	} else if ((ref.current.length || 0) >= 2 && ref.current[ref.current.length - 2] === value) {
		// New entry is equal to the previous one
		if (rewindPrevious) {
			ref.current.pop(); // [0, 1] + 0 -> [0]
		} else {
			ref.current.push(value);
		}
	} else {
		// Default case
		ref.current.push(value);
	}

	return ref.current;
}