/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { spacing } from "../../theme";
import { CardBox } from "./CardBox";

export function Menu({
	items = [],
	callerElement,
	openedWithKey = null,
	handleClose = () => { },
	...props
}) {
	const [focusedItemIndex, setFocusedItemIndex] = useState(null);

	// Handle different opening configurations
	useEffect(() => {
		setFocusedItemIndex(
			openedWithKey === null // Mouse click
				? null
				: (
					(openedWithKey === ' ' || openedWithKey === ' Enter')
					|| openedWithKey === 'ArrowDown'
				)
					? 0
					: openedWithKey === 'ArrowUp'
						? (items.length - 1)
						: null
		)
	}, [openedWithKey]);

	const wrapperRef = useRef();
	const itemsRef = useRef(new Map());

	useEffect(() => {
		function handleKeydown(event) {
			switch (event.key) {
				case 'Tab':
					if (event.shiftKey) {
						event.preventDefault();
						callerElement?.focus();
					}
					handleClose();

					break;
				case 'Escape':
					event.preventDefault();
					callerElement?.focus();
					handleClose();

					break;
				case 'ArrowDown':
					setFocusedItemIndex(prevState => {
						if (
							prevState === null // No item focused
							|| prevState >= (items.length - 1) // Last item focused -> Wrap
						) return 0;

						return prevState + 1;
					});

					event.preventDefault();

					break;
				case 'ArrowUp':
					setFocusedItemIndex(prevState => {
						if (
							prevState === null // No item focused
							|| prevState <= 0 // First item focused -> Wrap
						) return (items.length - 1);

						return prevState - 1;
					});

					event.preventDefault();

					break;
				default:
					break;
			}
		}

		function handleFocus(event) {
			const liElement = event.target?.closest('li');
			if (!liElement) return;

			if (!itemsRef.current) return;

			const newIndex = [...itemsRef.current].findIndex(([name, value]) => value === liElement);
			if (newIndex === -1) return;

			setFocusedItemIndex(newIndex);
		}

		wrapperRef.current?.addEventListener('keydown', handleKeydown, true);
		wrapperRef.current?.addEventListener('focus', handleFocus, true);

		return () => {
			wrapperRef.current?.removeEventListener('keydown', handleKeydown, true);
			wrapperRef.current?.removeEventListener('focus', handleFocus, true);
		}
	}, [wrapperRef]);

	useEffect(() => {
		itemsRef.current?.get(focusedItemIndex)?.querySelector('[role="menuitem"]')?.focus();
	}, [itemsRef, focusedItemIndex]);

	return <div
		ref={wrapperRef}
	>
		<CardBox
			component='ul'
			role='menu'
			css={{
				padding: spacing(4),
				'&>li+li': {
					marginTop: spacing(2),
				},
			}}
			{...props}
		>
			{
				items.map(({ label: itemLabel, component: itemComponent, props: itemProps }, itemIndex) =>
					<MenuItem
						key={itemIndex}
						ref={
							(node) => node
								? itemsRef.current?.set(itemIndex, node)
								: itemsRef.current?.delete(itemIndex)
						}
						label={itemLabel}
						component={itemComponent}
						props={itemProps}
						handleClose={handleClose}
					/>
				)
			}
		</CardBox>
	</div>
}

const MenuItem = forwardRef(function MenuItem({
	label = '',
	component = 'button',
	handleClose = () => { },
	props = null,
}, forwardedRef) {
	const { t } = useTranslation();

	function getHandleClick(clickHandler) {
		return function () {
			if (clickHandler) clickHandler();
			handleClose();
		}
	}

	return <li
		ref={forwardedRef}
		role='presentation'
	>
		{
			jsx(
				component,
				{
					...props,
					variant: 'underlinedHover',
					onClick: getHandleClick(props.onClick),
					role: 'menuitem',
					tabIndex: -1,
				},
				typeof label === 'function' ? label({ t }) : label
			)
		}
	</li>
});