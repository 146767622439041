export function getLocalStorageData(id, defaultData = null) {
	return new Promise((resolve, reject) => {
		const item = localStorage?.getItem(id);
		const data = item ? JSON.parse(item) : null;

		// Add delay because having an instant result makes `useIsFetching` go nuts on reload
		setTimeout(function () {
			resolve(data || defaultData);
		}, 100);
	});
}

export function setLocalStorageData({
	id = '',
	data = null,
	storageType = null, // `null`, `'array'`, `'uuid'`
	uuid = '', // For `'uuid'` `storageType`
}) {
	// Warning: Need to pass the entire entity data, not just its `data` property, e.g.:
	// data = { type: "negative_decision", data: { ...the entity data... }, }
	// NOT data = { ...the entity data... }

	switch (storageType) {
		case 'array': {
			const item = localStorage?.getItem(id);
			const storageData = item ? JSON.parse(item) : null;

			const storageDataArray = !storageData
				? []
				: !Array.isArray(storageData)
					? [storageData]
					: storageData;

			localStorage?.setItem(id, JSON.stringify([...storageDataArray, data]));

			return data;
		}
		case 'uuid': {
			const item = localStorage?.getItem(id);
			const storageData = item ? JSON.parse(item) : null;

			const storageDataArray = !storageData
				? []
				: !Array.isArray(storageData)
					? [storageData]
					: storageData;

			const itemIndex = storageDataArray.findIndex(i => i.uuid == uuid);
			if (itemIndex !== -1) {
				storageDataArray[itemIndex] = { ...data, uuid };
			} else {
				storageDataArray.push({ ...data, uuid });
			}

			localStorage?.setItem(id, JSON.stringify(storageDataArray));

			return { ...data, uuid };
		}
		default: {
			localStorage?.setItem(id, JSON.stringify(data));

			return data;
		}
	}
}