import { useEffect, useState } from "react";

export function useMediaQuery(query = '', defaultMatch = false) {
	const MEDIA_PREFIX = '@media ';
	const parsedQuery = query.startsWith(MEDIA_PREFIX)
		? query.slice(MEDIA_PREFIX.length)
		: query;

	const [match, setMatch] = useState(defaultMatch);

	useEffect(() => {
		const mediaQueryList = matchMedia(parsedQuery);

		function updateMatch() {
			setMatch(mediaQueryList.matches);
		}

		updateMatch();

		// TODO: Use `addEventListener('change', ...)` once support for older browsers is dropped
		mediaQueryList.addListener(updateMatch);
		return () => {
			mediaQueryList.removeListener(updateMatch);
		}
	}, [parsedQuery]);

	return match;
}