/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { animations, palette } from '../../theme';

export function Popper({
	open = false,
	anchorEl = null,
	placement = 'bottom',
	arrow = false,
	withPortal = false,
	phrasingContent = false,
	children,
	className = '', // /!\ Any passed style will be discarded (causes conflicts)
	...props
}) {
	const [popperElement, setPopperElement] = useState(null);
	const [arrowElement, setArrowElement] = useState(null);
	const { styles, attributes } = usePopper(anchorEl, popperElement, {
		placement: placement,
		modifiers: [
			...arrow ? [{ name: 'arrow', options: { element: arrowElement, }, },] : [],
			{ name: 'offset', options: { offset: [0, 8], }, },
		],
	});

	if (!open) return null;

	const output = jsx(
		phrasingContent ? 'span' : 'div',
		{
			ref: setPopperElement,
			css: {
				boxShadow: '0 4px 8px -2px rgb(0 0 0 / 10%)',
				animation: `${animations.fadeIn} 0.2s`,
				zIndex: 1,
				'&[data-popper-placement^="top"]>[data-popper-arrow]': {
					bottom: -4,
				},
				'&[data-popper-placement^="bottom"]>[data-popper-arrow]': {
					top: -4,
				},
				'&[data-popper-placement^="left"]>[data-popper-arrow]': {
					right: -4,
				},
				'&[data-popper-placement^="right"]>[data-popper-arrow]': {
					left: -4,
				},
			},
			style: styles.popper,
			...attributes.popper,
			...props,
		},
		<>
			{
				arrow &&
				jsx(
					phrasingContent ? 'span' : 'div',
					{
						ref: setArrowElement,
						'data-popper-arrow': true,
						css: {
							'&, &:before': {
								position: 'absolute',
								width: 8,
								height: 8,
								backgroundColor: palette.gray[300],
								transformOrigin: '50% 50%',
							},
							visibility: 'hidden',
							'&:before': {
								visibility: 'visible',
								content: '""',
								transform: 'rotate(45deg)',
							},
						},
						style: styles.arrow,
					}
				)
			}
			{children}
		</>
	);

	if (!withPortal) return output;

	return ReactDOM.createPortal(
		output,
		document.querySelector('#popper-root')
	);
}