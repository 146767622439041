import { useTranslation } from "react-i18next";
import { InteractiveCard } from "../common/InteractiveCard";
import { Button } from "../ui/Button";

export function SelectableCardButton({
	name = '',
	value = '',
	htmlValue = '',
	label = '',
	description = '',
	size = 'large',
	textAlign = 'center',
	handleChange: givenHandleChange = () => { },
}) {
	const { t } = useTranslation();

	const selected = value === htmlValue;

	function handleClick() {
		givenHandleChange([name, htmlValue]);
	}

	return <InteractiveCard
		component={Button}
		onClick={handleClick}
		variant=''
		aria-checked={selected}
		selected={selected}
		label={t(label)}
		description={t(description)}
		size={size}
		textAlign={textAlign}
	/>
}