import styled from "@emotion/styled";
import { palette, spacing } from "../../theme";
import { Typo } from "../ui/Typo";

export const Error = styled(
	function ({ children, ...props }) {
		return <Typo
			component='p'
			variant='small'
			{...props}
		>
			{children}
		</Typo>
	},
	{
		name: 'Field',
		slot: 'Error',
	}
)(({ theme, ...ownerState }) => ({
	marginTop: spacing(1),
	paddingLeft: spacing(2),
	paddingRight: spacing(2),
	color: palette.red[500],
}));