import { getAuthToken } from "./authToken";

export async function request({
	method = 'GET',
	url = '',
	data = null,
	needToken = false,
	extraHeaders = [], // Each item should be an array, e.g.: ['Authorization', 'Bearer {{BEARER}}']
}) {
	let token = null;

	if (needToken) {
		// Check if logged in
		token = getAuthToken();
		if (!token) {
			// Add delay because having an instant result makes useIsFetching go nuts on reload
			await new Promise((resolve, reject) => {
				setTimeout(function () {
					resolve(true);
				}, 100);
			});

			throw new Error('Not logged in');
		}
	}

	// Create headers
	const headers = new Headers();
	if (!!token) headers.append('Authorization', `Bearer ${token}`);
	// headers.append('Cache-Control', 'no-cache'); // Explicitly avoid caching // /!\ Disabled because setting a `Content-Type` makes the browser reject Zapier's requests
	// headers.append('Pragma', 'no-cache'); // Avoid caching in IE11 (https://learn.microsoft.com/en-US/troubleshoot/developer/browsers/connectivity-navigation/how-to-prevent-caching) // /!\ Disabled because setting a `Content-Type` makes the browser reject Zapier's requests
	// headers.append('Expires', '-1'); // Avoid caching in IE11 (https://learn.microsoft.com/en-US/troubleshoot/developer/browsers/connectivity-navigation/how-to-prevent-caching)
	extraHeaders.forEach(extraHeader => headers.append(extraHeader[0], extraHeader[1]));

	// Make request

	let body;
	let response;

	if (method === 'GET') {
		body = data ? `?${new URLSearchParams(data).toString()}` : '';

		response = await fetch(`${url}${body}`, {
			method,
			headers,
		});
	} else {
		body = JSON.stringify(data);

		response = await fetch(`${url}`, {
			method,
			headers,
			body,
		});
	}

	if (!response?.ok) throw new Error('Request failed');

	// Return response
	return await response.json();
}