import { getFormattedDate } from "../lib/dates";
import { getFlattenedObject } from "../lib/objects";
import { request } from "../lib/requests";
import { getFormattedNationalNumber } from "../lib/strings";
import { getFormattedAddress, insertEntities } from "./_advancedDecisionsHelpers";

export async function postEuthanasiaDecision(data, steps) {
	const newData = await insertEntities(data, steps);

	if (!newData) return false;

	const flattenedData = getFlattenedObject(newData);

	alterData(flattenedData);

	try {
		const response = await request({
			method: 'POST',
			url: 'https://hooks.zapier.com/hooks/catch/8288466/3h72wj6/',
			data: flattenedData,
		});

		return true;
	} catch (error) {
		console.error(error);

		return false;
	}
}

function alterData(data) {
	// Add missing keys
	[
		'data.first_name',
		'data.last_name',
		'data.national_number',
		'data.address.street',
		'data.address.number',
		'data.address.box',
		'data.address.postal_code',
		'data.address.town',
		'data.phone',
		'data.birth.date',
		'data.birth.address.town',
		'data.relationship',
	].forEach(key => {
		for (let i = 0; i < 2; i++) {
			data[`witnesses[${i}].${key}`] ??= '';
		}
		for (let i = 0; i < 3; i++) {
			data[`trusted_persons[${i}].${key}`] ??= '';
		}
	});

	// Add age
	[
		{
			birth_date: 'birth_date',
			age: 'age',
		},
	].forEach(keys => {
		const ageDifMs = Date.now() - new Date(data[keys.birth_date]).getTime();
		const ageDate = new Date(ageDifMs); // miliseconds from epoch
		data[keys.age] = `${Math.abs(ageDate.getUTCFullYear() - 1970)}`;
	});

	// Update dates
	[
		'previous_date',
		'witnesses[0].data.birth.date',
		'witnesses[1].data.birth.date',
		'trusted_persons[0].data.birth.date',
		'trusted_persons[1].data.birth.date',
		'trusted_persons[2].data.birth.date',
		'birth_date',
	].forEach(key => {
		if (!data[key]) return;

		data[key] = getFormattedDate(data[key]);
	});

	// Update national numbers
	[
		'witnesses[0].data.national_number',
		'witnesses[1].data.national_number',
		'trusted_persons[0].data.national_number',
		'trusted_persons[1].data.national_number',
		'trusted_persons[2].data.national_number',
	].forEach(key => {
		if (!data[key]) return;

		data[key] = getFormattedNationalNumber(data[key]);
	});

	// Add full names
	[
		{
			first_name: 'witnesses[0].data.first_name',
			last_name: 'witnesses[0].data.last_name',
			full_name: 'witnesses[0].data.full_name',
		},
		{
			first_name: 'witnesses[1].data.first_name',
			last_name: 'witnesses[1].data.last_name',
			full_name: 'witnesses[1].data.full_name',
		},
		{
			first_name: 'trusted_persons[0].data.first_name',
			last_name: 'trusted_persons[0].data.last_name',
			full_name: 'trusted_persons[0].data.full_name',
		},
		{
			first_name: 'trusted_persons[1].data.first_name',
			last_name: 'trusted_persons[1].data.last_name',
			full_name: 'trusted_persons[1].data.full_name',
		},
		{
			first_name: 'trusted_persons[2].data.first_name',
			last_name: 'trusted_persons[2].data.last_name',
			full_name: 'trusted_persons[2].data.full_name',
		},
	].forEach(keys => {
		data[keys.full_name] = `${data[keys.last_name]} ${data[keys.first_name]}`;
	});

	// Add full address
	[
		{
			street: 'witnesses[0].data.address.street',
			number: 'witnesses[0].data.address.number',
			box: 'witnesses[0].data.address.box',
			postal_code: 'witnesses[0].data.address.postal_code',
			town: 'witnesses[0].data.address.town',
			full_address: 'witnesses[0].data.full_address',
		},
		{
			street: 'witnesses[1].data.address.street',
			number: 'witnesses[1].data.address.number',
			box: 'witnesses[1].data.address.box',
			postal_code: 'witnesses[1].data.address.postal_code',
			town: 'witnesses[1].data.address.town',
			full_address: 'witnesses[1].data.full_address',
		},
		{
			street: 'trusted_persons[0].data.address.street',
			number: 'trusted_persons[0].data.address.number',
			box: 'trusted_persons[0].data.address.box',
			postal_code: 'trusted_persons[0].data.address.postal_code',
			town: 'trusted_persons[0].data.address.town',
			full_address: 'trusted_persons[0].data.full_address',
		},
		{
			street: 'trusted_persons[1].data.address.street',
			number: 'trusted_persons[1].data.address.number',
			box: 'trusted_persons[1].data.address.box',
			postal_code: 'trusted_persons[1].data.address.postal_code',
			town: 'trusted_persons[1].data.address.town',
			full_address: 'trusted_persons[1].data.full_address',
		},
		{
			street: 'trusted_persons[2].data.address.street',
			number: 'trusted_persons[2].data.address.number',
			box: 'trusted_persons[2].data.address.box',
			postal_code: 'trusted_persons[2].data.address.postal_code',
			town: 'trusted_persons[2].data.address.town',
			full_address: 'trusted_persons[2].data.full_address',
		},
	].forEach(keys => {
		data[keys.full_address] = getFormattedAddress({
			street: data[keys.street],
			number: data[keys.number],
			box: data[keys.box],
			postal_code: data[keys.postal_code],
			town: data[keys.town],
		});
	});

	// Rewrite previous date for conditional display
	data.previous_date__renew = data.decision_type === 'renew' ? data.previous_date : '';
	data.previous_date__change = data.decision_type === 'change' ? data.previous_date : '';
	data.previous_date__withdrawal = data.decision_type === 'withdrawal' ? data.previous_date : '';

	// Empty trusted persons' fields they're not supposed to exist
	if (data.has_trusted_person !== 'yes') {
		Object.keys(data).forEach(key => {
			if (key.startsWith('trusted_persons[')) data[key] = '';
		});
	}
}
