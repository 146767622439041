/** @jsxImportSource @emotion/react */

import { ReactComponent as ExpandMore } from "@material-design-icons/svg/round/expand_more.svg";
import { spacing } from '../../theme';
import { Button } from './Button';
import { Svg } from './Svg';

export function CollapseButton({
	id = '',
	rid = '',
	open = false,
	handleClick = () => { },
	children,
	...props
}) {
	return <Button
		id={id}
		aria-controls={open ? rid : undefined}
		aria-expanded={open}
		onClick={handleClick}
		variant='underlinedHover'
		endIcon={
			<Svg svg={ExpandMore}
				aria-hidden={true}
			/>
		}
		endIconProps={{
			css: {
				'& svg': {
					marginRight: `-${spacing(1)}`,
					marginLeft: spacing(2),
					height: 24,
					width: 24,
					transitionProperty: 'transform',
					transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', // transition
					transitionDuration: '150ms', // duration-500
					...open && { transform: 'rotate(180deg)', },
				},
			}
		}}
		{...props}
	>
		{children}
	</Button>
}