/** @jsxImportSource @emotion/react */

import { mq } from "../../theme";
import { Container } from "./Container";

/**
 * A UI component to use when the default container width is too large.
 * 
 * @param {object} props Object containing the props:
 * - `props.children` The element can contains children.
 * - `...props` Any other props will be passed to the inner `div`.
 * @returns {any} The component.
 */
export function NarrowContainer({
	children,
	...props
}) {
	return <Container>
		<div
			css={{
				maxWidth: '448px', // max-w-md
				[mq('md')]: {
					maxWidth: '512px', // max-w-lg
				},
				marginLeft: 'auto',
				marginRight: 'auto',
			}}
			{...props}
		>
			{
				children
			}
		</div>
	</Container>
}