import { createContext, useState } from "react";

const defaultContext = {
	tutorialsOpen: [],
	toggleTutorial: () => { },
};

export const TutorialsContext = createContext(defaultContext);

export function useTutorialsContext() {
	const [tutorialsOpen, setTutorialsOpen] = useState(['stepper_menu']);

	function openTutorial(key) {
		setTutorialsOpen(prevState => prevState.includes(key) ? [...prevState] : [...prevState, key]);
	}
	function closeTutorial(key) {
		setTutorialsOpen(prevState => prevState.filter(i => i !== key));
	}

	return {
		tutorialsOpen,
		openTutorial,
		closeTutorial,
	};
}