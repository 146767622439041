import App from "./App";
import { AuthTokenContext, useAuthTokenContext } from "./contexts/AuthToken";
import { ErrorContext, useErrorContext } from "./contexts/Error";
import { TutorialsContext, useTutorialsContext } from "./contexts/Tutorials";

export function AppWrapper() {
	const authTokenContext = useAuthTokenContext();
	const tutorialsContext = useTutorialsContext();
	const errorContext = useErrorContext();

	return <>
		<AuthTokenContext.Provider value={authTokenContext}>
			<ErrorContext.Provider value={errorContext}>
				<TutorialsContext.Provider value={tutorialsContext}>
					<App />
				</TutorialsContext.Provider>
			</ErrorContext.Provider>
		</AuthTokenContext.Provider>
	</>
}