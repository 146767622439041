import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from 'uuid';
import { getAuthToken } from "../lib/authToken";
import { getEntity } from "../lib/entities";
import { getLocalStorageData, setLocalStorageData } from "../lib/localStorage";

export function useEntity(entityName) {
	const queryClient = useQueryClient();

	const {
		queryKey,
		// fetcher, // No need for remote for now
		// updater, // No need for remote for now
		defaultData,
		dataSchema,
		modifyEntity,
	} = getEntity(entityName);

	// const { isLoading, data } = useQuery(queryKey, () => fetcher());
	const { isLoading, data } = useQuery(queryKey,
		/* No need for remote for now
		() => !!getAuthToken()
			? fetcher()
			: getLocalStorageData(queryKey, []) 
		*/
		() => getLocalStorageData(queryKey, [])
	);

	const updateMutation = useMutation(({ uuid = '', data = defaultData }) => {
		/* No need for remote for now
		return !!getAuthToken()
			? updater({ uuid, data })
			: setLocalStorageData({ id: queryKey, data: data, storageType: 'uuid', uuid: uuid || uuidv4(), });
		*/
		return setLocalStorageData({ id: queryKey, data: data, storageType: 'uuid', uuid: uuid || uuidv4(), });
	}, {
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
		},
	});
	async function save(uuid, data) {
		const newData = modifyEntity(data);

		return await updateMutation.mutateAsync({ uuid, data: newData });
	}

	return {
		isLoading,
		data,
		defaultData,
		dataSchema,
		save,
	}
}