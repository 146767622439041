/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { CardBox } from "../components/ui/CardBox";
import { LinkButton } from "../components/ui/LinkButton";
import { NarrowContainer } from "../components/ui/NarrowContainer";
import { TransParagraphs } from "../components/ui/Trans";
import { Typo } from "../components/ui/Typo";
import { animations, spacing } from "../theme";

export function SuccessionOnboardingInvalidUrl() {
	const { t } = useTranslation();

	return <NarrowContainer
		css={{
			animation: `${animations.fadeIn} 0.5s, ${animations.slideInRight} 0.5s`,
		}}
	>
		<div
			css={{
				marginBottom: spacing(8),
				marginLeft: spacing(4),
				marginRight: spacing(4),
			}}
		>
			<Typo
				component='h1'
				variant='marketing-title'
				css={{
					textAlign: 'center',
					margin: 'auto',
				}}
			>
				{t('successionOnboardingInvalidUrl.title')}
			</Typo>
		</div>

		<CardBox>
			<TransParagraphs
				i18nKey={'successionOnboardingInvalidUrl.content'}
				t={t}
				useMarkdown={true}
			/>
		</CardBox>
	</NarrowContainer>
}
