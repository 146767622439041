export function getFlattenedObject(object = {}, flattenNonObjectsArrays = false) {
	const pathsList = {};

	Object.keys(object).forEach(propertyName => {
		const propertyValue = object[propertyName];

		if (Array.isArray(propertyValue) && propertyValue.every(item => typeof item !== 'object' || item === null)) {
			// Array of non-objects
			if (flattenNonObjectsArrays) {
				const subPathsList = {};
				for (let i = 0; i < propertyValue.length; i++) {
					subPathsList[i] = propertyValue[i];
				}
				pathsList[propertyName] = subPathsList;
			} else {
				pathsList[propertyName] = propertyValue;
			}
		} else if (typeof propertyValue === 'object' && propertyValue !== null) {
			// Object or array
			const subPathsList = getFlattenedObject(propertyValue, flattenNonObjectsArrays);

			Object.keys(subPathsList).forEach(subPropertyName => {
				const subPropertyValue = subPathsList[subPropertyName];

				if (Array.isArray(propertyValue)) {
					// Need two steps to write the closing brackets properly
					const splittedSubPropertyName = subPropertyName.split(/((?:\.|\[).*)/);
					pathsList[`${propertyName}[${splittedSubPropertyName[0]}]${splittedSubPropertyName[1]}`] = subPropertyValue;
				} else {
					pathsList[`${propertyName}.${subPropertyName}`] = subPropertyValue;
				}
			});
		} else {
			pathsList[propertyName] = propertyValue;
		}
	});

	return pathsList;
}