import { getFormattedDate } from "../lib/dates";
import { getFlattenedObject } from "../lib/objects";
import { request } from "../lib/requests";
import { insertEntities } from "./_advancedDecisionsHelpers";

export async function postFuneralDecision(data, steps) {
	const newData = await insertEntities(data, steps);

	if (!newData) return false;

	const flattenedData = getFlattenedObject(newData);

	alterData(flattenedData);

	try {
		const response = await request({
			method: 'POST',
			url: 'https://hooks.zapier.com/hooks/catch/8288466/3hnob4c/',
			data: flattenedData,
		});

		return true;
	} catch (error) {
		console.error(error);

		return false;
	}
}

function alterData(data) {
	// Add missing keys
	[
		'data.company.name',
		'data.contract_number',
		'data.signature.date',
	].forEach(key => {
		for (let i = 0; i < 1; i++) {
			data[`funeral_contracts[${i}].${key}`] ??= '';
		}
	});

	// Add age
	[
		{
			birth_date: 'birth_date',
			age: 'age',
		},
	].forEach(keys => {
		const ageDifMs = Date.now() - new Date(data[keys.birth_date]).getTime();
		const ageDate = new Date(ageDifMs); // miliseconds from epoch
		data[keys.age] = `${Math.abs(ageDate.getUTCFullYear() - 1970)}`;
	});

	// Update dates
	[
		'funeral_contracts[0].data.signature.date',
		'birth_date',
	].forEach(key => {
		if (!data[key]) return;

		data[key] = getFormattedDate(data[key]);
	});

	// Empty funeral contracts fields if they're not supposed to exist
	if (data.does_refuse_treatment !== 'yes') {
		Object.keys(data).forEach(key => {
			if (key.startsWith('funeral_contracts[')) data[key] = '';
		});
	}

	// Rewrite previous town for conditional display
	data.previous_town__change = data.decision_type === 'change' ? data.previous_town : '';
	data.previous_town__withdrawal = data.decision_type === 'withdrawal' ? data.previous_town : '';

	// Rewrite funeral type & cremation type
	if (data['funeral.type'] === 'burial') {
		data['funeral.full_type'] = 'burial';
	} else if (data['funeral.type'] === 'cremation') {
		data['funeral.full_type'] = `cremation_${data['funeral.cremation.type']}`;
	}

	// Rewrite funeral other place for conditional display
	data.funeral_other_place__scattering_other_place = data['funeral.full_type'] === 'cremation_scattering_other_place' ? data['funeral.other_place'] : '';
	data.funeral_other_place__burial_other_place = data['funeral.full_type'] === 'cremation_burial_other_place' ? data['funeral.other_place'] : '';
	data.funeral_other_place__conservation_other_place = data['funeral.full_type'] === 'cremation_conservation_other_place' ? data['funeral.other_place'] : '';

	// // Separate other will in multiple lines
	// data.other_will__0 = data.other_will.replaceAll('\n', ' ');
	// data.other_will__1 = '';
	// data.other_will__2 = '';
	// if (data.other_will__0.length >= 120) {
	// 	const index = data.other_will__0.lastIndexOf(' ', 120);
	// 	if (index !== -1) {
	// 		data.other_will__1 = data.other_will__0.substring(index + 1);
	// 		data.other_will__0 = data.other_will__0.substring(0, index + 1);

	// 		if (data.other_will__1.length >= 120) {
	// 			const index = data.other_will__1.lastIndexOf(' ', 120);
	// 			if (index !== -1) {
	// 				data.other_will__2 = data.other_will__1.substring(index + 1);
	// 				data.other_will__1 = data.other_will__1.substring(0, index + 1);

	// 				data.other_will__2 = data.other_will__2.substring(0, 120);
	// 			}
	// 		}
	// 	}
	// }

	// Empty everything if this is a withdrawal
	if (data.decision_type === 'withdrawal') {
		Object.keys(data).forEach(key => {
			if (!['decision_type', 'previous_town__withdrawal', 'email'].includes(key)) data[key] = '';
		});
	}
}
