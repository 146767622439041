export function getFormattedNationalNumber(value = '') {
	if (!value) return '';

	const part1 = value.substring(0, 2);
	const part2 = value.substring(2, 4);
	const part3 = value.substring(4, 6);
	const part4 = value.substring(6, 9);
	const part5 = value.substring(9, 11);
	return `${part1}${part2
		? `.${part2}${part3
			? `.${part3}${part4
				? `-${part4}${part5
					? `.${part5}`
					: ''}`
				: ''}`
			: ''}`
		: ''}`;
}

export function getPhoneNumberHref(value = '') {
	return `tel:${value.replace(/[^\d+]/g, '')}`;
}