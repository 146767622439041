/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { postNegativeDecision } from "../../../../../api/negativeDecision";
import { updateService } from "../../../../../api/service";
import { Tutorial } from "../../../../../components/common/Tutorial";
import { PeopleDataPresentation } from "../../../../../components/dataPresentation/People";
import { ContainerWithSidebar } from "../../../../../components/display/ContainerWithSidebar";
import { Stepper } from "../../../../../components/stepper/Stepper";
import { StepperMenu } from "../../../../../components/stepper/StepperMenu";
import { Button } from "../../../../../components/ui/Button";
import { CardBox } from "../../../../../components/ui/CardBox";
import { Container } from "../../../../../components/ui/Container";
import { ScrollDisplay } from "../../../../../components/ui/ScrollDisplay";
import { TransListItems, TransParagraphs } from "../../../../../components/ui/Trans";
import { Typo } from "../../../../../components/ui/Typo";
import { PEOPLE__DATA as ENTITY__PEOPLE__DATA, PEOPLE__DATA__SCHEMA as ENTITY__PEOPLE__DATA__SCHEMA } from "../../../../../data/entities/people";
import { NEGATIVE_DECISION__DATA as SERVICE__NEGATIVE_DECISION__DATA, NEGATIVE_DECISION__DATA__SCHEMA as SERVICE__NEGATIVE_DECISION__DATA__SCHEMA } from "../../../../../data/services/negativeDecision";
import { useConditionValidatedOnce } from "../../../../../hooks/conditionValidatedOnce";
import { useFormValues } from "../../../../../hooks/form";
import { useMediaQuery } from "../../../../../hooks/mediaQuery";
import { isFalse } from "../../../../../lib/conditions";
import { getLocalStorageData, setLocalStorageData } from "../../../../../lib/localStorage";
import { parseCustomMarkdown } from "../../../../../lib/parser";
import { mq, palette, spacing } from "../../../../../theme";

const defaultData = SERVICE__NEGATIVE_DECISION__DATA;
const dataSchema = SERVICE__NEGATIVE_DECISION__DATA__SCHEMA

const peopleDefaultData = ENTITY__PEOPLE__DATA;
const peopleDataSchema = ENTITY__PEOPLE__DATA__SCHEMA;

const steps = [
	{
		route: 'intro',
		label: ({ t }) => t('negativeDecision.steps.intro.label'),
		title: ({ t }) => t('negativeDecision.steps.intro.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'negativeDecision.steps.intro.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'needs',
		label: ({ t }) => t('negativeDecision.steps.needs.label'),
		title: ({ t }) => t('negativeDecision.steps.needs.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('negativeDecision.steps.needs.list.title')}</Typo>
						<Typo
							component='ul'
							variant='regular'
							css={{
								listStyle: 'disc',
								paddingLeft: spacing(6),
								marginBottom: spacing(4),
								'& li+li': {
									marginTop: spacing(1),
								},
							}}
						>
							<TransListItems
								i18nKey={'negativeDecision.steps.needs.list.items'}
								t={t}
								useMarkdown={true}
							/>
						</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{ marginBottom: spacing(4), fontStyle: 'italic' }}
						>{t('negativeDecision.steps.needs.list.note')}</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('negativeDecision.steps.needs.missingInfo.title')}</Typo>
						<TransParagraphs
							i18nKey={'negativeDecision.steps.needs.missingInfo.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'context',
		label: ({ t }) => t('negativeDecision.steps.context.label'),
		title: ({ t }) => t('negativeDecision.steps.context.title'),
		items: [
			{
				type: 'oneOf',
				name: 'context',
				props: {
					style: 'row',
					options: [
						{ value: 'any', label: 'negativeDecision.steps.context.form.any' },
						{ value: 'specific', label: 'negativeDecision.steps.context.form.specific', description: 'negativeDecision.steps.context.form.specificDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.context === 'any' ? t('negativeDecision.steps.context.preview.any')
			: data.context === 'specific' ? t('negativeDecision.steps.context.preview.specific')
				: '',
	},
	{
		depth: 1,
		route: 'context_specific',
		label: ({ t }) => t('negativeDecision.steps.contextSpecific.label'),
		title: ({ t }) => t('negativeDecision.steps.contextSpecific.title'),
		items: [
			{
				type: 'field',
				name: 'context_specific',
				props: {
					type: 'text',
					label: 'negativeDecision.steps.contextSpecific.form.label',
					sublabel: 'negativeDecision.steps.contextSpecific.form.sublabel',
				},
			},
		],
		preview: ({ data, t }) => data.context_specific,
		condition: (data) => data.context === 'specific',
	},
	{
		route: 'does_refuse_treatment',
		label: ({ t }) => t('negativeDecision.steps.doesRefuseTreatment.label'),
		title: ({ t }) => t('negativeDecision.steps.doesRefuseTreatment.title'),
		subtitle: ({ t }) => t('negativeDecision.steps.doesRefuseTreatment.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'does_refuse_treatment',
				props: {
					style: 'row',
					options: [
						{ value: 'yes', label: 'negativeDecision.steps.doesRefuseTreatment.form.yes', description: 'negativeDecision.steps.doesRefuseTreatment.form.yesDescription' },
						{ value: 'no', label: 'negativeDecision.steps.doesRefuseTreatment.form.no', description: 'negativeDecision.steps.doesRefuseTreatment.form.noDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.does_refuse_treatment === 'yes' ? t('global.yes') : data.does_refuse_treatment === 'no' ? t('global.no') : '',
	},
	{
		depth: 1,
		route: 'refused_treatments',
		label: ({ t }) => t('negativeDecision.steps.refusedTreatments.label'),
		title: ({ t }) => t('negativeDecision.steps.refusedTreatments.title'),
		subtitle: ({ t }) => parseCustomMarkdown(t('negativeDecision.steps.refusedTreatments.subtitle')),
		items: [
			{
				type: 'anyOf',
				name: 'refused_treatments',
				props: {
					options: [
						{ value: 'antibiotic', label: 'negativeDecision.steps.refusedTreatments.form.antibiotic', },
						{ value: 'artificial_hydration_nutrition', label: 'negativeDecision.steps.refusedTreatments.form.artificialHydrationNutrition', },
						{ value: 'chemotherapy', label: 'negativeDecision.steps.refusedTreatments.form.chemotherapy', },
						{ value: 'radiation', label: 'negativeDecision.steps.refusedTreatments.form.radiation', },
						{ value: 'surgery', label: 'negativeDecision.steps.refusedTreatments.form.surgery', },
						{ value: 'artificial_respiration', label: 'negativeDecision.steps.refusedTreatments.form.artificialRespiration', },
						{ value: 'renal_dialysis_hemofiltration', label: 'negativeDecision.steps.refusedTreatments.form.renalDialysisHemofiltration', },
						{ value: 'intensive_care', label: 'negativeDecision.steps.refusedTreatments.form.intensiveCare', },
						{ value: 'hospitalization', label: 'negativeDecision.steps.refusedTreatments.form.hospitalization', },
						{ value: 'blood_product_transfusion', label: 'negativeDecision.steps.refusedTreatments.form.bloodProductTransfusion', },
						{ value: 'other', label: 'negativeDecision.steps.refusedTreatments.form.other', },
					],
				},
			},
		],
		preview: ({ data, t }) => data.refused_treatments.length ? t('negativeDecision.steps.refusedTreatments.preview', { count: data.refused_treatments.length }) : '',
		condition: (data) => data.does_refuse_treatment === 'yes',
	},
	{
		depth: 1,
		route: 'refused_treatments_other',
		label: ({ t }) => t('negativeDecision.steps.refusedTreatmentsOther.label'),
		title: ({ t }) => t('negativeDecision.steps.refusedTreatmentsOther.title'),
		items: [
			{
				type: 'field',
				name: 'refused_treatments_other',
				props: {
					type: 'text',
					label: 'negativeDecision.steps.refusedTreatmentsOther.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.refused_treatments_other,
		condition: (data) => data.does_refuse_treatment === 'yes' && data.refused_treatments.includes('other'),
	},
	{
		route: 'preserve_organ_donation',
		label: ({ t }) => t('negativeDecision.steps.preserveOrganDonation.label'),
		title: ({ t }) => t('negativeDecision.steps.preserveOrganDonation.title'),
		subtitle: ({ t }) => parseCustomMarkdown(t('negativeDecision.steps.preserveOrganDonation.subtitle')),
		items: [
			{
				type: 'oneOf',
				name: 'preserve_organ_donation',
				props: {
					style: 'row',
					options: [
						{ value: 'yes', label: 'global.yes' },
						{ value: 'no', label: 'global.no' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.preserve_organ_donation === 'yes' ? t('global.yes')
			: data.preserve_organ_donation === 'no' ? t('global.no')
				: '',
	},
	{
		route: 'has_representative',
		label: ({ t }) => t('negativeDecision.steps.hasRepresentative.label'),
		title: ({ t }) => t('negativeDecision.steps.hasRepresentative.title'),
		subtitle: ({ t }) => parseCustomMarkdown(t('negativeDecision.steps.hasRepresentative.subtitle')),
		items: [
			{
				type: 'oneOf',
				name: 'has_representative',
				props: {
					style: 'row',
					options: [
						{ value: 'yes', label: 'global.yes' },
						{ value: 'no', label: 'global.no' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_representative === 'yes' ? t('global.yes')
			: data.has_representative === 'no' ? t('global.no')
				: '',
	},
	{
		depth: 1,
		route: 'representatives',
		label: ({ t }) => t('negativeDecision.steps.representatives.label'),
		title: ({ t }) => t('negativeDecision.steps.representatives.title'),
		items: [
			{
				type: 'entitiesArray',
				name: 'representatives',
				refName: 'people_uuid',
				props: {
					maxLength: 3,
					entityName: 'people',
					emptyText: ({ t }) => t('negativeDecision.steps.representatives.form.emptyList'),
					addButtonText: ({ t }) => t('negativeDecision.steps.representatives.form.add'),
					defaultValue: peopleDefaultData,
					itemPreviewContent: ({ item, t }) => <PeopleDataPresentation
						data={item}
						partsToShow={[
							'firstNameAndLastName',
							'nationalNumber',
							'address',
							'phone',
							'birthPlaceAndDate',
							'relationship',
						]}
					/>,
					fields: [
						{
							type: 'text',
							label: 'form.firstName',
							name: 'first_name',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.lastName',
							name: 'last_name',
							addRequired: true,
						},
						{
							type: 'national_number',
							label: 'form.nationalNumber',
							sublabel: 'form.nationalNumberSublabel',
							name: 'national_number',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.address.street',
							name: 'address.street',
							addRequired: true,
							gridSizes: {
								lg: 6,
							},
						},
						{
							type: 'text',
							label: 'form.address.number',
							name: 'address.number',
							addRequired: true,
							gridSizes: {
								sm: 6,
								lg: 3,
							},
						},
						{
							type: 'text',
							label: 'form.address.box',
							name: 'address.box',
							gridSizes: {
								sm: 6,
								lg: 3,
							},
						},
						{
							type: 'text',
							label: 'form.address.postalCode',
							name: 'address.postal_code',
							addRequired: true,
							gridSizes: {
								md: 6,
							},
						},
						{
							type: 'text',
							label: 'form.address.town',
							name: 'address.town',
							addRequired: true,
							gridSizes: {
								md: 6,
							},
						},
						{
							type: 'text',
							label: 'form.phone',
							name: 'phone',
							addRequired: true,
						},
						{
							type: 'multipart_date',
							label: 'form.birthDate',
							name: 'birth.date',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.birthPlace',
							name: 'birth.address.town',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.relationship',
							sublabel: 'form.relationshipSublabel',
							name: 'relationship',
						},
					],
				},
			},
		],
		preview: ({ data, t }) => t('negativeDecision.steps.representatives.preview', { count: data.representatives?.length || 0 }),
		condition: (data) => data.has_representative === 'yes',
	},
	{
		route: 'contact_details',
		label: ({ t }) => t('negativeDecision.steps.contactDetails.label'),
		title: ({ t }) => t('negativeDecision.steps.contactDetails.title'),
		items: [
			{
				type: 'field',
				name: 'email',
				props: {
					type: 'email',
					label: 'form.email',
				},
			},
			{
				type: 'field',
				name: 'first_name',
				props: {
					label: 'form.firstName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'last_name',
				props: {
					label: 'form.lastName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'birth_date',
				props: {
					type: 'date',
					label: 'form.birthDate',
				},
				gridSizes: {
					sm: 6,
				},
			},
		],
		preview: ({ data, t }) => data.email,
	},
];

export function Negative() {
	const { t } = useTranslation();

	// Store
	const { isLoading: peoplesLoading, data: peoples = [] } = useQuery('peoples',
		/* No need for remote for now
		() => !!getAuthToken()
			? fetchPeoples()
			: getLocalStorageData('peoples', [])
		*/
		() => getLocalStorageData('peoples', [])
	);
	const peoplesLoaded = useConditionValidatedOnce(peoplesLoading, isFalse);

	// const { isLoading: serviceNegativeDecisionLoading, data: serviceNegativeDecision } = useQuery('serviceNegativeDecision', () => fetchService({ type: 'negative_decision' }));
	const { isLoading: serviceNegativeDecisionLoading, data: serviceNegativeDecision } = useQuery('serviceNegativeDecision',
		/* No need for remote for now
		() => !!getAuthToken()
			? fetchService({ type: 'negative_decision' })
			: getLocalStorageData('negative_decision')
		*/
		() => getLocalStorageData('negative_decision')
	);
	const serviceNegativeDecisionLoaded = useConditionValidatedOnce(serviceNegativeDecisionLoading, isFalse);

	const serviceNegativeDecisionData = (serviceNegativeDecisionLoaded && peoplesLoaded) ? (serviceNegativeDecision?.data || null) : undefined;

	const queryClient = useQueryClient();

	const updateMutation = useMutation((data = defaultData) => {
		return updateService({ type: 'negative_decision', data });
	}, {
		onSettled: () => {
			queryClient.invalidateQueries('serviceNegativeDecision');
		},
	});
	function save(data) {
		// if (!!getAuthToken()) return updateMutation.mutateAsync(data); // No need for remote for now

		setLocalStorageData({ id: 'negative_decision', data: { data: data, }, });
	}

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	} = useFormValues({
		defaultData,
		dataSchema,
		context: { peoples: peoples },
	});

	const postMutation = useMutation(({ data, steps }) => {
		return postNegativeDecision(data, steps);
	});

	async function sendData() {
		const response = await postMutation.mutateAsync({ data, steps });

		setLocalStorageData({ id: 'advance_decisions_completed', data: 'negative', storageType: 'array' });

		// if (!!response) localStorage.removeItem('negative_decision');

		return response;
	}

	const isMedium = useMediaQuery(mq('md'));

	return <>
		<ContainerWithSidebar
			sidebarFitDesktopScreen={true}
			showSidebarOnMobile={true}
			sidebar={
				<>
					<Tutorial
						id='stepper_menu'
						placement={isMedium ? 'right-start' : 'bottom'}
						description={t('tutorials.stepperMenu')}
					>
						<div>
							<Typo
								component='p'
								variant='title-2'
								css={{ marginBottom: spacing(2), }}
							>{t('stepper.menu.title')}</Typo>
						</div>
					</Tutorial>
					<ScrollDisplay
						component={CardBox}
						wrapperCss={{
							padding: 0,
						}}
						css={{
							padding: spacing(4),
						}}
					>
						<StepperMenu
							steps={steps}
							data={data}
							errors={errors}
						/>
					</ScrollDisplay>
				</>
			}
		>
			<div
				css={{
					paddingBottom: spacing(12),
				}}
			>
				<Stepper
					save={save}
					queryKey={'negative_decision'}
					storeData={serviceNegativeDecisionData}
					steps={steps}
					data={data}
					setData={setData}
					defaultData={defaultData}
					errors={errors}
					updateFormErrors={updateErrors}
					getFormErrors={getErrors}
					getFieldValues={getFieldValues}
					allowInvalid={false}
					validEndRedirect={'/advance-decisions?success=negative'}
					endCallback={sendData}
				/>
			</div>
		</ContainerWithSidebar>
		<Container>
			<div
				css={{
					marginBottom: spacing(12),
					borderBottom: '1px solid',
					borderColor: palette.gray[300],
				}}
			/>
		</Container>
	</>
}