import { useLocation } from "react-router-dom";

/**
 * A hook to return steps only if their condition is valid. Can also pass a max index.
 * 
 * @param {array} steps The steps.
 * @param {object} data The current `data` state, will be used to validate the steps' conditions.
 * @param {number} maxStepIndex The max index after which steps should be filtered out (optional).
 * @returns The filtered array.
 */
export function useFilteredSteps(steps = [], data = {}, maxStepIndex = null,) {
	return steps.filter((step, stepIndex) => (
		(maxStepIndex === null || stepIndex <= maxStepIndex)
		&& (!step.condition || step.condition(data))
	));
}

/**
 * A hook to return steps as a nested array based on their `depth` property.
 * 
 * @param {array} steps The steps.
 * @returns The nested array, each entry is structured like: `{ object: the step content, substeps: [...the nested steps, with the same format], }`.
 */
export function useNestedSteps(steps = []) {
	const newSteps = [];

	steps.forEach(step => {
		if (step.depth) {
			let parentStep = newSteps;
			for (let i = 0; i < step.depth; i++) {
				parentStep = newSteps[newSteps.length - 1];
			}
			parentStep.substeps.push({
				object: step,
				substeps: [],
			});
		} else {
			newSteps.push({
				object: step,
				substeps: [],
			});
		}
	});

	return newSteps;
}

// export function useFlattenedSteps(nestedSteps) {
// 	const flattenedSteps = [];

// 	function pushStep(step) {
// 		flattenedSteps.push(step);
// 		step.substeps?.forEach(substep => {
// 			pushStep(substep);
// 		});
// 	}
// 	nestedSteps.forEach(step => {
// 		pushStep(step);
// 	});

// 	return flattenedSteps;
// }

export function useCurrentStep(steps) {
	const location = useLocation();

	const pathArray = location.pathname.split('/').filter(i => !!i);
	const currentRoute = pathArray[pathArray.length - 1];
	const currentIndex = currentRoute === 'authentication' ? steps.length : steps.findIndex(step => step.route === currentRoute);

	return ({
		currentStepIndex: currentIndex !== -1 ? currentIndex : 0,
		currentStepRoute: currentRoute || '',
	});
}