/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getDateArray } from "../../lib/dates";
import { getFormattedNationalNumber } from "../../lib/strings";
import { mq, palette, spacing, typography } from "../../theme";
import { FlexGrid } from "../ui/FlexGrid";
import { LinkButton } from "../ui/LinkButton";
import { Typo } from "../ui/Typo";
import { Error } from "./Error";

const StartIcon = styled(
	'div',
	{
		name: 'Field',
		slot: 'StartIcon',
	}
)(({ theme, ...ownerState }) => ({
	position: 'absolute',
	left: 0,
	top: '50%',
	transform: 'translateY(-50%)',
}));

const EndIcon = styled(
	'div',
	{
		name: 'Field',
		slot: 'EndIcon',
	}
)(({ theme, ...ownerState }) => ({
	position: 'absolute',
	right: 0,
	top: '50%',
	transform: 'translateY(-50%)',
}));

const InputStyle = ({ theme, ...ownerState }) => ({
	boxSizing: 'border-box',
	position: 'relative',
	display: 'block',
	height: 'auto',
	width: '100%',
	resize: 'none',
	appearance: 'none',
	...typography['regular'],
	lineHeight: 1.25,
	color: palette.blue[400],
	border: '1px solid',
	borderColor: palette.gray[300],
	borderRadius: '4px',
	backgroundColor: 'white',
	...(ownerState.error && {
		backgroundColor: palette.red[200],
		'& option': {
			backgroundColor: 'white',
		},
	}),
	paddingTop: spacing(4),
	paddingBottom: spacing(4),
	paddingLeft: spacing(4),
	paddingRight: spacing(4),
	...(ownerState.startIcon && {
		paddingLeft: spacing(8),
	}),
	...((ownerState.endIcon || ownerState.error === 'required' || ownerState.type === 'select') && {
		paddingRight: spacing(8),
	}),
	[mq('md')]: {
		paddingLeft: spacing(6),
		paddingRight: spacing(6),
		...(ownerState.startIcon && {
			paddingLeft: spacing(8),
		}),
		...((ownerState.endIcon || ownerState.error === 'required' || ownerState.type === 'select') && {
			paddingRight: spacing(8),
		}),
	},
	...(ownerState.type === 'select' && {
		backgroundImage: `linear-gradient(135deg, rgba(3 18 59 / 0) 9px, rgba(3 18 59 / 1) 9px, rgba(3 18 59 / 1) 12px, rgba(3 18 59 / 0) 12px),
			linear-gradient(225deg, rgba(3 18 59 / 0) 9px, rgba(3 18 59 / 1) 9px, rgba(3 18 59 / 1) 12px, rgba(3 18 59 / 0) 12px)`,
		backgroundSize: '16px 7px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 8px center',
		cursor: 'pointer',
	}),
	'&:hover': {
		borderColor: palette.gray[500],
	},
	'&::placeholder': {
		color: palette.blue[200],
	},
	'&:focus': {
		outline: '2px solid transparent',
		outlineOffset: '2px',
		boxShadow: `0 0 0 1px ${palette.blue[300]}`,
	},
});

const Select = styled(
	function Select({
		value = '',
		options = [],
		onChange: givenOnChange = () => { },
		updateFormErrors = () => { },
		...props
	}) {
		const { t } = useTranslation();

		function onChange(event) {
			givenOnChange([event.target.name, event.target.value]);
		}

		useEffect(() => {
			updateFormErrors();
		}, [value]);

		return <select
			{...props}
			value={value}
			onChange={onChange}
		>
			<option value='' disabled>{t('form.select')}</option>
			{
				options.map((option, optionIndex) =>
					<option
						key={optionIndex}
						value={option.value}
					>{t(option.label)}</option>
				)
			}
		</select>
	},
	{
		name: 'Field',
		slot: 'Input',
		shouldForwardProp: (prop) => prop !== 'error',
	}
)(({ theme, ...ownerState }) => InputStyle({ type: 'select', ...ownerState }));

const Checkbox = styled(
	function Checkbox({
		label = '',
		labelLinks = null,
		name = '',
		value = false, // Accepts an `array`, `boolean`, or a `string`
		htmlValue = true,
		onChange: givenHandleChange = () => { },
		updateFormErrors = () => { },
		className = '', // Remove it from props to avoid wrong styling
		...props
	}) {
		const { t } = useTranslation();

		const valueType = (value === true || value === false)
			? 'boolean'
			: Array.isArray(value)
				? 'array'
				: typeof value === 'string'
					? 'string'
					: 'other';

		const checked = valueType === 'boolean'
			? value
			: valueType === 'array'
				? value.includes(htmlValue)
				: !!value;

		useEffect(() => {
			updateFormErrors();
		}, [checked]);

		function handleChange() {
			if (valueType === 'boolean') {
				givenHandleChange([name, !checked]);
			} else if (valueType === 'array') {
				if (htmlValue === 'n/a') {
					// If special case `'n/a'`, delete all other entries from array, as they cannot coexist with it
					givenHandleChange([name, !checked ? [htmlValue] : []]);
					return;
				}

				const arrayCopy = [...value];
				if (value.includes('n/a')) arrayCopy.splice(value.indexOf('n/a'), 1); // Delete `'n/a'` if present as it cannot coexist with another value
				if (value.includes(htmlValue)) arrayCopy.splice(value.indexOf(htmlValue), 1);
				givenHandleChange([name, !checked ? [...arrayCopy, htmlValue] : arrayCopy]);
			} else {
				givenHandleChange([name, !checked ? htmlValue : '']);

				if (valueType === 'other') console.warn(`'valueType' of '${typeof value}' is not supported, defaults to 'string behaviour'.`)
			}
		}

		return <div
			css={{
				display: 'flex',
				alignItems: 'flex-start',
				'& input': {
					flexShrink: 0,
					margin: 0,
					marginRight: spacing(2),
					marginTop: spacing(1.5),
					accentColor: palette.yellow[400],
					width: '1em',
					height: '1em',
					cursor: 'pointer',
				},
			}}
		>
			<input
				type='checkbox'
				name={name}
				checked={checked}
				onChange={handleChange}
				{...props}
			/>
			<Typo
				component='label'
				variant='regular'
				htmlFor={name}
				css={{
					color: palette.blue[200],
				}}
			>
				{
					labelLinks?.length
						? <Trans
							i18nKey={label}
							components={labelLinks.map((item, itemIndex) =>
								<LinkButton
									key={itemIndex}
									to={item}
									hideExternalIcon={true}
									css={{
										display: 'inline',
									}}
								/>
							)}
						/>
						: t(label)
				}
			</Typo>
		</div>
	},
	{
		name: 'Field',
		slot: 'Input',
		// shouldForwardProp: (prop) => prop !== 'error',
	}
)(({ theme, ...ownerState }) => InputStyle({ ...ownerState }));

const Input = styled(
	'input',
	{
		name: 'Field',
		slot: 'Input',
		shouldForwardProp: (prop) => prop !== 'error',
	}
)(({ theme, ...ownerState }) => InputStyle({ ...ownerState }));

const NationalNumberInput = styled(
	function NationalNumberInput({
		value = '',
		onChange: givenOnChange = () => { },
		...props
	}) {
		function onChange(event) {
			const cleanedValue = event.target.value.replace(/\D/g, '').substring(0, 11);

			givenOnChange([event.target.name, cleanedValue]);
		}

		const formattedValue = getFormattedNationalNumber(value);

		return <input
			value={formattedValue}
			onChange={onChange}
			{...props}
			inputMode='decimal'
		/>
	},
	{
		name: 'Field',
		slot: 'Input',
		shouldForwardProp: (prop) => prop !== 'error',
	}
)(({ theme, ...ownerState }) => InputStyle({ ...ownerState }));

const MultipartDateInput = styled(
	function MultipartDateInput({ value = '', name = '', id = '', onChange: givenOnChange = () => { }, onBlur: givenOnBlur = () => { }, ...props }) {
		const { t } = useTranslation();

		function handleDayChange(event) {
			const newDay = event.target.value.replace(/\D/, '').substring(0, 2);
			givenOnChange([name, (year || month || newDay) ? `${year}-${month}-${newDay}` : '']);
		}
		function handleMonthChange(event) {
			const newMonth = event.target.value.replace(/\D/, '').substring(0, 2);
			givenOnChange([name, (year || newMonth || day) ? `${year}-${newMonth}-${day}` : '']);
		}
		function handleYearChange(event) {
			const newYear = event.target.value.replace(/\D/, '').substring(0, 4);
			givenOnChange([name, (newYear || month || day) ? `${newYear}-${month}-${day}` : '']);
		}

		function onBlur(event) {
			givenOnBlur(event);

			if ((year && year.length < 4) || (month && month.length < 2) || (day && day.length < 2)) {
				const newYear = (year && year.length < 4) ? `0${year.length < 3 ? '0' : ''}${year.length < 2 ? '0' : ''}${year}` : year;
				const newMonth = (month && month.length < 2) ? `0${month}` : month;
				const newDay = (day && day.length < 2) ? `0${day}` : day;
				givenOnChange([name, `${newYear}-${newMonth}-${newDay}`]);
			}
		}

		const [year, month, day] = getDateArray(value);

		return <FlexGrid container spacing={2}>
			<FlexGrid xs={4} sm={3}>
				<Typo
					component='label'
					variant='small'
					css={{
						color: palette.blue[200],
					}}
				>{t('form.day')}</Typo>
				<input
					id={id}
					value={day}
					onChange={handleDayChange}
					onBlur={onBlur}
					{...props}
					inputMode='decimal'
				/>
			</FlexGrid>
			<FlexGrid xs={4} sm={3}>
				<Typo
					component='label'
					variant='small'
					css={{
						color: palette.blue[200],
					}}
				>{t('form.month')}</Typo>
				<input
					id={`${id}2`}
					value={month}
					onChange={handleMonthChange}
					onBlur={onBlur}
					{...props}
					inputMode='decimal'
				/>
			</FlexGrid>
			<FlexGrid xs={4} sm={6}>
				<Typo
					component='label'
					variant='small'
					css={{
						color: palette.blue[200],
					}}
				>{t('form.year')}</Typo>
				<input
					id={`${id}3`}
					value={year}
					onChange={handleYearChange}
					onBlur={onBlur}
					{...props}
					inputMode='decimal'
				/>
			</FlexGrid>
		</FlexGrid>
	},
	{
		name: 'Field',
		slot: 'Input',
		shouldForwardProp: (prop) => prop !== 'error',
	}
)(({ theme, ...ownerState }) => InputStyle({ ...ownerState }));

const TextArea = styled(
	function TextareaAutosize({ value = '', className = '', ...props }) {
		const hiddenRef = useRef();

		const [height, setHeight] = useState(0);

		useLayoutEffect(() => {
			if (!hiddenRef.current) setHeight(0);

			let newHeight = 0;
			newHeight += hiddenRef.current?.scrollHeight || 0;
			newHeight += Number(getComputedStyle(hiddenRef.current)?.borderTopWidth?.replace(/[^\d+.]/g, '') || '');
			newHeight += Number(getComputedStyle(hiddenRef.current)?.borderBottomWidth?.replace(/[^\d+.]/g, '') || '');

			setHeight(newHeight ? Math.ceil(newHeight) : 0);
		}, [hiddenRef.current, value]);

		return <>
			<textarea
				value={value}
				style={{ height: height, }}
				className={className}
				{...props}
			/>
			<div
				css={{
					width: '100%',
					position: 'absolute',
					visibility: 'hidden',
				}}
			>
				<textarea
					ref={hiddenRef}
					value={value}
					aria-hidden={true}
					tabIndex='-1'
					readOnly
					className={className}
				/>
			</div>
		</>
	},
	{
		name: 'Field',
		slot: 'Input',
		shouldForwardProp: (prop) => prop !== 'error',
	}
)(({ theme, ...ownerState }) => InputStyle({ ...ownerState }));

export const Field = styled(
	function Field({
		name = '',
		label = '',
		sublabel = '',
		labelLinks = null,
		placeholder = '',
		startIcon = null,
		endIcon = null,
		value = '',
		error = '',
		type = 'text',
		autoComplete = '',
		options = [],
		handleChange = () => { },
		handleBlur: givenHandleBlur = () => { },
		updateErrors: updateFormErrors = () => { },
		triedSubmit = false,
		...props
	}) {
		const { t } = useTranslation();

		const [{ focused, blurred, filled }, setState] = useState({
			focused: false,
			blurred: false,
			filled: false,
		})

		function handleFocus() {
			setState(prevState => ({ focused: true, ...prevState }));
		}

		function handleBlur(event) {
			givenHandleBlur(event);

			setState({
				focused: false,
				blurred: true,
				filled: !!value,
			});

			updateFormErrors();
		}

		return <div
			{...props}
		>
			{
				(label && type !== 'checkbox') &&
				<div
					css={{
						display: 'block',
						color: palette.blue[200],
						marginBottom: spacing(2),
					}}
				>
					<Typo
						component='label'
						variant='regular'
						htmlFor={name}
						css={{
							display: 'block',
							color: palette.blue[200],
						}}
					>
						<Typo
							component='span'
							variant='regular'
							css={{
								display: 'block',
							}}
						>{t(label)}</Typo>
						{
							sublabel &&
							<Typo
								component='span'
								variant='small'
								css={{
									display: 'block',
								}}
							>{t(sublabel)}</Typo>
						}
					</Typo>
				</div>
			}
			<div
				css={{ position: 'relative', }}
			>
				{
					startIcon &&
					<StartIcon>
						{startIcon}
					</StartIcon>
				}
				{
					type === 'textarea' ?
						<TextArea
							id={name}
							name={name}
							value={value}
							{...(blurred || triedSubmit) && { error: error, }}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={t(placeholder)}
						/>
						: type === 'select' ?
							<Select
								id={name}
								name={name}
								value={value}
								{...(blurred || triedSubmit) && { error: error, }}
								onChange={handleChange}
								onBlur={handleBlur}
								options={options}
								updateFormErrors={updateFormErrors}
							/>
							: type === 'checkbox' ?
								<Checkbox
									id={name}
									name={name}
									value={value}
									{...(blurred || triedSubmit) && { error: error, }}
									onChange={handleChange}
									onBlur={handleBlur}
									label={label}
									labelLinks={labelLinks}
									updateFormErrors={updateFormErrors}
								/>
								: type === 'national_number' ?
									<NationalNumberInput
										id={name}
										name={name}
										value={value}
										{...(blurred || triedSubmit) && { error: error, }}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									: type === 'multipart_date' ?
										<MultipartDateInput
											id={name}
											name={name}
											value={value}
											{...(blurred || triedSubmit) && { error: error, }}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										:
										<Input
											id={name}
											name={name}
											value={value}
											{...(blurred || triedSubmit) && { error: error, }}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder={placeholder}
											type={type}
											{...autoComplete && { autoComplete: autoComplete }}
										/>
				}
				{
					endIcon &&
					<EndIcon>
						{endIcon}
					</EndIcon>
				}
			</div>
			{
				error && (blurred || triedSubmit) &&
				<Error>
					{
						(typeof error.key === 'function') ?
							t(error().key, error().values)
							:
							t(error)
					}
				</Error>
			}
		</div >
	},
	{
		name: 'Field',
		slot: 'Test',
	}
)(({ theme, ...ownerState }) => ({
	display: 'inline-block',
	position: 'relative',
	borderRadius: '4px',
	width: '100%',
}));