/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { getButtonAndLinkStyle, spacing } from "../../theme";

export const Button = styled(
	function Button({
		type = 'button',
		startIcon = null,
		startIconProps = null,
		endIcon = null,
		endIconProps = null,
		onClick = () => { },
		children,
		...props
	}) {
		return <button
			type={type}
			onClick={onClick}
			{...props}
		>
			{
				startIcon &&
				<div
					aria-hidden={true}
					css={{ display: 'flex', marginRight: spacing(2), }}
					{...startIconProps}
				>{startIcon}</div>
			}
			{children}
			{
				endIcon &&
				<div
					aria-hidden={true}
					css={{ display: 'flex', marginLeft: spacing(2), }}
					{...endIconProps}
				>{endIcon}</div>
			}
		</button>
	},
	{
		shouldForwardProp: prop => prop !== 'variant' && prop !== 'color' && prop !== 'size',
	}
)(({ variant = 'contained', color = 'primary', size = 'large', }) => getButtonAndLinkStyle({ variant, color, size }));