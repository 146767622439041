/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { postOrganDonationDecision } from "../../../../../api/organDonationDecision";
import { updateService } from "../../../../../api/service";
import { Tutorial } from "../../../../../components/common/Tutorial";
import { ContainerWithSidebar } from "../../../../../components/display/ContainerWithSidebar";
import { Stepper } from "../../../../../components/stepper/Stepper";
import { StepperMenu } from "../../../../../components/stepper/StepperMenu";
import { Button } from "../../../../../components/ui/Button";
import { CardBox } from "../../../../../components/ui/CardBox";
import { Container } from "../../../../../components/ui/Container";
import { ScrollDisplay } from "../../../../../components/ui/ScrollDisplay";
import { TransListItems, TransParagraphs } from "../../../../../components/ui/Trans";
import { Typo } from "../../../../../components/ui/Typo";
import { ORGAN_DONATION_DECISION__DATA as SERVICE__ORGAN_DONATION_DECISION__DATA, ORGAN_DONATION_DECISION__DATA__SCHEMA as SERVICE__ORGAN_DONATION_DECISION__DATA__SCHEMA } from "../../../../../data/services/organDonationDecision";
import { useConditionValidatedOnce } from "../../../../../hooks/conditionValidatedOnce";
import { useFormValues } from "../../../../../hooks/form";
import { useMediaQuery } from "../../../../../hooks/mediaQuery";
import { isFalse } from "../../../../../lib/conditions";
import { getLocalStorageData, setLocalStorageData } from "../../../../../lib/localStorage";
import { mq, palette, spacing } from "../../../../../theme";

const defaultData = SERVICE__ORGAN_DONATION_DECISION__DATA;
const dataSchema = SERVICE__ORGAN_DONATION_DECISION__DATA__SCHEMA;

const steps = [
	{
		route: 'intro',
		label: ({ t }) => t('organDonationDecision.steps.intro.label'),
		title: ({ t }) => t('organDonationDecision.steps.intro.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'organDonationDecision.steps.intro.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'needs',
		label: ({ t }) => t('organDonationDecision.steps.needs.label'),
		title: ({ t }) => t('organDonationDecision.steps.needs.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('organDonationDecision.steps.needs.list.title')}</Typo>
						<Typo
							component='ul'
							variant='regular'
							css={{
								listStyle: 'disc',
								paddingLeft: spacing(6),
								marginBottom: spacing(4),
								'& li+li': {
									marginTop: spacing(1),
								},
							}}
						>
							<TransListItems
								i18nKey={'organDonationDecision.steps.needs.list.items'}
								t={t}
								useMarkdown={true}
							/>
						</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('organDonationDecision.steps.needs.missingInfo.title')}</Typo>
						<TransParagraphs
							i18nKey={'organDonationDecision.steps.needs.missingInfo.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'organ_transplantation_will',
		label: ({ t }) => t('organDonationDecision.steps.organTransplantationWill.label'),
		title: ({ t }) => t('organDonationDecision.steps.organTransplantationWill.title'),
		subtitle: ({ t }) => t('organDonationDecision.steps.organTransplantationWill.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'organ_transplantation_will',
				props: {
					style: 'row',
					options: [
						{ value: 'opposition', label: 'global.no' },
						{ value: 'consent', label: 'global.yes' },
						{ value: 'none', label: 'form.whatever', description: 'organDonationDecision.noWillDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.organ_transplantation_will === 'opposition' ? t('organDonationDecision.opposition')
			: data.organ_transplantation_will === 'consent' ? t('organDonationDecision.consent')
				: data.organ_transplantation_will === 'none' ? t('organDonationDecision.whatever')
					: '',
	},
	{
		route: 'body_material_transplantation_will',
		label: ({ t }) => t('organDonationDecision.steps.bodyMaterialTransplantationWill.label'),
		title: ({ t }) => t('organDonationDecision.steps.bodyMaterialTransplantationWill.title'),
		subtitle: ({ t }) => t('organDonationDecision.steps.bodyMaterialTransplantationWill.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'body_material_transplantation_will',
				props: {
					style: 'row',
					options: [
						{ value: 'opposition', label: 'global.no' },
						{ value: 'consent', label: 'global.yes' },
						{ value: 'none', label: 'form.whatever', description: 'organDonationDecision.noWillDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.body_material_transplantation_will === 'opposition' ? t('organDonationDecision.opposition')
			: data.body_material_transplantation_will === 'consent' ? t('organDonationDecision.consent')
				: data.body_material_transplantation_will === 'none' ? t('organDonationDecision.whatever')
					: '',
	},
	{
		route: 'body_material_drug_manufacturing_will',
		label: ({ t }) => t('organDonationDecision.steps.bodyMaterialDrugManufacturingWill.label'),
		title: ({ t }) => t('organDonationDecision.steps.bodyMaterialDrugManufacturingWill.title'),
		subtitle: ({ t }) => t('organDonationDecision.steps.bodyMaterialDrugManufacturingWill.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'body_material_drug_manufacturing_will',
				props: {
					style: 'row',
					options: [
						{ value: 'opposition', label: 'global.no' },
						{ value: 'consent', label: 'global.yes' },
						{ value: 'none', label: 'form.whatever', description: 'organDonationDecision.noWillDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.body_material_drug_manufacturing_will === 'opposition' ? t('organDonationDecision.opposition')
			: data.body_material_drug_manufacturing_will === 'consent' ? t('organDonationDecision.consent')
				: data.body_material_drug_manufacturing_will === 'none' ? t('organDonationDecision.whatever')
					: '',
	},
	{
		route: 'body_material_research_will',
		label: ({ t }) => t('organDonationDecision.steps.bodyMaterialResearchWill.label'),
		title: ({ t }) => t('organDonationDecision.steps.bodyMaterialResearchWill.title'),
		subtitle: ({ t }) => t('organDonationDecision.steps.bodyMaterialResearchWill.subtitle'),
		items: [
			{
				type: 'oneOf',
				name: 'body_material_research_will',
				props: {
					style: 'row',
					options: [
						{ value: 'opposition', label: 'global.no' },
						{ value: 'consent', label: 'global.yes' },
						{ value: 'none', label: 'form.whatever', description: 'organDonationDecision.noWillDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.body_material_research_will === 'opposition' ? t('organDonationDecision.opposition')
			: data.body_material_research_will === 'consent' ? t('organDonationDecision.consent')
				: data.body_material_research_will === 'none' ? t('organDonationDecision.whatever')
					: '',
	},
	{
		route: 'contact_details',
		label: ({ t }) => t('organDonationDecision.steps.contactDetails.label'),
		title: ({ t }) => t('organDonationDecision.steps.contactDetails.title'),
		items: [
			{
				type: 'field',
				name: 'email',
				props: {
					type: 'email',
					label: 'form.email',
				},
			},
			{
				type: 'field',
				name: 'first_name',
				props: {
					label: 'form.firstName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'last_name',
				props: {
					label: 'form.lastName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'birth_date',
				props: {
					type: 'date',
					label: 'form.birthDate',
				},
				gridSizes: {
					sm: 6,
				},
			},
		],
		preview: ({ data, t }) => data.email,
	},
];

export function OrganDonation() {
	const { t } = useTranslation();

	// const { isLoading: serviceOrganDonationDecisionLoading, data: serviceOrganDonationDecision } = useQuery('serviceOrganDonationDecision', () => fetchService({ type: 'organ_donation_decision' }));
	const { isLoading: serviceOrganDonationDecisionLoading, data: serviceOrganDonationDecision } = useQuery('serviceOrganDonationDecision',
		/* No need for remote for now	
		() => !!getAuthToken()
			? fetchService({ type: 'organ_donation_decision' })
			: getLocalStorageData('organ_donation_decision')
		*/
		() => getLocalStorageData('organ_donation_decision')
	);
	const serviceOrganDonationDecisionLoaded = useConditionValidatedOnce(serviceOrganDonationDecisionLoading, isFalse);

	const serviceOrganDonationDecisionData = serviceOrganDonationDecisionLoaded ? (serviceOrganDonationDecision?.data || null) : undefined;

	const queryClient = useQueryClient();

	const updateMutation = useMutation((data = defaultData) => {
		return updateService({ type: 'organ_donation_decision', data });
	}, {
		onSettled: () => {
			queryClient.invalidateQueries('serviceOrganDonationDecision');
		},
	});
	function save(data) {
		// if (!!getAuthToken()) return updateMutation.mutateAsync(data); // No need for remote for now

		setLocalStorageData({ id: 'advance_decisions_completed', data: 'organ_donation', storageType: 'array' });

		return setLocalStorageData({ id: 'organ_donation_decision', data: { data: data, }, });
	}

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	} = useFormValues({
		defaultData,
		dataSchema,
	});

	const postMutation = useMutation(({ data, steps }) => {
		return postOrganDonationDecision(data, steps);
	});

	async function sendData() {
		const response = await postMutation.mutateAsync({ data, steps });

		// if (!!response) localStorage.removeItem('organ_donation_decision');

		return response;
	}

	const isMedium = useMediaQuery(mq('md'));

	return <>
		<ContainerWithSidebar
			sidebarFitDesktopScreen={true}
			showSidebarOnMobile={true}
			sidebar={
				<>
					<Tutorial
						id='stepper_menu'
						placement={isMedium ? 'right-start' : 'bottom'}
						description={t('tutorials.stepperMenu')}
					>
						<div>
							<Typo
								component='p'
								variant='title-2'
								css={{ marginBottom: spacing(2), }}
							>{t('stepper.menu.title')}</Typo>
						</div>
					</Tutorial>
					<ScrollDisplay
						component={CardBox}
						wrapperCss={{
							padding: 0,
						}}
						css={{
							padding: spacing(4),
						}}
					>
						<StepperMenu
							steps={steps}
							data={data}
							errors={errors}
						/>
					</ScrollDisplay>
				</>
			}
		>
			<div
				css={{ paddingBottom: spacing(12), }}
			>
				<Stepper
					save={save}
					queryKey={'organ_donation_decision'}
					storeData={serviceOrganDonationDecisionData}
					steps={steps}
					data={data}
					setData={setData}
					defaultData={defaultData}
					errors={errors}
					updateFormErrors={updateErrors}
					getFormErrors={getErrors}
					getFieldValues={getFieldValues}
					allowInvalid={false}
					validEndRedirect={'/advance-decisions?success=organDonation'}
					endCallback={sendData}
				/>
			</div>
		</ContainerWithSidebar>
		<Container>
			<div
				css={{
					marginBottom: spacing(12),
					borderBottom: '1px solid',
					borderColor: palette.gray[300],
				}}
			/>
		</Container>
	</>
}