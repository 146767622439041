/** @jsxImportSource @emotion/react */

import { ClickAwayListener } from "@mui/material";
import React, { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "../ui/Menu";
import { Popper } from "../ui/Popper";
import { DropdownButton } from "./DropdownButton";

export function Dropdown({
	label = '',
	items = [],
}) {
	const { t } = useTranslation();

	const id = useId();

	const [buttonEl, setButtonEl] = useState(null);
	function handleOpen(event) {
		setButtonEl(event.currentTarget);
	}
	function handleClose() {
		setButtonEl(null);
		setOpenedWithKey(null);
	}
	function handleToggle(event) {
		!!buttonEl ? handleClose() : handleOpen(event)
	}

	// Keyboard activation
	const [openedWithKey, setOpenedWithKey] = useState(null);

	function handleKeyDown(event) {
		switch (event.key) {
			case ' ':
			case 'Enter':
				event.preventDefault();
				!!buttonEl ? handleClose() : handleOpen(event);
				setOpenedWithKey(event.key);
				break;
			case 'ArrowDown':
			case 'ArrowUp':
				event.preventDefault();
				handleOpen(event);
				setOpenedWithKey(event.key);
				break;
			case 'Tab':
				// Can still be focused while menu is open if opened by mouse click
				handleClose();
				break;
			default:
				break;
		}
	}

	return <ClickAwayListener onClickAway={handleClose}>
		<div> {/* Container for `ClickAwayListener` */}
			<DropdownButton
				label={label}
				id={`dropdown_${id}-button`}
				rid={`dropdown_${id}-content`}
				open={!!buttonEl}
				handleClick={handleToggle}
				onKeyDown={handleKeyDown}
			/>
			<Popper
				open={!!buttonEl}
				anchorEl={buttonEl}
				placement='bottom-end'
				withPortal
				role='presentation'
			>
				<Menu
					id={`dropdown_${id}-content`}
					aria-labelledby={`dropdown_${id}-button`}
					items={items}
					callerElement={buttonEl}
					openedWithKey={openedWithKey}
					handleClose={handleClose}
				// class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
				/>
			</Popper>
		</div>
		{/* class="py-1 z-5" */}
	</ClickAwayListener>
}