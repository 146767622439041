/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../lib/dates";
import { getFormattedNationalNumber } from "../../lib/strings";
import { palette, spacing } from "../../theme";
import { Typo } from "../ui/Typo";

export function PeopleDataPresentation({
	data = {},
	partsToShow = [],
}) {
	const { t } = useTranslation();

	function getString(data, part) {
		switch (part) {
			case 'firstNameAndLastName':
				return `${data.first_name || '.....'} ${data.last_name?.toUpperCase() || '.....'}`;
			case 'nationalNumber':
				return `${t('dataPresentation.nationalNumber')} ${getFormattedNationalNumber(data.national_number) || '.....'}`;
			case 'address':
				return `${t('dataPresentation.address')} ${t('dataPresentation.addressFormatted', { street: data.address?.street || '.....', number: data.address?.number || '.....', box: data.address?.box ? t('dataPresentation.addressFormattedBox', { box: data.address.box }) : '', postalCode: data.address?.postal_code || '.....', town: data.address?.town || '.....' })}`;
			case 'phone':
				return `${t('dataPresentation.phone')} ${data.phone || '.....'}`;
			case 'birthPlaceAndDate':
				return `${t('dataPresentation.birthPlaceAndDateFormatted', { date: getFormattedDate(data.birth.date) || '.....', place: data.birth?.address?.town || '.....' })}`;
			case 'relationship':
				return `${t('dataPresentation.relationship')} ${data.relationship || 'aucun'}`;
			default:
				return '';
		}
	}

	return <>
		{
			partsToShow.map((item, index, array) =>
				<Typo
					key={index}
					variant='regular'
					component='p'
					css={{
						...index === 0
							? {
								fontWeight: 600,
								...array.length > 1 && {
									marginBottom: spacing(2),
								},
							}
							: {
								color: palette.blue[200],
							}
					}}
				>{getString(data, item)}</Typo>
			)
		}
	</>
}