/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import { ClickAwayListener } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { fetchUser } from "../../api/user";
import { AuthTokenContext } from "../../contexts/AuthToken";
import { animations, mq, palette, spacing, typography } from "../../theme";
import { Dropdown } from "../common/Dropdown";
import { DropdownButton } from "../common/DropdownButton";
import { Logo } from "../Logo";
import { Button } from "../ui/Button";
import { Collapse } from "../ui/Collapse";
import { Container } from "../ui/Container";
import { LinkButton } from "../ui/LinkButton";
import { BooleanHandler } from "../utils/BooleanHandler";
import { HeaderComplexMenu } from "./HeaderComplexMenu";

const availableLocales = [
	{
		name: 'FR',
		code: 'fr',
	},
	{
		name: 'NL',
		code: 'nl',
	},
];

const navItems = [
	{
		label: ({ t }) => t('menu.advanceDecisions'),
		route: '/account/before/legal-documents/advance-decisions',
	},

	// {
	// 	label: ({ t }) => t('menu.before'),
	// 	menu: {
	// 		label: ({ t }) => t('menu.beforeAccess'),
	// 		route: '/account/before',
	// 		items: [
	// 			{
	// 				position: ['0', '0.0'],
	// 				label: ({ t }) => t('menu.legalDocuments'),
	// 				route: '/account/before/legal-documents',
	// 				icon: LegalDocumentsIcon,
	// 				items: [
	// 					// {
	// 					// 	label: ({ t }) => t('menu.testament'),
	// 					// 	route: '/account/before/legal-documents/testament',
	// 					// },
	// 					// {
	// 					// 	label: ({ t }) => t('menu.mandate'),
	// 					// 	route: '/account/before/legal-documents/mandate',
	// 					// },
	// 					{
	// 						label: ({ t }) => t('menu.advanceDecisions'),
	// 						route: '/account/before/legal-documents/advance-decisions',
	// 					},
	// 				],
	// 			},
	// 			// {
	// 			// 	position: ['2', '0.1'],
	// 			// 	label: ({ t }) => t('menu.insurances'),
	// 			// 	route: '/account/before/legal-documents/insurances',
	// 			// 	icon: InsurancesIcon,
	// 			// },
	// 			// {
	// 			// 	position: ['1', '1.0'],
	// 			// 	label: ({ t }) => t('menu.box'),
	// 			// 	route: '/account/before/box',
	// 			// 	icon: BoxIcon,
	// 			// 	items: [
	// 			// 		{
	// 			// 			label: ({ t }) => t('menu.contacts'),
	// 			// 			route: '/account/before/box/contacts',
	// 			// 		},
	// 			// 		{
	// 			// 			label: ({ t }) => t('menu.letters'),
	// 			// 			route: '/account/before/box/letters',
	// 			// 		},
	// 			// 		{
	// 			// 			label: ({ t }) => t('menu.vdeos'),
	// 			// 			route: '/account/before/box/videos',
	// 			// 		},
	// 			// 		{
	// 			// 			label: ({ t }) => t('menu.digitalEnd'),
	// 			// 			route: '/account/before/box/digital-end',
	// 			// 		},
	// 			// 		{
	// 			// 			label: ({ t }) => t('menu.practicalInformation'),
	// 			// 			route: '/account/before/box/practical-information',
	// 			// 		},
	// 			// 		{
	// 			// 			label: ({ t }) => t('menu.pets'),
	// 			// 			route: '/account/before/box/pets',
	// 			// 		},
	// 			// 	],
	// 			// },
	// 		],
	// 	},
	// },
	// {
	// 	label: ({ t }) => t('menu.after'),
	// 	menu: {
	// 		label: ({ t }) => t('menu.afterAccess'),
	// 		route: '/account/after',
	// 		items: [
	// 			{
	// 				position: ['0', '0.0'],
	// 				label: ({ t }) => t('menu.ongoingFolders'),
	// 				route: '/account/after',
	// 				icon: OngoingFolderIcon,
	// 				items: [
	// 					{
	// 						label: 'Jean IFICATE', // TODO: Handle this
	// 						route: '/account/after/0', // TODO: Handle this
	// 					},
	// 				],
	// 			},
	// 			{
	// 				position: ['1', '0.1'],
	// 				label: ({ t }) => t('menu.completedFolders'),
	// 				route: '/account/after',
	// 				icon: ClosedFolderIcon,
	// 			},
	// 			{
	// 				position: ['2', '1.0'],
	// 				label: ({ t }) => t('menu.createFolder'),
	// 				route: '/account/after',
	// 				icon: CreateFolderIcon,
	// 				items: [
	// 					{
	// 						label: 'Résiliation de contrats',
	// 						route: '/account/after',
	// 					},
	// 					{
	// 						label: 'Recherche d\'avoirs',
	// 						route: '/account/after',
	// 					},
	// 					{
	// 						label: 'Gestion de succession',
	// 						route: '/account/after',
	// 					},
	// 				],
	// 			},
	// 		],
	// 	},
	// },
	// {
	// 	label: ({ t }) => t('menu.pricing'),
	// 	route: '/pricing',
	// },
];

export function Header() {
	const { t, i18n } = useTranslation();

	const $i18n = {
		locale: 'fr',
		setLocale: (code) => { $i18n.locale = code },
	};

	// Contexts
	const { updateAuthToken } = useContext(AuthTokenContext);

	const { isLoading: userLoading, data: user } = useQuery('user', () => fetchUser(), {
		refetchOnMount: false,
	});

	function userSignOut() {
		updateAuthToken(null);

		// navigate('/login');
	}

	const userMenuItems = [
		{
			label: ({ t }) => t('menu.changeInformation'),
			component: LinkButton,
			props: {
				to: '/account/me',
			},
		},
		{
			label: ({ t }) => t('menu.logout'),
			component: Button,
			props: {
				onClick: userSignOut,
			},
		},
	];

	const languageMenuItems = availableLocales.map(({ name = '', code = '' }) => ({
		label: name,
		component: Button,
		props: {
			onClick: () => i18n.changeLanguage(code),
		},
	}));

	const [mobileMenuButtonEl, setMobileMenuButtonEl] = useState(null);
	const handleMobileMenuToggle = (event) => {
		mobileMenuButtonEl ? handleMobileMenuClose() : handleMobileMenuOpen(event);
	};
	const handleMobileMenuOpen = (event) => {
		setMobileMenuButtonEl(event.currentTarget);
	};
	const handleMobileMenuClose = () => {
		setMobileMenuButtonEl(null);
	};

	const location = useLocation();

	return <header
		className='mui-fixed' // Inform MUI that this element is fixed, preventing width jumping when a modal/menu is opened and the scrollbar disappears
		css={{
			boxSizing: 'border-box', // In combination with .mui-fixed
			backgroundColor: [
				'white',
				'rgba(255 255 255 / 0.95)',
			],
			backdropFilter: 'blur(5px)',
			borderBottom: '1px solid',
			borderColor: palette.gray[300],
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 10,
			width: '100%',
		}}
	>
		<div
			css={{
				display: 'none',
				[mq('lg')]: {
					display: 'block',
				},
			}}
		>
			<Container
				css={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					paddingTop: spacing(2),
					paddingBottom: spacing(2),
				}}
			>
				<nav
					css={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<LinkButton
						to='/account'
					>
						<Logo
							width={80}
							css={{
								marginLeft: spacing(6),
								marginRight: spacing(6),
								[mq('md')]: {
									marginLeft: spacing(20),
								},
								[mq('lg')]: {
									marginLeft: 0,
								},
							}}
						/>
					</LinkButton>
					<ul
						css={{
							display: 'flex',
							...typography['regular'],
							color: palette.blue[300],
						}}
					>
						{
							navItems.map((item, itemIndex) =>
								<li
									key={itemIndex}
									css={{
										marginRight: spacing(6),
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{
										!item.menu ?
											<LinkButton
												to={item.route}
												variant='underlinedHover'
												{...new RegExp(`^${item.route}`)?.test(location.pathname) && {
													['aria-current']: 'page',
												}}
												css={{
													...typography['regular'],
													color: palette.blue[300],
													'&[aria-current="page"]': {
														fontWeight: 700,
													},
												}}
											>{typeof item.label === 'function' ? item.label({ t }) : item.label}</LinkButton>
											:
											<BooleanHandler>
												{
													(open, handleOpen, handleClose, handleToggle) =>
														<ClickAwayListener onClickAway={handleClose}>
															<div> {/* Container for `ClickAwayListener` */}
																<DropdownButton
																	label={typeof item.label === 'function' ? item.label({ t }) : item.label}
																	id={`mainNav_${itemIndex}-button`}
																	rid={`mainNav_${itemIndex}-content`}
																	open={open}
																	handleClick={handleToggle}
																	{...new RegExp(`^${item.menu?.route}`)?.test(location.pathname) && {
																		css: { fontWeight: 700, },
																	}}
																/>
																<Container
																	css={{
																		position: 'absolute',
																		top: 65,
																		left: '50%',
																		transform: 'translateX(-50%)',
																		margin: 'auto',
																		width: '100%',
																		[mq('md')]: {
																			width: `${(100 * (10 / 12)).toFixed(2)}%`,
																		},
																		[mq('lg')]: {
																			width: `${(100 * (8 / 12)).toFixed(2)}%`,
																		},
																		backgroundColor: 'white',
																		borderRadius: '4px',
																		transition: '0.5s box-shadow',
																		...open && {
																			boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
																		},
																	}}
																>
																	<Collapse
																		open={open}
																	>
																		<HeaderComplexMenu
																			aria-labelledby={`mainNav_${itemIndex}-button`}
																			id={`mainNav_${itemIndex}-content`}
																			items={item.menu.items}
																			label={item.menu.label}
																			route={item.menu.route}
																			handleClose={handleClose}
																		/>
																	</Collapse>
																</Container>
															</div>
														</ClickAwayListener>
												}
											</BooleanHandler>
										// <Dropdown
										// 	handleSelect={(item) => navigate(item.route)}
										// 	items={item.menu.items.map(i => [{ label: i.label, route: i.route }, ...(i.items ? i.items.map(j => ({ label: `- ${j.label}`, route: j.route })) : [])]).flatMap(i => i)}
										// 	getItemLabel='label'
										// />
									}
								</li>
							)
						}
					</ul>
				</nav>
				<div
					css={{
						display: 'flex',
					}}
				>
					{/* <Dropdown
						label={availableLocales.find(i => i.code === i18n.language) || ''}
						items={availableLocales}
					/> */}
					{
						!userLoading && (
							user?.email
								?
								<Dropdown
									label={user.email}
									items={userMenuItems}
								/>
								:
								<LinkButton
									to='/'
									variant='underlinedHover'
									css={{
										...typography['regular'],
										color: palette.blue[300],
									}}
								>{t('menu.login')}</LinkButton>
						)
					}
				</div>
			</Container>
		</div>

		{/* Mobile header */}
		<div
			css={{
				display: 'block',
				[mq('lg')]: {
					display: 'none',
				},
			}}
		>
			<div
				css={{
					paddingTop: spacing(2),
					paddingBottom: spacing(2),
					paddingLeft: spacing(4),
					paddingRight: spacing(4),
				}}
			>
				<Container
					css={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<LinkButton
						to='/account'
					>
						<Logo
							width={80}
							css={{
								marginRight: spacing(2),
							}}
						/>
					</LinkButton>
					{/* <div class="inline-block text-left"> */}
					<div>
						<DropdownButton
							label={t('global.menu')}
							id='mobileMenu-button'
							rid='mobileMenu-content'
							open={!!mobileMenuButtonEl}
							handleClick={handleMobileMenuToggle}
						/>
					</div>
					{/* </div> */}
				</Container>
			</div>

			<Collapse
				open={!!mobileMenuButtonEl}
			>
				<div
					aria-labelledby={`mobileMenu-button`}
					id={`mobileMenu-content`}
					css={{
						padding: 6,
						zIndex: 1,
						borderBottom: '1px solid',
						borderColor: palette.gray[300],
					}}
				>
					<Container
						css={{
							...typography['regular'],
							color: palette.blue[300],
						}}
					>
						<nav>
							<ul
								css={{
									paddingBottom: spacing(4),
								}}
							>
								{
									navItems.map((item, itemIndex) =>
										<li
											key={itemIndex}
											onClick={handleMobileMenuClose}
											css={{
												marginRight: spacing(4),
												marginBottom: spacing(2),
											}}
										>
											{
												!item.menu ?
													<LinkButton
														to={item.route}
														variant='underlinedHover'
														css={{
															...typography['regular'],
															color: palette.blue[300],
														}}
													>{typeof item.title === 'function' ? item.title({ t }) : item.title}</LinkButton>
													:
													<div>
														<LinkButton
															to={item.menu?.route}
															variant='underlinedHover'
															css={{
																...typography['regular'],
																color: palette.blue[300],
															}}
														>{typeof item.label === 'function' ? item.label({ t }) : item.label}</LinkButton>
														{
															item.menu?.items?.length > 0 &&
															<ul
																css={{
																	paddingLeft: spacing(4),
																}}
															>
																{
																	item.menu.items.map((subItem, subItemIndex) =>
																		<li
																			key={subItemIndex}
																		>
																			<LinkButton
																				to={subItem.route}
																				variant='underlinedHover'
																				css={{
																					...typography['regular'],
																					color: palette.blue[300],
																				}}
																			>{typeof subItem.label === 'function' ? subItem.label({ t }) : subItem.label}</LinkButton>
																			{
																				subItem.items?.length > 0 &&
																				<ul
																					css={{
																						paddingLeft: spacing(4),
																					}}
																				>
																					{
																						subItem.items.map((subSubItem, subSubItemIndex) =>
																							<li
																								key={subSubItemIndex}
																							>
																								<LinkButton
																									to={subSubItem.route}
																									variant='underlinedHover'
																									css={{
																										...typography['regular'],
																										color: palette.blue[300],
																									}}
																								>{typeof subSubItem.label === 'function' ? subSubItem.label({ t }) : subSubItem.label}</LinkButton>
																							</li>
																						)
																					}
																				</ul>
																			}
																		</li>
																	)
																}
															</ul>
														}
													</div>
											}
										</li>
									)
								}
							</ul>
						</nav>
						{/* <div
							css={{
								borderTop: '2px solid',
								borderColor: palette.gray[200],
								paddingTop: spacing(4),
							}}
						>
							{t('menu.language')}
						</div>
						<div
							css={{
								...typography['regular'],
								color: palette.blue[300],
								margin: spacing(4),
							}}
						>
							<ul>
								{
									availableLocales.map(locale =>
										<li
											key={locale.code}
										>
											<Link
												component='a'
												onClick={() => $i18n.setLocale(locale)}
												variant='underlinedHover'
												css={{
													cursor: 'pointer',
													...typography['regular'],
													color: palette.blue[300],
												}}
											>{locale.name}</Link>
										</li>
									)
								}
							</ul>
						</div> */}
						<div>
							{
								userMenuItems.map(({ label: itemLabel, component: itemComponent, props: itemProps }, itemIndex) =>
									jsx(
										itemComponent,
										{
											key: itemIndex,
											...itemProps,
											variant: 'underlinedHover',
											css: { color: palette.blue[300], },
										},
										typeof itemLabel === 'function' ? itemLabel({ t }) : itemLabel
									)
								)
							}
						</div>
					</Container>
				</div>
			</Collapse>
		</div>
		{
			!!mobileMenuButtonEl &&
			<div
				onClick={handleMobileMenuClose}
				css={{
					display: 'block',
					[mq('lg')]: {
						display: 'none',
					},
					animation: `${animations.fadeIn} 0.5s`,
					position: 'absolute',
					top: '100%',
					left: 0,
					width: '100vw',
					height: '100vh',
					backgroundColor: palette.gray[500],
					opacity: 0.75,
				}}
			/>
		}
	</header>
}