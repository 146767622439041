/** @jsxImportSource @emotion/react */

import { ReactComponent as Close } from "@material-design-icons/svg/filled/close.svg";
import { ModalUnstyled } from "@mui/base";
import { useTranslation } from "react-i18next";
import { mq, palette, spacing } from "../../theme";
import { Button } from "./Button";
import { CardBox } from "./CardBox";
import { ScrollDisplay } from "./ScrollDisplay";
import { Svg } from "./Svg";

/**
 * A card-like modal. If its content is too long, it will get a scrollbar. The header/footer will always be displayed.
 * 
 * @param {object} props Object containing the props:
 * - `props.open` Defines if the modal is open.
 * Defaults to `false`.
 * - `props.onClose` The callback to use when the modal is asking to close itself.
 * Optional. If defined, it will automatically create a 'close' button which will use it.
 * - `props.header` The header, will always be visible.
 * Optional.
 * - `props.footer` The footer, will always be visible.
 * Optional.
 * - `props.children` The element can contains children.
 * @returns {any} The component.
 */
export function CardModal({
	open = false,
	onClose = null,
	header = null,
	footer = null,
	children,
}) {
	const { t } = useTranslation();

	function handleBackdropClick() {
		if (onClose) onClose();
	}

	return <ModalUnstyled
		open={open}
		{...onClose && {
			onClose: onClose,
		}}
		css={{
			position: 'fixed',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1000,
		}}
	>
		<div>
			<Backdrop
				onClick={handleBackdropClick}
			/>
			<CardBox
				css={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '448px', // max-w-md
					maxWidth: 'calc(100vw - 40px)',
					display: 'flex',
					flexDirection: 'column',
					height: 'auto',
					maxHeight: 'calc(100vh - 40px)',
					padding: 0,
					[mq('sm')]: {
						maxWidth: 'none',
					},
					[mq('md')]: {
						width: '512px', // max-w-lg
					},
				}}
			>
				{
					header &&
					<div
						css={{
							paddingLeft: spacing(8),
							paddingRight: spacing(8),
							paddingTop: spacing(4),
							paddingBottom: spacing(4),
							borderBottom: '1px solid',
							borderColor: palette.blue[200],
						}}
					>
						{header}
					</div>
				}
				<ScrollDisplay
					css={{
						paddingLeft: spacing(8),
						paddingRight: spacing(8),
						paddingTop: header ? spacing(4) : spacing(8),
						paddingBottom: footer ? spacing(4) : spacing(8),
					}}
				>
					{children}
				</ScrollDisplay>
				{
					footer &&
					<div
						css={{
							paddingLeft: spacing(8),
							paddingRight: spacing(8),
							paddingTop: spacing(4),
							paddingBottom: spacing(4),
							borderTop: '1px solid',
							borderColor: palette.blue[200],
						}}>
						{footer}
					</div>
				}
				{
					onClose &&
					<div
						css={{
							position: 'absolute',
							right: 15,
							top: 15,
							width: 20,
							height: 20,
						}}>
						<Button
							onClick={onClose}
							variant='icon'
							color='secondary'
							css={{
								border: 'none',
								color: palette.blue[200],
							}}
						><Svg svg={Close} aria-label={t('global.close')} /></Button>
					</div>
				}
			</CardBox>
		</div>
	</ModalUnstyled>
}

function Backdrop({ ...props }) {
	return <div
		css={{
			zIndex: -1,
			position: 'fixed',
			right: 0,
			bottom: 0,
			top: 0,
			left: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		}}
		{...props}
	/>
}