/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import legacioBlackImg from '../assets/logos/legacio_black.png';
import legacioBlueImg from '../assets/logos/legacio_blue.png';
import legacioWhiteImg from '../assets/logos/legacio_white.png';
import legacioYellowImg from '../assets/logos/legacio_yellow.png';

export function Logo({
	color = 'blue',
	width = 128, // w-32
	...props
}) {
	const { t } = useTranslation();

	function getSrc(color) {
		switch (color) {
			case 'yellow':
				return legacioYellowImg;
			case 'white':
				return legacioWhiteImg;
			case 'black':
				return legacioBlackImg;
			case 'blue':
			default:
				return legacioBlueImg;
		}
	}

	return <div
		css={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: width,
		}}
		{...props}
	>
		<img
			src={getSrc(color)}
			alt={t('global.legacioLogo')}
			width='250px'
		/>
	</div>
}