/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";
import { ReactComponent as Add } from "@material-design-icons/svg/filled/add.svg";
import { ReactComponent as Delete } from "@material-design-icons/svg/filled/delete.svg";
import { ReactComponent as Edit } from "@material-design-icons/svg/filled/edit.svg";
import _get from 'lodash.get';
import { useTranslation } from "react-i18next";
import { palette, spacing } from "../../theme";
import { CheckboxCardButton } from "../form/CheckboxCardButton";
import { EntitiesArray } from "../form/EntitiesArray";
import { Error } from "../form/Error";
import { Field } from "../form/Field";
import { SelectableCardButton } from "../form/SelectableCardButton";
import { Button } from "../ui/Button";
import { CardBox } from "../ui/CardBox";
import { CardModal } from "../ui/CardModal";
import { FlexGrid } from "../ui/FlexGrid";
import { LinkButton } from "../ui/LinkButton";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";
import { ModalsArrayHandler } from "../utils/ModalsArrayHandler";
import { useCurrentStep } from "./hooks";
import { getStepValidity } from "./utils";

export function StepperQuestion({
	items = [],
	data = {},
	errors = null,
	handleButtonClick = () => { },
	handleChange = () => { },
	handleAdd = () => { },
	handleRemove = () => { },
	handleSubmit = () => { },
	handleBlur = () => { },
	handleBack = () => { },
	handleNext = () => { },
	updateFormErrors = () => { },
	getFieldProps = () => { },
	triedSubmit = false,
	lastStep = false,
	invalidStepperAllowed = true,
	canContinue = false,
	steps = [],
}) {
	const { t } = useTranslation();

	const { currentStepIndex, currentStepRoute } = useCurrentStep(steps);

	const invalidSteps = lastStep ? steps.filter(step => !getStepValidity(step, errors)) : []; // Used only for last step, don't evaluate when not needed

	const hasNames = items.some(i => i.name);

	return jsx(
		hasNames ? 'form' : 'div',
		{
			...hasNames && { onSubmit: handleSubmit, },
			css: {
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingLeft: spacing(2),
				paddingRight: spacing(2),
			}
		},
		<>
			<FlexGrid
				container
				spacing={2}
				css={{
					marginBottom: spacing(4),
				}}
			>
				{
					items.map(({
						type: itemType = '',
						name: itemName = '',
						refName: itemRefName = '',
						gridSizes: itemGridSizes = {},
						props: itemProps = {},
					}, itemIndex) =>
						<FlexGrid
							key={itemIndex}
							xs={12}
							{...itemGridSizes}
						>
							{
								itemType === 'content' ?
									itemProps.content({ data, handleBack, handleNext, t, })
									: itemType === 'field' ?
										<Field
											{...getFieldProps(itemName)}
											{...itemProps}
											handleBlur={handleBlur}
										/>
										:
										itemType === 'oneOf' ?
											<OneOf
												fieldProps={{
													...getFieldProps(itemName),
													handleChange: handleButtonClick,
													triedSubmit: triedSubmit,
												}}
												{...itemProps}
											/>
											: itemType === 'anyOf' ?
												<AnyOf
													fieldProps={{
														...getFieldProps(itemName),
														triedSubmit: triedSubmit,
													}}
													{...itemProps}
												/>
												// TODO: Factorize the buttons list from entitiesArray & modals array UI
												: itemType === 'entitiesArray' ?
													<EntitiesArray
														name={itemName}
														refName={itemRefName}
														{...itemProps}
														data={data}
														handleAdd={handleAdd}
														handleRemove={handleRemove}
														updateFormErrors={updateFormErrors}
														errors={errors}
														handleChange={handleChange}
														handleBlur={handleBlur}
														triedSubmit={triedSubmit}
													/>
													: itemType === 'modalsArray' ? // TODO: Rewrite as it may not be up to date anymore, as it is unused
														<ModalsArray
															name={itemName}
															{...itemProps}
															data={data}
															handleAdd={handleAdd}
															handleRemove={handleRemove}
															handleChange={handleChange}
															handleBlur={handleBlur}
															updateFormErrors={updateFormErrors}
															errors={errors}
															triedSubmit={triedSubmit}
															getFieldProps={getFieldProps}
														/>
														: null
							}
						</FlexGrid>
					)
				}
			</FlexGrid>
			{
				hasNames && // No automatic button if no names
				(
					!lastStep
						?
						(triedSubmit && !canContinue)
							?
							<CardBox
								css={{ padding: spacing(4), }}
							>
								<Typo
									component='p'
									variant='regular'
									css={{
										color: palette.red[500],
										marginBottom: spacing(2),
									}}
								>{t('stepper.stepInvalid')}</Typo>
								<Button
									type='submit'
								>{t('global.skipForNow')}</Button>
							</CardBox>
							:
							<Button
								type='submit'
							>{t('global.continue')}</Button>
						:
						(triedSubmit && !canContinue)
							?
							<CardBox
								css={{ padding: spacing(4), }}
							>
								{
									(invalidSteps.length === 1 && invalidSteps[0].route === currentStepRoute)
										?
										<Typo
											component='p'
											variant='regular'
											css={{
												color: palette.red[500],
												marginBottom: spacing(2),
											}}
										>{t('stepper.stepperInvalidLastStepOnly')}</Typo>
										:
										<>
											<Typo
												component='p'
												variant='regular'
												css={{
													color: palette.red[500],
													marginBottom: spacing(2),
												}}
											>{t('stepper.stepperInvalid')}</Typo>
											<Typo
												component='ul'
												variant='regular'
												css={{
													color: palette.red[500],
													listStyle: 'disc',
													paddingLeft: spacing(6),
													marginBottom: spacing(2),
													'& li+li': {
														marginTop: spacing(1),
													},
												}}
											>
												{
													invalidSteps.map((step, stepIndex) =>
														<Typo
															key={stepIndex}
															component='li'
															variant='inherit'
														>
															<LinkButton
																to={`../${step.route}`} // '../' because we are already in a route
																css={{
																	display: 'block',
																	color: palette.red[500],
																	'&:hover': {
																		color: palette.red[500],
																	},
																}}
															>{typeof step.label === 'function' ? step.label({ t }) : step.label} {currentStepRoute === step.route ? `(${t('stepper.currentStep')})` : ''}</LinkButton>
														</Typo>
													)
												}
											</Typo>
										</>
								}
								{
									invalidStepperAllowed
										?
										<>
											<Typo
												component='p'
												variant='regular'
												css={{
													color: palette.red[500],
													marginBottom: spacing(2),
												}}
											>{t('stepper.stepperInvalidContinueLater')}</Typo>
											<Button
												type='submit'
											>{t('global.completeLater')}</Button>
										</>
										:
										<Button
											type='submit'
										>{t('global.complete')}</Button>
								}
							</CardBox>
							:
							<Button
								type='submit'
							>{t('global.complete')}</Button>
				)
			}
		</>
	)
}

function ModalsList({
	data = {},
	errors = null,
	emptyText = '',
	addButtonText = '',
	itemPreviewContent = '',
	maxLength = 0,
	fields = [],
	namePrefix = '',
	modalOpenIndex = 0,
	currentLength = 0,
	openModal = () => { },
	closeModal = () => { },
	handleAdd = () => { },
	handleRemove = () => { },
	handleChange = () => { },
	handleBlur = () => { },
	updateFormErrors = () => { },
	triedSubmit = false,
	getFieldProps = () => { },
}) {
	const { t } = useTranslation();

	return <>
		<FlexGrid container spacing={2}>
			{
				(_get(data, namePrefix)?.length || 0) > 0 ?
					_get(data, namePrefix).map((item, itemIndex) =>
						<FlexGrid
							key={itemIndex}
							xs={12}
						>
							<CardBox
								css={{
									padding: spacing(4),
								}}
							>
								<div
									css={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div>
										{
											typeof itemPreviewContent === 'function' ? itemPreviewContent({ item, t }) : itemPreviewContent
										}
									</div>
									<div
										css={{
											marginLeft: 'auto',
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Button
											onClick={() => openModal(itemIndex)}
											variant='icon'
											css={{ marginLeft: spacing(2), }}
										><Svg svg={Edit} aria-hidden={true} /></Button>
										<Button
											onClick={() => handleRemove(itemIndex)}
											variant='icon'
											css={{ marginLeft: spacing(2), }}
										><Svg svg={Delete} aria-hidden={true} /></Button>
									</div>
								</div>
							</CardBox>
						</FlexGrid>
					)
					:
					<FlexGrid
						xs={12}
					>
						<CardBox
							css={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<Typo
								variant='regular'
								component='p'
							>{(typeof emptyText === 'function' ? emptyText({ t }) : emptyText) || t('form.emptyList')}</Typo>
						</CardBox>
					</FlexGrid>
			}
		</FlexGrid>
		<Button
			variant='outlined'
			color='secondary'
			onClick={handleAdd}
			startIcon={<Svg svg={Add} />}
			css={{
				marginTop: spacing(4),
				marginBottom: spacing(8),
			}}
			disabled={currentLength >= maxLength}
		>{(typeof addButtonText === 'function' ? addButtonText({ t }) : addButtonText) || t('global.add')}</Button>
		<CardModal
			open={modalOpenIndex !== null}
			onClose={closeModal}
		>
			<form
				onSubmit={(e) => { e.preventDefault(); closeModal(); }}
			>
				<FlexGrid
					container
					spacing={2}
					css={{
						marginBottom: spacing(4),
					}}
				>
					{
						fields.map(({ name: fieldName, gridSizes: fieldGridSizes = {}, ...fieldProps }, fieldIndex) =>
							<FlexGrid
								key={fieldIndex}
								xs={12}
								{...fieldGridSizes}
							>
								<Field
									{...getFieldProps(typeof fieldName === 'function' ? fieldName(`${namePrefix}[${modalOpenIndex}]`) : fieldName)}
									{...fieldProps}
									handleBlur={handleBlur}
								/>
							</FlexGrid>
						)
					}
				</FlexGrid>
				<Button
					type='submit'
				>{t('global.continue')}</Button>
			</form>
		</CardModal>
	</>
}

function OneOf({
	style = '',
	options = [],
	fieldProps = {},
}) {
	const { t } = useTranslation();

	return <>
		<Typo
			component='p'
			variant='regular'
			css={{
				color: palette.blue[200],
				marginBottom: spacing(2),
			}}
		>{t('stepper.selectOption')}</Typo>
		<FlexGrid
			container
			spacing={2}
			css={{
				marginBottom: spacing(4),
			}}
		>
			{
				options.map((option, optionIndex) =>
					<FlexGrid
						key={optionIndex}
						xs={12}
						{...(options.length <= 3 && style !== 'row') && {
							sm: true,
						}}
					>
						<SelectableCardButton
							key={optionIndex}
							{...fieldProps}
							htmlValue={option.value}
							label={option.label}
							description={option.description}
							{...(options.length > 3 || style === 'row') && {
								textAlign: 'left',
							}}
							{...(options.length > 3) && {
								size: 'normal',
							}}
						/>
					</FlexGrid>
				)
			}
		</FlexGrid>
		{
			(fieldProps.error && fieldProps.triedSubmit) &&
			<Error
				css={{ marginBottom: spacing(4), }}
			>{t('stepper.selectOptionError')}</Error>
		}
	</>
}

function AnyOf({
	optional = false,
	style = '',
	options = [],
	fieldProps = {},
}) {
	const { t } = useTranslation();

	return <>
		<Typo
			component='p'
			variant='regular'
			css={{
				color: palette.blue[200],
				marginBottom: spacing(2),
			}}
		>{t('stepper.selectOptions')}</Typo>
		<FlexGrid
			container
			spacing={2}
			css={{
				marginBottom: spacing(4),
			}}
		>
			{
				options.map((option, optionIndex) =>
					<FlexGrid
						key={optionIndex}
						xs={12}
					// {...(options.length > 2) && {
					// 	sm: true,
					// }}
					>
						<CheckboxCardButton
							key={optionIndex}
							{...fieldProps}
							htmlValue={option.value}
							label={option.label}
							description={option.description}
							// {...options.length <= 2 && {
							// 	size: 'normal',
							// 	textAlign: 'left',
							// }}
							size={'normal'}
							textAlign={'left'}
						/>
					</FlexGrid>
				)
			}
			{
				optional &&
				<FlexGrid
					xs={12}
				>
					<div
						css={{
							paddingTop: spacing(4),
							height: '100%',
							boxSizing: 'border-box',
							position: 'relative',
							'&:before': {
								content: '""',
								position: 'absolute',
								bottom: 'calc(100% - 6px)',
								left: '50%',
								width: `${(100 * (3 / 4)).toFixed(2)}%`,
								height: '1px',
								transform: 'translateX(-50%)',
								borderTop: '1px solid',
								borderColor: palette.gray[300],
							}
						}}
					>
						<CheckboxCardButton
							{...fieldProps}
							htmlValue='n/a'
							label={t('stepper.noOption')}
							// {...options.length <= 2 && {
							// 	size: 'normal',
							// 	textAlign: 'left',
							// }}
							size={'normal'}
							textAlign={'left'}
						/>
					</div>
				</FlexGrid>
			}
		</FlexGrid>
		{
			(fieldProps.error && fieldProps.triedSubmit) &&
			<Error
				css={{ marginBottom: spacing(4), }}
			>{t('stepper.selectOptionsError')}</Error>
		}
	</>
}

function ModalsArray({
	name = '',
	emptyText = '',
	addButtonText = '',
	itemPreviewContent = '',
	maxLength = 0,
	fields = [],
	defaultValue = null,
	data = null,
	handleAdd = () => { },
	handleRemove = () => { },
	handleChange = () => { },
	handleBlur = () => { },
	updateFormErrors = () => { },
	errors = null,
	triedSubmit = false,
	getFieldProps = () => { },
}) {
	return <ModalsArrayHandler
		handleAdd={handleAdd}
		handleRemove={handleRemove}
		defaultValue={defaultValue}
		name={name}
		updateFormErrors={updateFormErrors}
		currentLength={_get(data, name)?.length || 0}
	>
		{
			({ modalIndex: modalOpenIndex, setModal: openModal, closeModal, handleAdd, handleRemove, currentLength }) =>
				<ModalsList
					data={data}
					errors={errors}
					emptyText={emptyText}
					addButtonText={addButtonText}
					itemPreviewContent={itemPreviewContent}
					maxLength={maxLength}
					fields={fields}
					namePrefix={name}
					modalOpenIndex={modalOpenIndex}
					currentLength={currentLength}
					openModal={openModal}
					closeModal={closeModal}
					handleAdd={handleAdd}
					handleRemove={handleRemove}
					handleChange={handleChange}
					handleBlur={handleBlur}
					updateFormErrors={updateFormErrors}
					triedSubmit={triedSubmit}
					getFieldProps={getFieldProps}
				/>
		}
	</ModalsArrayHandler>
}