/** @jsxImportSource @emotion/react */

import { ReactComponent as Check } from "@material-design-icons/svg/filled/check.svg";
import React from "react";
import { ReactComponent as WarningSign } from '../../assets/icons/warningSign.svg';
import { isExternalLink } from "../../lib/links";
import { mq, palette, spacing } from "../../theme";
import { Button } from "../ui/Button";
import { CardBox } from "../ui/CardBox";
import { IconCircleWrapper } from "../ui/IconCircleWrapper";
import { LinkButton } from "../ui/LinkButton";
import { Svg } from "../ui/Svg";
import { Typo } from "../ui/Typo";

const imageSizes = {
	tiny: [
		{ shorthand: 'xs', value: '52px', },
		{ shorthand: 'sm', value: '64px', },
	],
	small: [
		{ shorthand: 'xs', value: '78px', },
		{ shorthand: 'sm', value: '96px', },
	],
	medium: [
		{ shorthand: 'xs', value: '104px', },
		{ shorthand: 'sm', value: '128px', },
	],
	large: [
		{ shorthand: 'xs', value: '208px', }, // h-52
		{ shorthand: 'sm', value: '256px', }, // h-64
	],
};

function getImageSizes(size, property) {
	const sizes = imageSizes[size];

	const results = {};

	sizes.forEach(({ shorthand, value }) => {
		shorthand === 'xs'
			?
			results[property] = value
			:
			results[mq(shorthand)] = {
				[property]: value,
			}
	});

	return results;
}

/**
 * A card-like component based on `CardBox`
 * 
 * @param {object} props Object containing the props:
 * - `props.icon` Defines the icon to show.
 * Accepts shorthands `valid`, `invalid`, or the icon itself
 * - `props.disabled` Disable the buttons/links and apply style.
 * - `props.title` Defines the title text content, should be a `string`.
 * - `props.titleComponent` Defines the title component.
 * Defaults to `'h3'`.
 * - `props.titleIcon` Defines an icon to put after the title content.
 * - `props.image` Defines an image to show.
 * - `props.imageSize` Defines the size preset of the image, based on a map.
 * Accepts `'tiny'`, `'small'`, `'medium'`, `'large'`.
 * Defaults to `'large'`,
 * - `props.imageLayout` Defines the layout of the image.
 * Accepts `'row'`, `'column'`.
 * Defaults to `'column'`.
 * - `props.note` Defines an element shown above the image, ignored if no `image`.
 * - `props.buttons` Defines the buttons to show.
 * Should be an `array` of objects with the following properties:
 * `label`, `type = 'link'`, `props = { variant: 'underlined' }`, `handleClick` (for button), `path` (for links).
 * - `...props` any other props will be passed.
 * @returns {any} The component
 */
export function TextCard({
	icon = null,
	disabled = false,
	title = '',
	titleComponent = 'h3',
	titleIcon = null,
	image = null,
	imageSize = 'large',
	imageLayout = 'column',
	note = '',
	buttons = [],
	children,
	...props
}) {
	return <CardBox
		display={(image && imageLayout === 'row') ? 'row' : 'column'}
		css={{
			display: 'flex',
			flexDirection: (image && imageLayout === 'row') ? 'row' : 'column',
			...((isExternalLink(props.to)) /* && !buttonLabel */) && { borderStyle: 'dashed', },
			...disabled && { opacity: 0.5, },
		}}
		{...isExternalLink(props.to) && { hideExternalIcon: true, }}
		{...props}
	>
		{
			!!icon && (
				icon === 'invalid'
					?
					<IconCircleWrapper
						backgroundColor={palette.yellow[500]}
						css={{
							margin: spacing(4),
							position: 'absolute',
							top: 0,
							right: 0,
						}}
					>
						<WarningSign />
					</IconCircleWrapper>
					: icon === 'valid' ?
						<IconCircleWrapper
							css={{
								margin: spacing(4),
								position: 'absolute',
								top: 0,
								right: 0,
							}}
						>
							<Svg svg={Check} />
						</IconCircleWrapper>
						: icon
			)
		}
		{
			image &&
			<div
				css={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					...imageLayout === 'row' && {
						marginRight: (imageSize === 'tiny' || imageSize === 'small') ? spacing(4) : spacing(8),
					},
					...!!note && {
						position: 'relative',
						paddingTop: spacing(6),
					}
				}}
			>
				{
					!!note &&
					<Typo
						component='p'
						variant='small'
						css={{
							position: 'absolute',
							top: 4,
							left: '50%',
							transform: 'translateX(-50%)',
							width: '100%',
							textAlign: 'center',
							color: palette.blue[400],
						}}
					>{note}</Typo>
				}
				<div
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						...getImageSizes(imageSize, imageLayout === 'row' ? 'width' : 'height'),
					}}
				>
					<img
						src={image}
						alt={title}
						css={{
							flexShrink: 0, // IE11 fix for images in flex
						}}
					/>
				</div>
			</div>
		}
		<div
			css={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			{
				title &&
				<Typo
					component={titleComponent}
					variant='title-2'
					css={{
						marginBottom: spacing(2),
						textAlign: 'left',
					}}
				>{title}{titleIcon}</Typo>
			}
			<div>
				{children}
			</div>
			{
				buttons.length > 0 &&
				<div
					css={{
						paddingTop: spacing(4),
						marginTop: 'auto',
						justifySelf: 'flex-end',
						alignSelf: imageLayout === 'row' ? 'flex-start' : 'auto',
					}}
				>
					{
						buttons.map(({
							label: buttonLabel = '',
							type: buttonType = 'link',
							props: buttonProps = { variant: 'underlined' },
							handleClick: buttonHandleClick = () => { },
							path: buttonPath = '',
						}, buttonIndex) =>
							<React.Fragment
								key={buttonIndex}
							>
								{
									buttonType === 'button' ?
										<Button
											disabled={disabled}
											onClick={buttonHandleClick}
											{...buttonProps}
											{...(buttonIndex !== 0) && { css: { marginTop: spacing(2), }, }}
										>
											{buttonLabel}
										</Button>
										:
										<LinkButton
											to={disabled ? '#' : buttonPath}
											{...buttonProps}
											{...(buttonIndex !== 0) && { css: { marginTop: spacing(2), }, }}
										>{buttonLabel}</LinkButton>
								}
							</React.Fragment>
						)
					}
				</div>
			}
		</div>
	</CardBox>
}