/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react';
import { typography } from "../../theme";

/**
 * A UI component to define the component and variant of text.
 * 
 * @param {object} props Object containing the props:
 * - `props.component` Defines the component of the element.
 * Defaults to `'span'`.
 * - `props.variant` Defines the style variant of the typography.
 * Can be either a variant's name as defined in the theme, or `'inherit'` to inherit from the DOM.
 * Defaults to `'inherit'`.
 * - `props.children` The element can contains children.
 * - `...props` Any other props will be passed.
 * @returns {any} The component.
 */
export function Typo({
	component = 'span',
	variant = 'inherit',
	children,
	...props
}) {
	return jsx(
		component,
		{
			...variant !== 'inherit' && {
				css: {
					...typography[variant],
				},
			},
			...props,
		},
		children
	)
}