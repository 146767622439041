/** @jsxImportSource @emotion/react */

import { jsx } from "@emotion/react";

export function Svg({ fontSize = 'medium', svg, ...props }) {

	return jsx(
		svg,
		{
			css: {
				userSelect: 'none',
				width: '1em',
				height: '1em',
				display: 'inline-block',
				fill: 'currentColor',
				flexShrink: 0,
				fontSize: {
					inherit: 'inherit',
					small: '1.25rem',
					medium: '1.5rem',
					large: '2.1875rem',
				}[fontSize],
			},
			...props,
		}
	);
}