/** @jsxImportSource @emotion/react */

import mergeWith from "lodash.mergewith";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching, useIsMutating, useMutation, useQueryClient } from "react-query";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ErrorContext } from "../../contexts/Error";
import { useFormShorthands, useFormUpdaters } from "../../hooks/form";
import { useValueHistory } from "../../hooks/valueHistory";
import { getAuthToken } from "../../lib/authToken";
import { getEntity } from "../../lib/entities";
import { getLocalStorageData } from "../../lib/localStorage";
import { replaceArrays } from "../../lib/mergeWithCustomizers";
import { AuthenticationCard } from "../authentication/AuthenticationCard";
import { LoadingModal } from "../LoadingModal";
import { Button } from "../ui/Button";
import { Container } from "../ui/Container";
import { Typo } from "../ui/Typo";
import { useCurrentStep } from "./hooks";
import { StepperQuestion } from "./StepperQuestion";
import { StepperStep } from "./StepperStep";
import { getStepValidity } from "./utils";

const getErrorModalProps = ({ t, setAppError }) => ({
	onClose: () => setAppError(null),
	header: <Typo
		component='p'
		variant='title-1'
	>{t('global.errorOccurred')}</Typo>,
	footer: <Button
		onClick={() => setAppError(null)}
		variant='contained'
	>{t('global.close')}</Button>,
	children: <Typo
		component='p'
		variant='regular'
	>{t('stepper.endCallbackFailed')}</Typo>,
});

export function Stepper({
	save = null,
	queryKey = '',
	storeData,
	steps = [],
	data = {},
	setData = () => { },
	defaultData = {},
	errors = null,
	updateFormErrors = async () => { },
	getFormErrors = async () => { },
	getFieldValues = () => { },
	allowInvalid = true,
	validEndRedirect = '',
	invalidEndRedirect = '',
	needAuth = false,
	endCallback = async () => { return true; }, // TODO: for now only available when `needAuth === false`, as for now it is used to replace the automatic data save
}) {
	const { t } = useTranslation();

	// Queries loading are not (yet?) handled case by case, show a loading modal whenever there is a request
	const isFetching = useIsFetching();
	const isMutating = useIsMutating();

	const { state } = useLocation();
	const previousLocation = state?.from || '';

	const { currentStepIndex, currentStepRoute } = useCurrentStep(steps);

	const [triedSubmits, setTriedSubmits] = useState([]);

	const {
		handleChange,
		handleArrayAdd: handleAdd,
		handleArrayRemove: handleRemove,
	} = useFormUpdaters({
		setData: setData,
	});

	const {
		getFieldProps,
	} = useFormShorthands({
		handleChange: handleChange,
		updateErrors: updateFormErrors,
		triedSubmit: triedSubmits.includes(currentStepIndex),
		getFieldValues: getFieldValues,
	});

	useEffect(() => {
		updateFormErrors();
	}, []);

	// Redirect to previous step if condition of current one is invalid (for direct route access)
	// TODO: This is dirty but it avoids direct access to routes locked behind invalid conditions. Good enough for now as it is purely a safeguard, but should find a better way to handle it
	const [shouldCheckCondition, setShouldCheckCondition] = useState(false);
	useEffect(() => {
		if (!shouldCheckCondition) return;

		if (steps[currentStepIndex]?.condition && !steps[currentStepIndex].condition(data)) {
			navigateStep(false);
		}

		setShouldCheckCondition(false);
	}, [shouldCheckCondition, steps, storeData]);

	// Load user data
	useEffect(() => {
		if (!storeData && storeData !== null) return; // `undefined` if not given (no store) or not loaded yet, `null` if loaded but nothing to load

		setData(mergeWith({}, defaultData, storeData, replaceArrays));

		updateFormErrors(true);

		setShouldCheckCondition(true);
	}, [setData, storeData]);

	const navigate = useNavigate();

	const stepsIndexesHistory = useValueHistory(currentStepIndex);
	const previousStepIndex = stepsIndexesHistory.length >= 2 ? stepsIndexesHistory[stepsIndexesHistory.length - 2] : 0;

	const [shouldEmulateSubmit, setShouldEmulateSubmit] = useState(false);
	useEffect(() => {
		if (!shouldEmulateSubmit) return;

		handleSubmit();
		setShouldEmulateSubmit(false);
	}, [shouldEmulateSubmit, setShouldEmulateSubmit]);

	function handleButtonClick(data) {
		handleChange(data);

		updateFormErrors(true);

		setTimeout(() => {
			// The submit should launch after `setData` (that's why we go through a `setState`->`useEffect` cycle)
			// It *should* work without the `setTimeout` which is unrelated
			setShouldEmulateSubmit(true);
		}, 250); // Delay is here for UX purpose (feedback/waiting otherwise it is too fast for the user)
	}

	const [submitting, setSubmitting] = useState(false);
	async function handleSubmit(event) {
		event?.preventDefault();

		if (submitting) return;
		setSubmitting(true);

		const errors = await getFormErrors(); // `errors` state not up to date

		if (!triedSubmits.includes(currentStepIndex)) {
			// If step's first try

			// Keep track of which steps are tried
			setTriedSubmits(prevState => [currentStepIndex, ...prevState]);

			if ( // Since it is the step's first try, do not navigate if...
				!getStepValidity(steps[currentStepIndex], errors) // ...the current step is invalid
				|| (currentStepIndex >= lastStepIndex && errors) // ...the current step is the last one and if there are ANY errors
			) {
				if (save) save(data);

				setSubmitting(false);
				return;
			}
		}

		setSubmitting(false);
		navigateStep(true);
	}

	function handleBack() {
		navigateStep(false);
	}

	function handleNext() {
		navigateStep(true);
	}

	const lastStepIndex = (function () {
		let newIndex = 0;
		for (let index = (steps.length - 1); index >= 0; index--) {
			const step = steps[index];

			if (!step.condition || step.condition(data)) {
				newIndex = index;
				break;
			}
		}

		return newIndex;
	})();

	const [biggestStepIndex, setBiggestStepIndex] = useState(0);
	useEffect(() => {
		if (currentStepIndex > biggestStepIndex) setBiggestStepIndex(currentStepIndex);
	}, [currentStepIndex, setBiggestStepIndex]);

	const { setError: setAppError } = useContext(ErrorContext);

	const [endCallbackLoading, setEndCallbackLoading] = useState(false);
	async function navigateStep(forward = true) {
		// Save (server or localStorage), and save the biggest step index
		if (save) save({ ...data, stepper_data: { ...data.stepper_data, biggest_step_index: biggestStepIndex } }); // TODO: This does not save when using the browser's back/forward buttons

		if (forward && currentStepIndex >= lastStepIndex) {
			// If last step going forward

			if (!!errors && !allowInvalid) {
				// Errors but not allowed -> stay on page
				return;
			}

			if (!needAuth || !!getAuthToken()) {
				// Logged in or auth not needed

				if (!needAuth) {
					if (endCallbackLoading) return;
					setEndCallbackLoading(true);
					
					const response = await endCallback();

					setEndCallbackLoading(false);

					if (!response) {
						setAppError(getErrorModalProps({ t, setAppError }))
						return;
					}
				}

				navigate((!errors ? validEndRedirect : invalidEndRedirect) || '/account', { state: { from: currentStepRoute } });

				return;
			}

			// Not logged in and auth needed
			navigate('authentication', { state: { from: currentStepRoute } });

			return;
		}

		let nextStep = null;
		let nextStepIndex = null;
		let stepFound = false;

		for (
			let stepIndex = (currentStepIndex + (forward ? 1 : -1));
			(forward ? stepIndex < steps.length : stepIndex >= 0);
			(forward ? stepIndex++ : stepIndex--)
		) {
			const step = steps[stepIndex];
			if (!step) break;

			if (!step.condition || step.condition(data)) {
				nextStep = step;
				nextStepIndex = stepIndex;
				stepFound = true;
				break;
			}
		}

		if (stepFound) {
			steps[nextStepIndex]?.route === previousLocation ? navigate(-1, { state: { from: currentStepRoute } }) : navigate(nextStep.route, { state: { from: currentStepRoute } });
		}
	}

	const queryClient = useQueryClient();

	const entityUpdateMutation = useMutation(({ updater = async () => { }, data }) => {
		return updater({ data });
	});

	async function saveGuestData() {
		if (!save) return true;

		// Save every used entities, then recover their uuid
		// Then replace their old fake/local uuid in the main data, then save it

		let dataJson = JSON.stringify(data);

		const entityNames = [...new Set( // Delete potential duplicates by doing Array -> Set -> Array 
			steps.flatMap(step => step.items.filter(item => item.type === 'entitiesArray').map(item => item.props?.entityName))
				.filter(i => !!i)
		)];

		try {
			// For every type of entities
			await Promise.all(entityNames.map(async entityName => {
				const {
					queryKey: entityQueryKey,
					updater: entityUpdater,
				} = getEntity(entityName);

				const entities = await getLocalStorageData(entityQueryKey, []);

				// Saving all the used entities
				const uuidsPairs = await Promise.all(entities.map(async entity => {
					const localUuid = entity.uuid;
					if (!localUuid || !dataJson.includes(localUuid)) return null; // We don't need to save the entity if it is not used

					const savedEntity = await entityUpdateMutation.mutateAsync({ updater: entityUpdater, data: entity, entityQueryKey }, {
						onSettled: () => {
							queryClient.invalidateQueries(entityQueryKey);
						},
					});

					const savedUuid = savedEntity.uuid;

					if (!savedUuid) throw new Error(`Entity with local uuid '${localUuid}' was not saved or did not return a remote uuid`);

					return { from: localUuid, to: savedUuid };
				}));

				uuidsPairs.filter(i => !!i).forEach(({ from, to }) => {
					dataJson = dataJson.replaceAll(from, to);
				});

				// Remove entities from localStorage now that they are saved
				localStorage?.removeItem(entityQueryKey);
			}));

			// All occurences of local uuids in `dataJson` are now replaced
			// TODO: Check if parsing the data causes any problem (it will be re-stringified later on)
			const response = await save(JSON.parse(dataJson));

			if (!response) throw new Error(`Service was not saved`);

			localStorage?.removeItem(queryKey);

			return true;
		} catch (error) {
			// TODO: Handle error better than here (what happens if part of data is saved etc.)
			console.error(error);

			return false;
		}
	}

	return <>
		<Container fixed={false}>
			<Routes>
				<Route
					path='*'
					element={<Navigate to={steps[0]?.route || '#'} replace />}
				/>
				{
					steps.map((step, stepIndex) =>
						<Route
							key={step.route}
							path={step.route}
							index={stepIndex === 0}
							element={
								<StepperStep
									key={step.route} // Force differenciation for css animation
									title={step.title}
									subtitle={step.subtitle}
									image={step.img}
									animateForwards={previousStepIndex <= currentStepIndex}
									data={data}
									handleBack={stepIndex !== 0 && handleBack}
								>
									{
										<StepperQuestion
											data={data}
											errors={errors}
											value={step.value}
											items={step.items}
											handleButtonClick={handleButtonClick}
											handleChange={handleChange}
											handleAdd={handleAdd}
											handleRemove={handleRemove}
											handleSubmit={handleSubmit}
											updateFormErrors={updateFormErrors}
											triedSubmit={triedSubmits.includes(stepIndex)}
											getFieldProps={getFieldProps}
											{...stepIndex >= lastStepIndex ? {
												lastStep: true,
												canContinue: errors === null,
											} : {
												canContinue: getStepValidity(step, errors),
											}}
											invalidStepperAllowed={allowInvalid}
											steps={steps}
											handleBack={handleBack}
											handleNext={handleNext}
										/>
									}
								</StepperStep>
							}
						/>
					)
				}
				{
					needAuth &&
					<Route
						path={'authentication'}
						element={<StepperStep
							title={({ t }) => t('stepper.authentication.title')}
							subtitle={({ t }) => t('stepper.authentication.subtitle')}
							animateForwards={previousStepIndex <= currentStepIndex}
							handleBack={handleBack}
						>
							<AuthenticationCard
								to={(!errors ? validEndRedirect : invalidEndRedirect) || '/account'}
								typesBlacklist={['login', 'forgot']}
								successCallback={saveGuestData}
							/>
						</StepperStep>}
					/>
				}
			</Routes>
		</Container>
		<LoadingModal
			open={!!isFetching || !!isMutating}
		/>
	</>
}