/** @jsxImportSource @emotion/react */

import { palette } from "../../theme";

/**
 * A UI component to use for wrapping an icon in a disc.
 * 
 * @param {object} props Object containing the props:
 * - `props.wrapperSize` A shorthand for the width & height style properties of the wrapper.
 * Defaults to `24`
 * - `props.iconSize` A shorthand for the width & height style properties of the icon.
 * Defaults to `16`
 * - `props.backgroundColor` A shorthand for the backgroundColor style.
 * Defaults to `palette.green[500]`
 * - `props.color` A shorthand for the color style.
 * Defaults to `'white'`
 * - `props.ariaHidden` Defines if `aria-hidden` should be set.
 * Defaults to `true`
 * - `props.children` The element can contains children (the icon).
 * - `...props` Any other props will be passed.
 * @returns {any} The component
 */
export function IconCircleWrapper({
	wrapperSize = 24,
	iconSize = 16,
	backgroundColor = palette.green[500],
	color = 'white',
	ariaHidden = true,
	children,
	...props
}) {

	return <div
		aria-hidden={ariaHidden}
		css={{
			width: wrapperSize,
			height: wrapperSize,
			backgroundColor: backgroundColor,
			borderRadius: '100%',
			color: color,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& svg': {
				width: iconSize,
				height: iconSize,
			},
		}}
		{...props}
	>
		{children}
	</div>
}