/** @jsxImportSource @emotion/react */

import { ReactComponent as Info } from "@material-design-icons/svg/filled/info.svg";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { postFuneralDecision } from "../../../../../api/funeralDecision";
import { updateService } from "../../../../../api/service";
import signImg from '../../../../../assets/illustrations/sign.png';
import { Tutorial } from "../../../../../components/common/Tutorial";
import { InsuranceDataPresentation } from "../../../../../components/dataPresentation/Insurance";
import { ContainerWithSidebar } from "../../../../../components/display/ContainerWithSidebar";
import { Stepper } from "../../../../../components/stepper/Stepper";
import { StepperMenu } from "../../../../../components/stepper/StepperMenu";
import { Button } from "../../../../../components/ui/Button";
import { CardBox } from "../../../../../components/ui/CardBox";
import { CollapseCard } from "../../../../../components/ui/CollapseCard";
import { Container } from "../../../../../components/ui/Container";
import { ScrollDisplay } from "../../../../../components/ui/ScrollDisplay";
import { Svg } from "../../../../../components/ui/Svg";
import { TransListItems, TransParagraphs } from "../../../../../components/ui/Trans";
import { Typo } from "../../../../../components/ui/Typo";
import { INSURANCE__DATA as ENTITY__INSURANCE__DATA, INSURANCE__DATA__SCHEMA as ENTITY__INSURANCE__DATA__SCHEMA } from "../../../../../data/entities/insurance";
import { FUNERAL_DECISION__DATA as SERVICE__FUNERAL_DECISION__DATA, FUNERAL_DECISION__DATA__SCHEMA as SERVICE__FUNERAL_DECISION__DATA__SCHEMA } from "../../../../../data/services/funeralDecision";
import { useConditionValidatedOnce } from "../../../../../hooks/conditionValidatedOnce";
import { useFormValues } from "../../../../../hooks/form";
import { useMediaQuery } from "../../../../../hooks/mediaQuery";
import { isFalse } from "../../../../../lib/conditions";
import { getLocalStorageData, setLocalStorageData } from "../../../../../lib/localStorage";
import { parseCustomMarkdown } from "../../../../../lib/parser";
import { mq, palette, spacing, typography } from "../../../../../theme";

const defaultData = SERVICE__FUNERAL_DECISION__DATA;
const dataSchema = SERVICE__FUNERAL_DECISION__DATA__SCHEMA;

const insuranceDefaultData = ENTITY__INSURANCE__DATA;
const insuranceDataSchema = ENTITY__INSURANCE__DATA__SCHEMA;

const steps = [
	{
		route: 'intro',
		label: ({ t }) => t('funeralDecision.steps.intro.label'),
		title: ({ t }) => t('funeralDecision.steps.intro.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<TransParagraphs
							i18nKey={'funeralDecision.steps.intro.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],

	},
	{
		route: 'needs',
		label: ({ t }) => t('funeralDecision.steps.needs.label'),
		title: ({ t }) => t('funeralDecision.steps.needs.title'),
		items: [
			{
				type: 'content',
				props: {
					content: ({ handleNext, t }) => <CardBox>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('funeralDecision.steps.needs.list.title')}</Typo>
						<Typo
							component='ul'
							variant='regular'
							css={{
								listStyle: 'disc',
								paddingLeft: spacing(6),
								marginBottom: spacing(4),
								'& li+li': {
									marginTop: spacing(1),
								},
							}}
						>
							<TransListItems
								i18nKey={'funeralDecision.steps.needs.list.items'}
								t={t}
								useMarkdown={true}
							/>
						</Typo>
						<Typo
							component='p'
							variant='regular'
							css={{
								textDecoration: 'underline',
								marginBottom: spacing(2),
							}}
						>{t('funeralDecision.steps.needs.missingInfo.title')}</Typo>
						<TransParagraphs
							i18nKey={'funeralDecision.steps.needs.missingInfo.content'}
							t={t}
							useMarkdown={true}
						/>
						<Button
							onClick={handleNext}
							css={{ marginTop: spacing(4), }}
						>{t('global.start')}</Button>
					</CardBox>,
				},
			},
		],
	},
	{
		route: 'decision_type',
		label: ({ t }) => t('funeralDecision.steps.decisionType.label'),
		title: ({ t }) => t('funeralDecision.steps.decisionType.title'),
		items: [
			{
				type: 'oneOf',
				name: 'decision_type',
				props: {
					style: 'row',
					options: [
						{ value: 'declaration', label: 'funeralDecision.steps.decisionType.form.declaration' },
						{ value: 'change', label: 'funeralDecision.steps.decisionType.form.change', description: 'funeralDecision.steps.decisionType.form.changeDescription' },
						{ value: 'withdrawal', label: 'funeralDecision.steps.decisionType.form.withdrawal', description: 'funeralDecision.steps.decisionType.form.withdrawalDescription' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.decision_type === 'declaration' ? t('funeralDecision.steps.decisionType.preview.declaration')
			: data.decision_type === 'change' ? t('funeralDecision.steps.decisionType.preview.change')
				: data.decision_type === 'withdrawal' ? t('funeralDecision.steps.decisionType.preview.withdrawal')
					: '',
	},
	{
		depth: 1,
		route: 'previous_town',
		label: ({ t }) => t('funeralDecision.steps.previousTown.label'),
		title: ({ t }) => t('funeralDecision.steps.previousTown.title'),
		subtitle: ({ t }) => t('funeralDecision.steps.previousTown.subtitle'),
		items: [
			{
				type: 'field',
				name: 'previous_town',
				props: {
					type: 'text',
					label: 'form.address.town',
				},
			},
		],
		preview: ({ data, t }) => data.previous_town,
		condition: (data) => data.decision_type === 'change' || data.decision_type === 'withdrawal',
	},
	{
		route: 'funeral_type',
		label: ({ t }) => t('funeralDecision.steps.funeralType.label'),
		title: ({ t }) => t('funeralDecision.steps.funeralType.title'),
		items: [
			{
				type: 'oneOf',
				name: 'funeral.type',
				props: {
					options: [
						{ value: 'burial', label: 'funeralDecision.steps.funeralType.form.burial' },
						{ value: 'cremation', label: 'funeralDecision.steps.funeralType.form.cremation' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.funeral.type === 'burial' ? t('funeralDecision.steps.funeralType.preview.burial')
			: data.funeral.type === 'cremation' ? t('funeralDecision.steps.funeralType.preview.cremation')
				: '',
		condition: (data) => data.decision_type !== 'withdrawal',
	},
	{
		depth: 1,
		route: 'cremation_type',
		label: ({ t }) => t('funeralDecision.steps.cremationType.label'),
		title: ({ t }) => t('funeralDecision.steps.cremationType.title'),
		items: [
			{
				type: 'oneOf',
				name: 'funeral.cremation.type',
				props: {
					options: [
						{ value: 'burial_cemetery', label: 'funeralDecision.steps.cremationType.form.burialCemetery' },
						{ value: 'conservation_cemetery_columbarium', label: 'funeralDecision.steps.cremationType.form.conservationCemeteryColumbarium' },
						{ value: 'scattering_cemetery_lawn', label: 'funeralDecision.steps.cremationType.form.scatteringCemeteryLawn' },
						{ value: 'scattering_sea', label: 'funeralDecision.steps.cremationType.form.scatteringSea' },
						{ value: 'scattering_other_place', label: 'funeralDecision.steps.cremationType.form.scatteringOtherPlace' },
						{ value: 'burial_other_place', label: 'funeralDecision.steps.cremationType.form.burialOtherPlace' },
						{ value: 'conservation_other_place', label: 'funeralDecision.steps.cremationType.form.conservationOtherPlace' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.funeral.cremation.type === 'burial_cemetery' ? t('funeralDecision.steps.cremationType.preview.burialCemetery')
			: data.funeral.cremation.type === 'conservation_cemetery_columbarium' ? t('funeralDecision.steps.cremationType.preview.conservationCemeteryColumbarium')
				: data.funeral.cremation.type === 'scattering_cemetery_lawn' ? t('funeralDecision.steps.cremationType.preview.scatteringCemeteryLawn')
					: data.funeral.cremation.type === 'scattering_sea' ? t('funeralDecision.steps.cremationType.preview.scatteringSea')
						: data.funeral.cremation.type === 'scattering_other_place' ? t('funeralDecision.steps.cremationType.preview.scatteringOtherPlace')
							: data.funeral.cremation.type === 'burial_other_place' ? t('funeralDecision.steps.cremationType.preview.burialOtherPlace')
								: data.funeral.cremation.type === 'conservation_other_place' ? t('funeralDecision.steps.cremationType.preview.conservationOtherPlace')
									: '',
		condition: (data) => data.decision_type !== 'withdrawal'
			&& data.funeral.type === 'cremation',
	},
	{
		depth: 1,
		route: 'funeral_other_place',
		label: ({ t }) => t('funeralDecision.steps.funeralOtherPlace.label'),
		title: ({ t }) => t('funeralDecision.steps.funeralOtherPlace.title'),
		subtitle: ({ t }) => t('funeralDecision.steps.funeralOtherPlace.subtitle'),
		items: [
			{
				type: 'field',
				name: 'funeral.other_place',
				props: {
					type: 'text',
					label: 'funeralDecision.steps.funeralOtherPlace.form.label',
				},
			},
		],
		preview: ({ data, t }) => data.funeral.other_place,
		condition: (data) => data.decision_type !== 'withdrawal'
			&& data.funeral.type === 'cremation'
			&& (data.funeral.cremation.type === 'scattering_other_place' || data.funeral.cremation.type === 'burial_other_place' || data.funeral.cremation.type === 'conservation_other_place'),
	},
	{
		depth: 1,
		route: 'funeral_town',
		label: ({ t }) => t('funeralDecision.steps.funeralTown.label'),
		title: ({ t }) => t('funeralDecision.steps.funeralTown.title'),
		subtitle: ({ t }) => t('funeralDecision.steps.funeralTown.subtitle'),
		items: [
			{
				type: 'field',
				name: 'funeral.town',
				props: {
					type: 'text',
					label: 'form.address.town',
				},
			},
		],
		preview: ({ data, t }) => data.funeral.town,
		condition: (data) => data.decision_type !== 'withdrawal'
			&& (data.funeral.type !== 'cremation'
				|| (data.funeral.cremation.type !== 'scattering_other_place' && data.funeral.cremation.type !== 'burial_other_place' && data.funeral.cremation.type !== 'conservation_other_place')),
	},
	{
		route: 'has_funeral_contract',
		label: ({ t }) => t('funeralDecision.steps.hasFuneralContract.label'),
		title: ({ t }) => t('funeralDecision.steps.hasFuneralContract.title'),
		img: signImg,
		items: [
			{
				type: 'oneOf',
				name: 'has_funeral_contract',
				props: {
					options: [
						{ value: 'no', label: 'global.no' },
						{ value: 'yes', label: 'global.yes' },
					],
				},
			},
		],
		preview: ({ data, t }) => data.has_funeral_contract === 'yes' ? t('global.yes')
			: data.has_funeral_contract === 'no' ? t('global.no')
				: '',
		condition: (data) => data.decision_type !== 'withdrawal',
	},
	{
		depth: 1,
		route: 'funeral_contracts',
		label: ({ t }) => t('funeralDecision.steps.funeralContracts.label'),
		title: ({ t }) => t('funeralDecision.steps.funeralContracts.title'),
		items: [
			{
				type: 'entitiesArray',
				name: 'funeral_contracts',
				refName: 'insurance_uuid',
				props: {
					maxLength: 1,
					entityName: 'insurance',
					allowedTypes: [
						{ value: 'death', label: 'funeralDecision.steps.funeralContracts.form.deathInsurance' },
						{ value: 'funeral', label: 'funeralDecision.steps.funeralContracts.form.funeralInsurance' },
					],
					emptyText: ({ t }) => t('funeralDecision.steps.funeralContracts.form.emptyList'),
					addButtonText: ({ t }) => t('funeralDecision.steps.funeralContracts.form.add'),
					selectTypeText: ({ t }) => t('funeralDecision.steps.funeralContracts.form.selectType'),
					defaultValue: insuranceDefaultData,
					itemPreviewContent: ({ item, t }) => <InsuranceDataPresentation
						data={item}
						partsToShow={[
							'companyName',
							'contractNumber',
							'signatureDate',
						]}
					/>,
					fields: [
						{
							type: 'text',
							label: 'form.companyName',
							name: 'company.name',
							addRequired: true,
						},
						{
							type: 'text',
							label: 'form.contractNumber',
							name: 'contract_number',
						},
						{
							type: 'multipart_date',
							label: 'form.signatureDate',
							name: 'signature.date',
						},
					],
				},
			},
		],
		preview: ({ data, t }) => t('funeralDecision.steps.funeralContracts.preview', { count: data.funeral_contracts?.length || 0 }),
		condition: (data) => data.decision_type !== 'withdrawal'
			&& data.has_funeral_contract === 'yes',
	},
	{
		route: 'funeral_belief',
		label: ({ t }) => t('funeralDecision.steps.funeralBelief.label'),
		title: ({ t }) => t('funeralDecision.steps.funeralBelief.title'),
		items: [
			{
				type: 'field',
				name: 'funeral_belief',
				props: {
					type: 'select',
					label: 'Rite de la cérémonie',
					options: [
						{ value: 'none', label: 'funeralDecision.steps.funeralBelief.form.none' },
						{ value: 'secular', label: 'funeralDecision.steps.funeralBelief.form.secular' },
						{ value: 'catholic', label: 'funeralDecision.steps.funeralBelief.form.catholic' },
						{ value: 'protestant', label: 'funeralDecision.steps.funeralBelief.form.protestant' },
						{ value: 'anglican', label: 'funeralDecision.steps.funeralBelief.form.anglican' },
						{ value: 'orthodox', label: 'funeralDecision.steps.funeralBelief.form.orthodox' },
						{ value: 'jewish', label: 'funeralDecision.steps.funeralBelief.form.jewish' },
						{ value: 'muslim', label: 'funeralDecision.steps.funeralBelief.form.muslim' },
						{ value: 'neutral', label: 'funeralDecision.steps.funeralBelief.form.neutral' },
					],
				},
			},
			{
				type: 'content',
				props: {
					content: ({ t }) => <CollapseCard
						buttonContent={
							<span
								css={{ textAlign: 'left', ...typography['small'], }}
							>
								<Svg
									svg={Info}
									fontSize='small'
									css={{
										position: 'relative',
										top: '-1px',
										marginRight: spacing(2),
									}}
								/>
								{t('funeralDecision.steps.funeralBelief.form.infoBox.title')}
							</span>
						}
						css={{ padding: spacing(4), }}
					>
						<Typo
							component='ul'
							variant='small'
							css={{
								listStyle: 'disc',
								paddingLeft: spacing(4),
								'& li+li': {
									marginTop: spacing(1),
								},
							}}
						>
							<TransListItems
								i18nKey={'funeralDecision.steps.funeralBelief.form.infoBox.items'}
								t={t}
								useMarkdown={true}
							/>
						</Typo>
					</CollapseCard>,
				},
			},
		],
		preview: ({ data, t }) => data.funeral_belief === 'none' ? t('funeralDecision.steps.funeralBelief.preview.none')
			: data.funeral_belief === 'secular' ? t('funeralDecision.steps.funeralBelief.preview.secular')
				: data.funeral_belief === 'catholic' ? t('funeralDecision.steps.funeralBelief.preview.catholic')
					: data.funeral_belief === 'protestant' ? t('funeralDecision.steps.funeralBelief.preview.protestant')
						: data.funeral_belief === 'anglican' ? t('funeralDecision.steps.funeralBelief.preview.anglican')
							: data.funeral_belief === 'orthodox' ? t('funeralDecision.steps.funeralBelief.preview.orthodox')
								: data.funeral_belief === 'jewish' ? t('funeralDecision.steps.funeralBelief.preview.jewish')
									: data.funeral_belief === 'muslim' ? t('funeralDecision.steps.funeralBelief.preview.muslim')
										: data.funeral_belief === 'neutral' ? t('funeralDecision.steps.funeralBelief.preview.neutral')
											: '',
		condition: (data) => data.decision_type !== 'withdrawal',
	},
	// {
	// 	route: 'other_wills',
	// 	label: ({ t }) => t('funeralDecision.steps.otherWills.label'),
	// 	title: ({ t }) => t('funeralDecision.steps.otherWills.title'),
	// 	subtitle: ({ t }) => parseCustomMarkdown(t('funeralDecision.steps.otherWills.subtitle')),
	// 	items: [
	// 		{
	// 			type: 'field',
	// 			name: 'funeral_home',
	// 			props: {
	// 				type: 'text',
	// 				label: 'funeralDecision.steps.otherWills.form.funeralHome',
	// 			},
	// 		},
	// 		{
	// 			type: 'field',
	// 			name: 'other_will',
	// 			props: {
	// 				type: 'textarea',
	// 				label: 'funeralDecision.steps.otherWills.form.otherWill',
	// 				sublabel: 'funeralDecision.steps.otherWills.form.otherWillSublabel',
	// 				placeholder: 'funeralDecision.steps.otherWills.form.otherWillPlaceholder',
	// 			},
	// 		},
	// 	],
	// 	preview: ({ data, t }) => `${data.funeral_home ? t('funeralDecision.steps.otherWills.preview.funeralHome', { funeralHome: data.funeral_home }) : ''}${data.funeral_home && data.other_will ? ', ' : ''}${data.other_will ? t('funeralDecision.steps.otherWills.preview.otherWill') : ''}`,
	// 	condition: (data) => data.decision_type !== 'withdrawal',
	// },
	{
		route: 'contact_details',
		label: ({ t }) => t('funeralDecision.steps.contactDetails.label'),
		title: ({ t }) => t('funeralDecision.steps.contactDetails.title'),
		items: [
			{
				type: 'field',
				name: 'email',
				props: {
					type: 'email',
					label: 'form.email',
				},
			},
			{
				type: 'field',
				name: 'first_name',
				props: {
					label: 'form.firstName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'last_name',
				props: {
					label: 'form.lastName',
				},
				gridSizes: {
					sm: 6,
				},
			},
			{
				type: 'field',
				name: 'birth_date',
				props: {
					type: 'date',
					label: 'form.birthDate',
				},
				gridSizes: {
					sm: 6,
				},
			},
		],
		preview: ({ data, t }) => data.email,
	},
];

export function Funeral() {
	const { t } = useTranslation();

	// Store
	const { isLoading: insurancesLoading, data: insurances = [] } = useQuery('insurances',
		/* No need for remote for now
		() => !!getAuthToken()
			? fetchInsurances()
			: getLocalStorageData('insurances', [])
		*/
		() => getLocalStorageData('insurances', [])
	);
	const insurancesLoaded = useConditionValidatedOnce(insurancesLoading, isFalse);

	// const { isLoading: serviceFuneralDecisionLoading, data: serviceFuneralDecision } = useQuery('serviceFuneralDecision', () => fetchService({ type: 'funeral_decision' }));
	const { isLoading: serviceFuneralDecisionLoading, data: serviceFuneralDecision } = useQuery('serviceFuneralDecision',
		/* No need for remote for now
		() => !!getAuthToken()
			? fetchService({ type: 'funeral_decision' })
			: getLocalStorageData('funeral_decision')
		*/
		() => getLocalStorageData('funeral_decision')
	);
	const serviceFuneralDecisionLoaded = useConditionValidatedOnce(serviceFuneralDecisionLoading, isFalse);

	const serviceFuneralDecisionData = (serviceFuneralDecisionLoaded && insurancesLoaded) ? (serviceFuneralDecision?.data || null) : undefined;

	const queryClient = useQueryClient();

	const updateMutation = useMutation((data = defaultData) => {
		return updateService({ type: 'funeral_decision', data });
	}, {
		onSettled: () => {
			queryClient.invalidateQueries('serviceFuneralDecision');
		},
	});
	function save(data) {
		// if (!!getAuthToken()) return updateMutation.mutateAsync(data); // No need for remote for now

		setLocalStorageData({ id: 'funeral_decision', data: { data: data, }, });
	}

	const {
		data,
		setData,
		errors,
		getErrors,
		updateErrors,
		getFieldValues,
	} = useFormValues({
		defaultData,
		dataSchema,
		context: { insurances: insurances },
	});

	const postMutation = useMutation(({ data, steps }) => {
		return postFuneralDecision(data, steps);
	});

	async function sendData() {
		const response = await postMutation.mutateAsync({ data, steps });

		setLocalStorageData({ id: 'advance_decisions_completed', data: 'funeral', storageType: 'array' });

		// if (!!response) localStorage.removeItem('funeral_decision');

		return response;
	}

	const isMedium = useMediaQuery(mq('md'));

	return <>
		<ContainerWithSidebar
			sidebarFitDesktopScreen={true}
			showSidebarOnMobile={true}
			sidebar={
				<>
					<Tutorial
						id='stepper_menu'
						placement={isMedium ? 'right-start' : 'bottom'}
						description={t('tutorials.stepperMenu')}
					>
						<div>
							<Typo
								component='p'
								variant='title-2'
								css={{ marginBottom: spacing(2), }}
							>{t('stepper.menu.title')}</Typo>
						</div>
					</Tutorial>
					<ScrollDisplay
						component={CardBox}
						wrapperCss={{
							padding: 0,
						}}
						css={{
							padding: spacing(4),
						}}
					>
						<StepperMenu
							steps={steps}
							data={data}
							errors={errors}
						/>
					</ScrollDisplay>
				</>
			}
		>
			<div
				css={{
					paddingBottom: spacing(12),
				}}
			>
				<Stepper
					save={save}
					queryKey={'funeral_decision'}
					storeData={serviceFuneralDecisionData}
					steps={steps}
					data={data}
					setData={setData}
					defaultData={defaultData}
					errors={errors}
					updateFormErrors={updateErrors}
					getFormErrors={getErrors}
					getFieldValues={getFieldValues}
					allowInvalid={false}
					validEndRedirect={'/advance-decisions?success=funeral'}
					endCallback={sendData}
				/>
			</div>
		</ContainerWithSidebar>
		<Container>
			<div
				css={{
					marginBottom: spacing(12),
					borderBottom: '1px solid',
					borderColor: palette.gray[300],
				}}
			/>
		</Container>
	</>
}